import { useState } from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./ServiceDetailTimeLine.style";

const ColorIndex = (index: number) => {
    if (index === 0) {
        return "Available";
    } else if (index === 1) {
        return "Not Available";
    } else {
        return "Selected";
    }
};

const TimeLine = (props: any) => {
    const styles = useStyles();
    // var styleListTemp = [styles.avaDiv, styles.avaDiv, styles.avaDiv, styles.unavaDiv, styles.avaDiv, styles.unavaDiv, styles.avaDiv, styles.avaDiv, styles.avaDiv];
    const timeList = props.timeList.concat();
    console.log(timeList);

    const styleTag = [styles.avaDivTag, styles.unavaDivTag];

    const styleListTag = [styles.avaDiv, styles.unavaDiv, styles.selectDiv];

    const styleList = [
        styleListTag[timeList[0]],
        styleListTag[timeList[1]],
        styleListTag[timeList[2]],
        styleListTag[timeList[3]],
        styleListTag[timeList[4]],
        styleListTag[timeList[5]],
        styleListTag[timeList[6]],
        styleListTag[timeList[7]],
        styleListTag[timeList[8]],
    ];

    return (
        <>
            <div className={styles.center}>
                <div className={styles.mainDiv}>
                    {styleList.map((value: string, index: number) => (
                        <div
                            className={styleList[index]}
                            //onClick={() => handleTimeLineClick(index)}
                        >
                            <Typography variant="h6">
                                {index + 9}: 00 — {index + 10}:00
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>

            <br />
            <br />

            <div className={styles.center}>
                <div className={styles.introDiv}>
                    {styleTag.map((value: string, index: number) => (
                        <div className={styleTag[index]}>
                            <Typography variant="body1">
                                {ColorIndex(index)}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default TimeLine;
