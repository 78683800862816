import { useStyles } from "./OrderDetail.style";
import AppBarTopNoUser from "../../components/AppBarTop/AppBarTopNoUser";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const OrderDetail = () => {
    const styles = useStyles();

    return (
        <div>
            <AppBarTopNoUser />
            <br />
            <Button className={styles.backBtn} startIcon={<ArrowBackIosIcon />}>
                Back
            </Button>
            <div className={styles.containDiv}>
                <div className={styles.div1}>
                    <div className={styles.textDiv}>Service Detail</div>
                    <div className={styles.div2}></div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetail;
