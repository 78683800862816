import { Field, Form, Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import {
    fieldLabels,
    UserField,
    initialValues,
    newValidationSchema,
} from "./fields";
import Button from "@material-ui/core/Button";
import {
    Card,
    CardHeader,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
} from "@material-ui/core";
import { useStyles } from "./Register.style";
import { userRoles } from "../../util/users";
import { useState } from "react";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { FormikHelpers } from "formik";
import { TNewUserValues } from "./fields";
import { addUser } from "./handler";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { TextField } from "formik-material-ui";

enum RegisterStatus {
    INITIAL,
    SUBMITTING,
    FAILED,
}

const Register = () => {
    const styles = useStyles();
    const history = useHistory();
    const [status, setStatus] = useState(RegisterStatus.INITIAL);

    const handleDash = () => {
        return history.push("/");
    };

    const handleSignIn = () => {
        return history.push("/Login");
    };

    const handleNewSubmit = async (
        values: TNewUserValues,
        helpers: FormikHelpers<TNewUserValues>
    ) => {
        const newUser = JSON.stringify({
            username: values.username,
            password: values.password,
            first_name: values.first_name,
            last_name: values.last_name,
            phoneNumber: values.phoneNumber,
            email: values.email,
            user_role: values.role,
            address: values.address,
            gender: values.gender,
            province: values.province,
        });
        try {
            const user = await addUser(newUser);
            if (user) {
                setStatus(RegisterStatus.SUBMITTING);
            }
        } catch (e) {
            setStatus(RegisterStatus.FAILED);
            helpers.setSubmitting(false);
        }
    };

    const RegisterAlert = () => {
        if (status === RegisterStatus.INITIAL) return <></>;
        if (status === RegisterStatus.SUBMITTING) {
            return (
                <Alert
                    className={styles.alertBox}
                    severity="success"
                    action={
                        <Button
                            color="inherit"
                            size="small"
                            onClick={() => {
                                history.push("/");
                            }}
                        >
                            <strong>OK</strong>
                        </Button>
                    }
                >
                    <AlertTitle>Success</AlertTitle>
                    Please check your email to finish the register
                </Alert>
            );
        }

        return (
            <Alert
                severity="error"
                className={styles.alertBox}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setStatus(RegisterStatus.INITIAL);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                <AlertTitle>Login Error</AlertTitle>
                The user with that email already exists. Please try again. —{" "}
                <strong>Please check it out!</strong>
            </Alert>
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={newValidationSchema}
            onSubmit={handleNewSubmit}
        >
            {({ isSubmitting }) => (
                <div className={styles.container}>
                    <Button onClick={handleDash} className={styles.topLeftBtn}>
                        <Logo />
                    </Button>

                    <RegisterAlert />

                    <br />
                    <br />
                    <Card className={styles.card}>
                        <CardHeader
                            title="Register"
                            titleTypographyProps={{ variant: "h2" }}
                            className={styles.header}
                        />
                        <Divider variant="middle" />
                        <br />
                        <Form>
                            <Grid container spacing={2} justify="center">
                                <Grid item md={4} xs={12}>
                                    <FormControl fullWidth variant="filled">
                                        <Field
                                            component={TextField}
                                            select
                                            required
                                            variant="filled"
                                            label={
                                                fieldLabels[UserField.user_role]
                                            }
                                            name={UserField.user_role}
                                        >
                                            {Object.entries(userRoles).map(
                                                ([value, { name }]) => (
                                                    <MenuItem
                                                        key={value}
                                                        value={value}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <br />

                                <Grid item md={7} xs={12}>
                                    <Field
                                        component={TextField}
                                        name={UserField.username}
                                        variant="filled"
                                        label={fieldLabels[UserField.username]}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <br />

                                <Grid item md={11} xs={12}>
                                    <Field
                                        component={TextField}
                                        name={UserField.email}
                                        variant="filled"
                                        label={fieldLabels[UserField.email]}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <br />

                                <Grid item md={6} xs={12}>
                                    <Field
                                        component={TextField}
                                        name={UserField.first_name}
                                        variant="filled"
                                        label={
                                            fieldLabels[UserField.first_name]
                                        }
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <br />

                                <Grid item md={5} xs={12}>
                                    <Field
                                        component={TextField}
                                        name={UserField.last_name}
                                        variant="filled"
                                        label={fieldLabels[UserField.last_name]}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <br />

                                {/* <Grid item md={11} xs={12}>
                                    <Field
                                        component={TextField}
                                        name={UserField.address}
                                        variant="outlined"
                                        label={fieldLabels[UserField.address]}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <br /> */}

                                <Grid item md={11} xs={12}>
                                    <Field
                                        component={TextField}
                                        name={UserField.password}
                                        variant="filled"
                                        type="password"
                                        label={fieldLabels[UserField.password]}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <br />

                                <Grid item md={11} xs={12}>
                                    <Field
                                        component={TextField}
                                        name={UserField.confirm_password}
                                        variant="filled"
                                        type="password"
                                        label={
                                            fieldLabels[
                                                UserField.confirm_password
                                            ]
                                        }
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <br />
                            </Grid>
                            <br />
                            <div>
                                <Grid container justify="center" spacing={2}>
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                                <br></br>
                            </div>
                        </Form>
                        {/* </CardActions> */}
                        <Divider variant="middle" />
                        <Button onClick={handleSignIn}>
                            Already Have an account
                        </Button>
                    </Card>
                </div>
            )}
        </Formik>
    );
};

export default Register;
