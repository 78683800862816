import { Button, TextField, useTheme, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import AppBarTop from '../../components/AppBarTop/AppBarTop';
import { getToken } from "../../util/cookie";
import { useStyles } from './ContactServiceProvider.style';
import io from 'socket.io-client';
// @ts-ignore 
import ScrollToBottom from 'react-scroll-to-bottom';
import { BsFillPersonFill, BsFillTelephoneFill } from 'react-icons/bs';
import { FaLock, FaLocationArrow, FaCity } from "react-icons/fa";
import { GrMail } from "react-icons/gr";

export const ContactServiceProvider = ({ socket }: any) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const location = useLocation();
    const token: string = getToken();
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [province, setProvince] = React.useState('');
    const [conversations, setConversations] = React.useState([]);
    const [flag, setFlag] = React.useState(false);
    const [currentConversation, setCurrentConversation] = React.useState([]);
    const [counter, setCounter] = React.useState(0);
    const [currentConversationWith, setCurrentConversationWith] = React.useState('');

    socket.on("new_message", (data: any) => {
        console.log("NEW MESSAGE");
        setCurrentConversation(data.messages);
        setLoading(false);
    })

    useEffect(() => {
        setLoading(true);

        const socketId = sessionStorage.getItem("name") + "-" + currentConversationWith;
        socket.emit("join_room", { room: socketId });

        if (currentConversationWith !== '') {
            axios.get(`https://helpercloud.herokuapp.com/api/contact/getProviderDetails/${currentConversationWith}`)
                .then(res => {
                    setFirstName(res.data.first_name);
                    setLastName(res.data.last_name);
                    setPhoneNumber(res.data.phoneNumber);
                    setAddress(res.data.address);
                    setProvince(res.data.province);
                    setEmail(res.data.email);
                    setLoading(false);
                })
        }
        axios.get(`https://helpercloud.herokuapp.com/api/contact/getClientConversations/${sessionStorage.getItem("name")}`)
            .then(res => {
                setConversations(res.data);
                console.log(conversations);
                conversations.forEach(conversation => {
                    if (conversation.with === currentConversationWith) {
                        setCurrentConversation(conversation.messages);
                        return;
                    }
                })
                setLoading(false);
            })
        // axios.post('https://helpercloud.herokuapp.com/api/contact/initializeConversation', {
        //     clientName: sessionStorage.getItem("name"),
        //     proName: proName,
        // })
        //     .then(res => {
        //         setConversations(res.data.reverse());
        //     })
        setLoading(false);

    }, [flag, counter, socket, currentConversationWith])

    const submitInquiry = async () => {
        console.log("SENDING MESSAGE THROUGH SOCKET")
        // setLoading(true);
        const socketId = sessionStorage.getItem("name") + "-" + currentConversationWith;
        await socket.emit("send_message", { id: socketId, to: currentConversationWith, by: sessionStorage.getItem("name"), message: message })
        const date = new Date();
        const time = date.getHours() + ":" + date.getMinutes();
        setCurrentConversation([...currentConversation, { by: sessionStorage.getItem("name"), message: message, time: time }]);
        setLoading(false);
        return;

    }

    const handleSubmitThroughEnter: any = (e: any) => {
        if (e.key === 'Enter') {
            submitInquiry();
            setMessage('');
        }
    }

    const handleChatSelection = (convoWith: any) => {
        setCurrentConversationWith(convoWith);
    }

    return (
        <>
            <AppBarTop />
            <div className={classes.container}>
                <div className={classes.left}>
                    <p style={{ fontSize: '2rem', marginLeft: '10px', fontWeight: 'bold' }}>Conversations</p>
                    {conversations.map(conversation => {
                        return (
                            <div className={classes.infoWrapper} onClick={() => handleChatSelection(conversation.with)}>
                                <h3 style={{ margin: "0px 0px" }}>{conversation.with}</h3>
                                {conversation.messages[0] ? <p className={classes.peekMessage}>{conversation.messages[conversation.messages.length - 1].message}</p> : <p className={classes.peekMessage}>No messages yet</p>}
                            </div>
                        )
                    })
                    }
                </div>
                {loading ? <div style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></div> :
                    <div className={classes.middle}>

                        <>
                            {currentConversation.length === 0 ?
                                <div style={{ width: "100%", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{ fontSize: '2rem', marginLeft: '10px', fontWeight: 'bold' }}>No messages yet</p>
                                </div>
                                :
                                <>
                                    <p className={classes.heading}>{firstName}</p>
                                    <div className={classes.encryptionWrapper}>
                                        <FaLock style={{ fontSize: '1.2rem', color: 'black', marginRight: '10px' }} />
                                        <p>Messages you send are end-to-end encrypted. Enjoy a secure chatting experience.</p>
                                    </div>

                                    <ScrollToBottom className={classes.conversationWrapper}>
                                        {currentConversation.map(({ id, by, message, time }) => {
                                            return by === currentConversationWith ? (
                                                <div style={{ alignSelf: 'flex-start' }} className={classes.chatMessage}>
                                                    <p className={classes.chatHeader} style={{ color: "orange" }}>{by} <span style={{ fontWeight: 'normal', color: "#000" }}>{time}</span></p>
                                                    <p className={classes.chatMsg}>{message}</p>
                                                </div>
                                            )
                                                : (
                                                    <div style={{ alignSelf: 'flex-end' }} className={classes.chatMessage}>
                                                        <p className={classes.chatHeader}>You <span style={{ fontWeight: 'normal' }}>{time}</span></p>
                                                        <p className={classes.chatMsg}>{message}</p>
                                                    </div>
                                                )
                                        })
                                        }

                                    </ScrollToBottom>
                                </>
                            }
                        </>



                        <div className={classes.inputBoxWrapper}>
                            <TextField onKeyPress={handleSubmitThroughEnter} value={message} type="text" onChange={e => setMessage(e.target.value)} variant="outlined" placeholder="Your message here..." rows={3} multiline className={classes.inputBox} />
                            <Button variant="outlined" color="secondary" size="large" onClick={submitInquiry} style={{ marginTop: '30px' }}>Send</Button>
                        </div>


                    </div>
                }
                <div className={classes.right}>
                    <p style={{ fontSize: '2rem' }}>Service Provider Details</p>
                    {loading ? <CircularProgress /> : <div style={{ border: '1px solid #ebebeb', padding: '5px 5px' }}>
                        
                        <p className={classes.infoRight}><BsFillPersonFill className={classes.icon} />{firstName} {lastName}</p>
                        <p className={classes.infoRight}><BsFillTelephoneFill className={classes.icon} />{phoneNumber}</p>
                        <p className={classes.infoRight}><FaLocationArrow className={classes.icon} />{address}</p>
                        <p className={classes.infoRight}><FaCity className={classes.icon} />{province}</p>
                        <p className={classes.infoRight}><GrMail className={classes.icon} />{email}</p>
                        <hr className={classes.divider} />
                        <p style={{ fontSize: '1.2rem' }}>You can contact the service provider using this number: +1 234 56789</p>
                        <Button variant="outlined" color="primary" size="large" onClick={() => setFlag(!flag)} style={{ marginTop: '30px', width: "100%", borderRadius: '10px' }}>Call Provider</Button>
                    </div>}
                </div>
            </div>
        </>
    )
}
