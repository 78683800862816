export enum TimeRange {
    RIGHT_NOW = "RN",
    IN_WEEK = "IW",
    IN_MONTH = "IM",
    NOT_SURE = "NS",
}

export const timeRange = {
    [TimeRange.RIGHT_NOW]: {
        name: "I need it right now",
        level: 1,
    },
    [TimeRange.IN_WEEK]: {
        name: "I need it in a week",
        level: 2,
    },
    [TimeRange.IN_MONTH]: {
        name: "I need it in a month",
        level: 3,
    },
    [TimeRange.NOT_SURE]: {
        name: "I have not decided yet",
        level: 4,
    },
};

export enum TimeLines {
    time9 = '9',
    time10 = '10',
    time11 = '11',
    time12 = '12',
    time13 = '13',
    time14 = '14',
    time15 = '15',
    time16 = '16',
    time17 = '17',
}

export const timeLines = {
    [TimeLines.time9]: {
        id: 1,
        name: "9:00 - 10:00",
    },
    [TimeLines.time10]: {
        id: 2,
        name: "10:00 - 11:00",
    },
    [TimeLines.time11]: {
        id: 3,
        name: "11:00 - 12:00",
    },
    [TimeLines.time12]: {
        id: 4,
        name: "12:00 - 13:00",
    },
    [TimeLines.time13]: {
        id: 5,
        name: "13:00 - 14:00",
    },
    [TimeLines.time14]: {
        id: 6,
        name: "14:00 - 15:00",
    },
    [TimeLines.time15]: {
        id: 7,
        name: "15:00 - 16:00",
    },
    [TimeLines.time16]: {
        id: 8,
        name: "16:00 - 17:00",
    },
    [TimeLines.time17]: {
        id: 9,
        name: "17:00 - 18:00",
    },
}

export enum Equipments {
    SPONGES = "SPONGES",
    VACUUM = "VACUUM",
    MOP = "MOP",
    OTHER = "OTHER",
    BRUSH = "BRUSH",
    NONE = "NONE",
}

export const equipments = {
    [Equipments.SPONGES]: {
        id: 1,
        name: "Sponges and scourers",
    },
    [Equipments.VACUUM]: {
        name: "Vacuum cleaner",
        id: 2,
    },
    [Equipments.MOP]: {
        name: "A mop and bucket",
        id: 3,
    },
    [Equipments.BRUSH]: {
        name: "A dustpan and brush",
        id: 4,
    },
    [Equipments.OTHER]: {
        name: "Others",
        id: 5,
    },
    [Equipments.NONE]: {
        name: "NO, I am good",
        id: 6,
    },
};