import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Typography,
} from "@material-ui/core";
import { SetStateAction, useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { Pagination, Skeleton } from "@material-ui/lab";
import { useStyles } from "./SuggestCards.style";
import { getToken } from "../../util/cookie";
import { getAuth } from "../../util/auth";
import { Endpoint } from "../../util/endpoints";
import { IUser } from "../../util/users";
import { IService } from "../../util/service";
import { useHistory, useParams } from "react-router-dom";
import Cleaning from "../Landboard/dashboard-images/cleaning.png";
import StarShow from "../../components/StarShow/StarShow";
import { ArrowForward } from "@material-ui/icons/";

interface IProps {
    serviceId: string;
    serviceOwner: string;
    serviceTitle: string;
    servicePrice?: number;
    serviceType: string;
    info: string;
    phoneNum: string;
    language: string;
    location: string;
    details: string;
    rate?: number;
    user: IUser;
    feedbackRate: number;
    serviceRate: number;
    feedbackCounter: number;
    numberOfClientLike: number;
}
export interface IRouteParams {
    serviceType: string;
}
const SuggestCards = () => {
    const styles = useStyles();
    const history = useHistory();
    const [user, setUser] = useState<IUser>();
    const [loadingError, setLoadingError] = useState(false);
    // const [feedbackAvgRateArray, setFeedbackAvgRateArray] =
    //     useState<number[]>();
    const [service, setService] = useState<IProps[]>();
    const fillFeedbackAvgRate = async (theService: any, token: string) => {
        let user_ = [];
        if (theService !== undefined) {
            theService.forEach(async (service_: any) => {
                const apiUserString = Endpoint.CLIENT + "id/" + service_.owner;
                const user = await getAuth(apiUserString, token);
                user_.push(user.feedbackAvgRate);
                // setFeedbackAvgRateArray(user_);
            });
        }
    };
    // const serviceType = useRouteMatch<IRouteParams>().params;
    const { serviceType } = useParams<{ serviceType: string }>();
    console.log(serviceType);
    const handleDash = () => {
        return history.push("/");
    };

    useEffect(() => {
        const apiServiceString = Endpoint.TYPE_SERVICE + "Cleaning";
        const getService = async () => {
            try {
                const token: string = getToken();

                // Handle case where user not login
                if (!token) {
                    throw Error();
                }
                console.log(apiServiceString);

                const theUser: IUser = (await getAuth(
                    Endpoint.PROFILE,
                    token
                )) as IUser;
                const theService = await getAuth(apiServiceString, token);
                setUser(theUser);
                fillFeedbackAvgRate(theService, token);

                const service = theService.map((data: IService) => {
                    return {
                        serviceId: data._id,
                        serviceOwner: data.owner,
                        servicePostedDate: data.createdAt,
                        serviceTitle: data.serviceTitle,
                        serviceType: data.serviceType,
                        servicePrice: data.servicePrice,
                        info: data.serviceDescription,
                        language: data.serviceLanguage,
                        feedbackRate: data.serviceFeedbackAvgRate.toFixed(1),
                        feedbackCounter: data.serviceFeedbackCounter,
                        numberOfClientLike: data.numberOfClientLike,
                    };
                });
                console.log(service);
                setService(service);
            } catch (e) {
                setLoadingError(true);
            }
        };
        getService();
    }, []);
    const convertDateToString = (date: Date) => {
        const tmpDate = new Date(date);

        return (
            tmpDate.getFullYear() +
            "-" +
            tmpDate.getMonth() +
            "-" +
            tmpDate.getDate()
        );
    };
    const [open, setOpen] = useState(true);
    const onClose = () => {
        setOpen(false);
        setLoadingError(false);
    };
    const handleNotLogin = () => {
        return history.push("/Login");
    };
    const servicePerPage = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const handleChange = (e: any, p: SetStateAction<number>) => {
        setCurrentPage(p);
    };

    return loadingError ? (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitle>
                <b>Please login or register first</b>
            </DialogTitle>
            <DialogContent>
                <b>
                    Get personalized recommendations after you login into van
                    service
                </b>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleNotLogin();
                    }}
                    color="primary"
                    variant="outlined"
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    ) : (
        <div className={styles.container}>
            <Button onClick={handleDash} className={styles.topLeftBtn}>
                <Logo />
            </Button>
            <br />
            <br />

            <Card className={styles.card}>
                <CardHeader
                    title="Suggestion Service"
                    titleTypographyProps={{ variant: "h2" }}
                    className={styles.header}
                />
                <Divider variant="middle" className={styles.divider} />
                {service ? (
                    <>
                        {service.length !== 0 ? (
                            <div className={styles.suggestWord}>
                                <Typography variant="h4">
                                    Please choose the service you prefer
                                </Typography>
                            </div>
                        ) : (
                            <Typography variant="h4" className={styles.header}>
                                Sorry, we don't have any type of services
                                posted, please check it later
                            </Typography>
                        )}
                        <Grid container spacing={4}>
                            {service
                                .slice(
                                    (currentPage - 1) * servicePerPage,
                                    currentPage * servicePerPage
                                )
                                .map((item) => (
                                    <Grid
                                        item
                                        key={item.serviceId}
                                        xs={12}
                                        sm={6}
                                        md={4}
                                    >
                                        <div className={styles.cardBoarder}>
                                            <Card
                                                className={styles.suggestCard}
                                            >
                                                <CardMedia
                                                    className={styles.cardMedia}
                                                    image={Cleaning}
                                                />
                                                <CardContent
                                                    className={
                                                        styles.cardContent
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.serviceTitle
                                                        }
                                                    >
                                                        <Typography
                                                            variant="h4"
                                                            className={
                                                                styles.boldFont
                                                            }
                                                        >
                                                            {item.serviceTitle}
                                                        </Typography>
                                                    </div>
                                                    <br />
                                                    <Typography variant="h6">
                                                        Service Type:
                                                    </Typography>
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            styles.boldFont
                                                        }
                                                    >
                                                        {item.serviceType +
                                                            " service"}
                                                    </Typography>
                                                    <Divider
                                                        className={
                                                            styles.divider
                                                        }
                                                    />
                                                    <Typography variant="h6">
                                                        Service Price:
                                                    </Typography>
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            styles.boldFont
                                                        }
                                                    >
                                                        ${item.servicePrice}/
                                                        hour
                                                    </Typography>

                                                    <Divider
                                                        className={
                                                            styles.divider
                                                        }
                                                    />
                                                    <Typography variant="h6">
                                                        Service Rating:
                                                    </Typography>
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            styles.boldFont
                                                        }
                                                    >
                                                        <StarShow
                                                            rate={
                                                                item.feedbackRate
                                                            }
                                                        />
                                                        {item.feedbackRate}(
                                                        {item.feedbackCounter}{" "}
                                                        customers has rated)
                                                    </Typography>
                                                    {/* <br /> */}
                                                    <Divider
                                                        className={
                                                            styles.divider
                                                        }
                                                    />
                                                    <Typography variant="h6">
                                                        Number of Clients Like:
                                                    </Typography>
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            styles.boldFont
                                                        }
                                                    >
                                                        {
                                                            item.numberOfClientLike
                                                        }
                                                    </Typography>

                                                    <Divider
                                                        className={
                                                            styles.divider
                                                        }
                                                    />
                                                    <Typography variant="h6">
                                                        Language:
                                                    </Typography>
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            styles.boldFont
                                                        }
                                                    >
                                                        {item.language + " "}
                                                    </Typography>
                                                    <Divider
                                                        className={
                                                            styles.divider
                                                        }
                                                    />
                                                    <Typography variant="h6">
                                                        Joined Time:
                                                    </Typography>
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            styles.boldFont
                                                        }
                                                    >
                                                        {convertDateToString(
                                                            user.createdAt
                                                        )}
                                                    </Typography>
                                                    <Divider
                                                        className={
                                                            styles.divider
                                                        }
                                                    />
                                                </CardContent>
                                                <CardActions
                                                    className={
                                                        styles.cardActionSection
                                                    }
                                                >
                                                    <div>
                                                        <Button
                                                            size="large"
                                                            variant="contained"
                                                            className={
                                                                styles.chooseButton
                                                            }
                                                            onClick={() =>
                                                                history.push(
                                                                    `/ServiceDetail/${item.serviceId}`
                                                                )
                                                            }
                                                        >
                                                            Choose
                                                        </Button>
                                                    </div>
                                                </CardActions>
                                            </Card>
                                        </div>
                                    </Grid>
                                ))}
                        </Grid>
                        {currentPage === 3 ? (
                            <>
                                <div className={styles.viewMore}>
                                    <Button
                                        size="large"
                                        className={styles.viewMore}
                                        endIcon={<ArrowForward />}
                                        onClick={() =>
                                            history.push(`/PostList`)
                                        }
                                    >
                                        View More Surveys
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <br />
                        )}
                        {service.length !== 0 ? (
                            <Pagination
                                count={Math.ceil(
                                    service.length / servicePerPage
                                )}
                                size="large"
                                page={currentPage}
                                variant="outlined"
                                color="primary"
                                className={styles.pagination}
                                onChange={handleChange}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <Skeleton variant="rect" height={1000} />
                )}
            </Card>
        </div>
    );
};

export default SuggestCards;
