import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
    containDiv: {
        // height: "75vh",
        width: "100%",
        marginTop: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    backBtn: {
        marginLeft: "20px",
    },
    div1: {
        height: "75vh",
        width: "95%",
        backgroundColor: "orange",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    div2: {
        height: "65vh",
        width: "95%",
        backgroundColor: "white",
        borderRadius: "20px",
    },
    textDiv: {
        width: "95%",
        fontSize: "21px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inlineTitle: {
        display: "inline",
        margin: 10,
        fontWeight: "bold",
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
