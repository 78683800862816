import { FormikHelpers } from "formik";
import { apiFetch, Endpoint } from "../../util/endpoints";
import { FormField, TFormValues } from "./CleanSurveyFormFields";
import history from "../../util/history";
import { getToken } from "../../util/cookie";

const addSurvey = async (surveyInfo: string, token: string) => {
    const init: RequestInit = {
        headers: {
            authorization: token,
            Accept: "application/json",
        },
        method: "POST",
        body: surveyInfo,
    };
    return await apiFetch(Endpoint.ORDER, "NTP", init)
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            return res;
        });
};

export const handleSubmit = async (
    values: TFormValues,
    helpers: FormikHelpers<TFormValues>,
    setSubmissionError: React.Dispatch<React.SetStateAction<boolean>>,
    date: string
) => {
    let TimeLines: string[] = [];
    const getTimeLine = () => {
        if (values[FormField.timeRange9] === true) {
            TimeLines.push("9");
        }
        if (values[FormField.timeRange10] === true) {
            TimeLines.push("10");
        }
        if (values[FormField.timeRange11] === true) {
            TimeLines.push("11");
        }
        if (values[FormField.timeRange12] === true) {
            TimeLines.push("12");
        }
        if (values[FormField.timeRange13] === true) {
            TimeLines.push("13");
        }
        if (values[FormField.timeRange14] === true) {
            TimeLines.push("14");
        }
        if (values[FormField.timeRange15] === true) {
            TimeLines.push("15");
        }
        if (values[FormField.timeRange16] === true) {
            TimeLines.push("16");
        }
        if (values[FormField.timeRange17] === true) {
            TimeLines.push("17");
        }
    };
    await getTimeLine();
    const newSurvey = JSON.stringify({
        serviceId: values[FormField.serviceId],
        numberBedRoom: values[FormField.numberBedRoom],
        numberWashRoom: values[FormField.numberWashRoom],
        area: values[FormField.area],
        equipment: values[FormField.equipment],

        focusBathCleaning: values[FormField.focusBathCleaning],
        focusKitchenCleaning: values[FormField.focusKitchenCleaning],
        focusRoomCleaning: values[FormField.focusRoomCleaning],
        focusWindowWashing: values[FormField.focusWindowWashing],
        focusCarpetShampooing: values[FormField.focusCarpetShampooing],
        focusFurnitureTreatment: values[FormField.focusFurnitureTreatment],
        focusLaunary: values[FormField.focusLaunary],

        cleaningDate: date,
        timeLine: TimeLines,
        address: values[FormField.address],
        zipcode: values[FormField.zipcode],

        firstName: values[FormField.firstName],
        lastName: values[FormField.lastName],
        phoneNumber: values[FormField.phoneNumber],
    });
    try {
        const order = await addSurvey(newSurvey, getToken());
        history.push(`/ReverseDetail/${order._id}`);
    } catch (e) {
        helpers.setSubmitting(false);
        setSubmissionError(true);
    }
};
