import { useState } from "react";
import { io } from "socket.io-client";
// import { API_URL } from "../../util/endpoints";

const Chat = () => {
    const socket = io();
    const [msg, setMsg] = useState("default");
    // socket.on('new message', (data: any) => {
    //     console.log(data);
    // });
    socket.on("connect", () => {
        console.log(socket.connected);
    });
    socket.on("connect_error", () => {
        socket.connect();
        console.log(socket.connected);
    });
    socket.on("message", (data) => {
        console.log(data);
        // setMsg(data);
    });
    return <div>message</div>;
};

export default Chat;
