import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from "./LandboardReviews.styles";

const LandboardReviews = (props: any) => {
    const styles = useStyles();
    return (
        <div className={styles.reviews}>

            {/* review title */}
            <h2 className={styles.reviews_title}> 
                Meet our service providers 
            </h2>

            {/* review profiles */}
            <div className={styles.reviews_profiles}>
                <div className={styles.profile}>
                    <div className={styles.profile_header}>
                        <img className={styles.profile_img} src={"https://source.unsplash.com/user/erondu"} width="48" height="48" aspect-ratio="auto 48 / 48"/>
                        <h3 className={styles.profile_title}>Shy</h3>
                        <span className={styles.profile_subtitle}>Nanny</span>
                        <span className={styles.rating}>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                        </span>
                    </div>
                    <p className={styles.profile_description}>
                        “Shy is an exceptional caregiver who we feel lucky to have found! She is wonderful with our daughter 
                        (who asks for her to visit all the time) and so charming, punctual, fun, inventive and thoughtful. 
                        Our child has focus and attention challenges and Shy just rolls with it. Can't recommend her highly enough!”
                    </p>
                    <span className={styles.profile_info}>
                        
                            Mavis J. • 
                            <span className={styles.profile_city}>Toronto</span> 
                    </span>
                </div>

                <div className={styles.profile}>
                    <div className={styles.profile_header}>
                        <img className={styles.profile_img} src={"https://source.unsplash.com/user/jackie"} width="48" height="48" aspect-ratio="auto 48 / 48"/>
                        <h3 className={styles.profile_title}>Geti</h3>
                        <span className={styles.profile_subtitle}>Pet sitter</span>
                        <span className={styles.rating}>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                        </span>
                    </div>
                    <p className={styles.profile_description}>
                        “Thank you for taking such excellent care of Buddy! He had a wonderful day and I appreciate all the photos and treats.
                        Buddy loved them! This girl has a lot of love to give and she puts all her energy into these dogs. 
                        Wouldn't go to anyone else. So glad to have found her!”
                    </p>
                    <span className={styles.profile_info}>
                        
                                Alex P. • 
                            <span className={styles.profile_city}>Montreal</span> 
                    </span>
                </div>

                <div className={styles.profile}>
                    <div className={styles.profile_header}>
                        <img className={styles.profile_img} src={"https://source.unsplash.com/random"} width="48" height="48" aspect-ratio="auto 48 / 48"/>
                        <h3 className={styles.profile_title}>Lia</h3>
                        <span className={styles.profile_subtitle}>Housekeeper</span>
                        <span className={styles.rating}>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                            <span className={styles.icon_star}></span>
                        </span>
                    </div>
                    <p className={styles.profile_description}>
                        “I highly recommend Lia. She is incredible at her job. She is fast and effective. Very meticulous and responsible. 
                        She has an eye for detail. If you want someone that can take charge she is the person for you. 
                        Very honest I can trust with my home anytime.”
                    </p>
                    <span className={styles.profile_info}>
                        
                        Francecso M. • 
                            <span className={styles.profile_city}> Vancouver</span> 
                    </span>
                </div>
            </div>

        </div>
    );
};

export default LandboardReviews;
