import { useTheme } from "@material-ui/styles";
import AppBarTop from "../../components/AppBarTop/AppBarTop";
import { useStyles } from "./ProTutoringSurvey.style";
import { Button, CircularProgress, FormControl, FormControlLabel, Radio, Select, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { handleSubmit } from "./handler";
import { useHistory } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"

export const ProTutoringSurvey = () => {
    const theme = useTheme();
    const classes = useStyles();
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [sampleClass, setSampleClass] = useState("yes");
    const [serviceMode, setServiceMode] = useState("online");
    const [title, setTitle] = useState("");
    const [company, setCompany] = useState("");
    const [years, setYears] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phno, setPhno] = useState("");
    const [address, setAddress] = useState("");
    const [desc, setDesc] = useState("");
    const [rate, setRate] = useState("");
    const [slots, setSlots] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [from, setFrom] = useState("ind");
    const [qualification, setQualification] = useState("High School");
    const history = useHistory();
    const listOfSubjects = ["Math", "Biology", "Physics", "Chemistry", "English", "History", "Geography", "Computer Science", "Music", "Art", "Physical Education", "Other"]
    const listOfSlots = ["8:00-9:00", "9:00-10:00", "10:00-11:00", "11:00-12:00", "12:00-13:00", "13:00-14:00", "14:00-15:00", "15:00-16:00", "16:00-17:00", "17:00-18:00", "18:00-19:00", "19:00-20:00", "20:00-21:00", "21:00-22:00", "22:00-23:00", "23:00-00:00"];
    const [grade, setGrade] = useState("Elementary");
    const [language, setLanguage] = useState([]);
    const languages = ["English", "Mandarin", "Hindi", "Spanish", "French", "German", "Other"];
    const generateOptions = () => {
        const options = [];
        for (let i = 1; i <= 10; i++) {
            options.push(
                <option key={i} value={i} className={classes.option}>{i}</option>
            )
        }
        options.push(<option key={11} value={10} className={classes.option}>More than 10</option>)
        return options;
    }
    const handleSubjectSelection = (e: any) => {
        const selected = e.target.value;
        if (!selectedSubjects.includes(selected) && e.target.checked) {
            setSelectedSubjects([...selectedSubjects, selected]);
        }
        else if (selectedSubjects.includes(selected) && !e.target.checked) {
            setSelectedSubjects(selectedSubjects.filter(sub => sub !== selected));
        }
    }
    const handleSlotSelection = (e: any) => {
        const selected = e.target.value;
        if (!slots.includes(selected) && e.target.checked) {
            setSlots([...slots, selected]);
        }
        else if (slots.includes(selected) && !e.target.checked) {
            setSlots(slots.filter(slot => slot !== selected));
        }

    }
    const generateQualifications = () => {
        const qualifications = ["High school", "Diploma", "Graduate", "Masters", "PhD", "Other"];
        const qualificationList: any = [];
        qualifications.forEach(qualification => {
            qualificationList.push(
                <option key={qualification} value={qualification} className={classes.option}>{qualification}</option>
            )
        })
        return qualificationList;
    }
    const handleLanguageSelection = (e: any) => {
        const selected = e.target.value;
        if (!language.includes(selected) && e.target.checked) {
            setLanguage([...language, selected]);
        }
        else if (language.includes(selected) && !e.target.checked) {
            setLanguage(language.filter(lan => lan !== selected));
        }
    }

    const Submit = () => {
        setLoading(true);
        const data = {
            title, grade, language, company, years, firstName, lastName, phno, address, selectedSubjects, sampleClass, serviceMode, desc, rate, slots, qualification, serviceOwner: sessionStorage.getItem("name")
        }
        handleSubmit(data)
            .then((res) => {
                console.log(res.data.success);
                setLoading(false);
                setSuccess(true);
            })
    }
    return (
        <div>
            <AppBarTop />
            <div className={classes.container}>
                <p className={classes.heading}>Provide information for your service</p>

                <p className={classes.subHeading}>Part A: General Information</p>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Please provide a title for your service</p>
                    <TextField value={title} onChange={e => setTitle(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="E.g. Elementary Math" />
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Are you an individual or are you from a company?</p>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                            <label style={{ display: "inline", fontSize: '1.2rem' }}>Individual</label>
                            <input type="radio" value="ind" checked={from === 'ind' ? true : false} onClick={() => setFrom("ind")} />
                        </div>
                        <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                            <label style={{ display: "inline", fontSize: '1.2rem' }}>From a company</label>
                            <input type="radio" value="com" checked={from === 'com' ? true : false} onClick={() => setFrom("com")} />
                        </div>
                    </div>
                </div>
                {from === 'com' &&
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>What is the name of your company?</p>
                        <TextField value={company} onChange={(e) => setCompany(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="E.g. Mark's Academy of Science" />
                    </div>
                }
                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>How many years have you done this service?</p>
                    <Select color="secondary" onChange={(e: any) => setYears(e.target.value)} value={years} variant="outlined" className={classes.formInput} defaultValue={10}>
                        {generateOptions()}
                    </Select>
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Please enter your highest qualification</p>
                    <Select color="secondary" onChange={(e: any) => setQualification(e.target.value)} value={qualification} variant="outlined" className={classes.formInput} defaultValue={"High School"}>
                        {generateQualifications()}
                    </Select>
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Please enter your first name</p>
                    <TextField value={firstName} onChange={e => setFirstName(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="E.g. Mark" />
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Please enter your last name</p>
                    <TextField value={lastName} onChange={e => setLastName(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="E.g. Smith" />
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Please enter your phone number</p>
                    <TextField value={phno} onChange={e => setPhno(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="E.g. +1 2363345860" />
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Please enter your address</p>
                    <TextField value={address} onChange={e => setAddress(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="E.g. Tallond street, Burquitlam, BC" />
                </div>

                <p className={classes.subHeading}>Part B: Information about your service</p>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Which of the following subject(s) can you teach?</p>
                    <div className={classes.checkboxContainer}>
                        {listOfSubjects.map((subject: any, index) => {
                            return (<>
                                <label className={classes.label}>{subject}</label>
                                <input onClick={handleSubjectSelection} type="checkbox" name="math" value={subject} className={classes.checkbox} />
                            </>
                            )
                        })}
                    </div>
                    
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>What will your primary language(s) of delivery be?</p>
                    <div className={classes.checkboxContainer}>
                        {languages.map((language: any, index: any) => {
                            return (<>
                                <label className={classes.label}>{language}</label>
                                <input onClick={handleLanguageSelection} type="checkbox" name="math" value={language} className={classes.checkbox} />
                            </>
                            )
                        })
                        }
                    </div>
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Which of the following grades would you like to offer your services to?</p>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                            <label style={{ display: "inline", fontSize: '1.2rem' }} >Elementary</label>
                            <input type="radio" value="ind" checked={grade === 'Elementary' ? true : false} onClick={() => setGrade("Elementary")} />
                        </div>
                        <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                            <label style={{ display: "inline", fontSize: '1.2rem' }}>Middle School</label>
                            <input type="radio" value="ind" checked={grade === 'Middle School' ? true : false} onClick={() => setGrade("Middle School")} />
                        </div>
                        <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                            <label style={{ display: "inline", fontSize: '1.2rem' }}>High School</label>
                            <input type="radio" value="ind" checked={grade === 'High School' ? true : false} onClick={() => setGrade("High School")} />
                        </div>
                        <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                            <label style={{ display: "inline", fontSize: '1.2rem' }}>University</label>
                            <input type="radio" value="ind" checked={grade === 'University' ? true : false} onClick={() => setGrade("University")} />
                        </div>

                    </div>
                    {/* <Button color="secondary" variant="contained" onClick={() => setSelectedSubjects([])} style={{ width: "400px" }}>Clear Selection</Button> */}
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Would you prefer offering your services online or offline or both?</p>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                            <label style={{ display: "inline", fontSize: '1.2rem' }}>Online</label>
                            <input type="radio" name="radio" value="online" checked={serviceMode === 'online' ? true : false} onClick={() => setServiceMode("online")} />
                        </div>
                        <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                            <label style={{ display: "inline", fontSize: '1.2rem' }}>Offline</label>
                            <input type="radio" name="radio" value="offline" checked={serviceMode === 'offline' ? true : false} onClick={() => setServiceMode("offline")} />
                        </div>
                        <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                            <label style={{ display: "inline", fontSize: '1.2rem' }}>Both</label>
                            <input type="radio" name="radio" value="online+offline" checked={serviceMode === 'online+offline' ? true : false} onClick={() => setServiceMode("online+offline")} />
                        </div>
                    </div>
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Would you be willing to provide a free sample class online?</p>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                            <label style={{ display: "inline", fontSize: '1.2rem' }}>Yes</label>
                            <input type="radio" value="yes" checked={sampleClass === 'yes' ? true : false} onClick={() => setSampleClass("yes")} />
                        </div>
                        <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                            <label style={{ display: "inline", fontSize: '1.2rem' }}>No</label>
                            <input type="radio" value="no" checked={sampleClass === 'no' ? true : false} onClick={() => setSampleClass("no")} />
                        </div>
                    </div>
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>What are your available hour slots?</p>
                    <div className={classes.checkboxContainer}>
                        {listOfSlots.map((slot: any, index: any) => {
                            return (<>
                                <label className={classes.label}>{slot}</label>
                                <input onClick={handleSlotSelection} type="checkbox" name="math" value={slot} className={classes.checkbox} />
                            </>
                            )
                        })
                        }
                    </div>
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Please provide a brief description of your service</p>
                    <TextField value={desc} onChange={(e) => setDesc(e.target.value)} color="secondary" multiline rows={4} variant="outlined" className={classes.formInput} placeholder="E.g. I am a teacher of math. I can teach students how to add, subtract, multiply and divide." />
                </div>

                <div className={classes.formContainer}>
                    <p className={classes.formLabel}>Please provide your hourly rate</p>
                    <TextField value={rate} onChange={e => setRate(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="E.g. 25" /><span>Dollars per hour</span>
                </div>

                <p style={{ color: "#501ff0", fontSize: '1.2rem' }}><span style={{ color: 'red' }}>*</span>By clicking submit, I agree to provide my services upon request by a client, without forgery, fraud or any other misconduct.</p>

                {!loading && !success && <Button onClick={Submit} variant="contained" color="primary" size="large" style={{ width: '200px', margin: '0 auto', fontSize: '1.3rem' }}>SUBMIT</Button>}
                {loading && !success && <CircularProgress style={{ margin: '0 auto', display: 'block' }} />}
                {success && <>
                    <p style={{ color: 'green', fontSize: '1.2rem', textAlign: 'center' }}>Your service has been submitted successfully. Thank you.</p>
                    <p style={{ color: 'blue', fontSize: '1.2rem', textAlign: 'center' }} onClick={() => history.push("/Pros/Survey/Tutoring")}>Add another service here.</p>
                </>}
            </div>
        </div>
    )
}
