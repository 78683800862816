import { FormikHelpers } from "formik";
import { apiFetch, Endpoint } from "../../util/endpoints";
import { getToken } from "../../util/cookie";
import {IReserve} from "./OrderPage";

export const handleChange = async (
    values: IReserve,
    helpers: FormikHelpers<IReserve>,
    setIsEditing: (isEditing: boolean) => void
) => {
    setIsEditing(false);
    console.log("handleChange");
};

export const handleCancel = (
    setIsEditing: (isEditing: boolean) => void,
    resetForm: () => void
) => {
    if (
        window.confirm(
            "Would you like to cancel editing your profile?\nClicking OK will not save any edited information."
        )
    ) {
        resetForm();
        setIsEditing(false);
    }
};
