import { Button, CircularProgress, useTheme } from "@material-ui/core";
import { useStyles } from "./SuggestServiceCardsTutoring.style";
import AppBarTop from "../../components/AppBarTop/AppBarTop"
import { useEffect, useState } from "react";
import { GetData } from "./handler";
import { AiFillStar } from 'react-icons/ai';
import { useHistory } from "react-router-dom";

export const SuggestServiceCardsTutoring = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [filter, setFilter] = useState("");
    const [gradeFilter, setGradeFilter] = useState("");
    const listOfSubjects = ["Math", "Biology", "Physics", "Chemistry", "English", "History", "Geography", "Computer Science", "Music", "Art", "Physical Education", "Other"];
    useEffect(() => {
        setLoading(true);
        GetData().then(res => {
            if (filter === "" && gradeFilter === "") {
                setServices(res);
                console.log(res);
                setLoading(false);
            }
            else if (filter !== "" && gradeFilter === "") {
                let filteredServices = [];
                for (let i = 0; i < res.length; i++) {
                    if (res[i].selectedSubjects.includes(filter)) {
                        filteredServices.push(res[i]);
                    }
                }
                setServices(filteredServices);
                setLoading(false);
            }
            else if (filter === "" && gradeFilter !== "") {
                let filteredServices = [];
                for (let i = 0; i < res.length; i++) {
                    if (res[i].grade === gradeFilter) {
                        filteredServices.push(res[i]);
                    }
                }
                setServices(filteredServices);
                setLoading(false);
            }
            else {
                let filteredServices = [];
                for (let i = 0; i < res.length; i++) {
                    if (res[i].selectedSubjects.includes(filter) && res[i].grade === gradeFilter) {
                        filteredServices.push(res[i]);
                    }
                }
                setServices(filteredServices);
                setLoading(false);
            }
        })

    }, [filter, gradeFilter])

    const GenerateStars = (rate: number) => {
        const list: any = [];
        for (let i = 1; i <= rate; i++) {
            list.push(
                <AiFillStar className={classes.star} key={i} />
            )
        }
        return list;
    }
    const GenerateOverallRatings = (reviews: any) => {
        let overallTimeRating: any = 0;
        let overallQualityRating: any = 0;
        let overallAttitudeRating: any = 0;
        let overallServiceRating: any = 0;
        reviews.map((review: any) => {
            overallTimeRating += review.timeRating;
            overallQualityRating += review.qualityRating;
            overallAttitudeRating += review.attitudeRating;
            overallServiceRating += review.overallRating;
            return overallAttitudeRating;
        })
        overallTimeRating = overallTimeRating / reviews.length;
        overallQualityRating = overallQualityRating / reviews.length;
        overallAttitudeRating = overallAttitudeRating / reviews.length;
        overallServiceRating = overallServiceRating / reviews.length;
        console.log(overallServiceRating);
        return { overallTimeRating, overallQualityRating, overallAttitudeRating, overallServiceRating };
    }

    return (
        <>
            <AppBarTop />
            <div className={classes.container}>
                <h3 className={classes.heading}>List of Tutoring Services</h3>
                <p className={classes.centerParagraph}>At WeCare Services, we are dedicated to providing you with the best tutors for a variety of subjects. <br />Choose from {services.length} service{services.length > 1 ? "s" : null}</p>
                <select className={classes.filter} onChange={e => setFilter(e.target.value)}>
                    <option value="">Filter by Subjects</option>
                    <option value="">All</option>
                    {listOfSubjects.map((subject, index) => {
                        return <option key={index} value={subject}>{subject}</option>
                    }
                    )}
                </select><br />
                <select className={classes.filter} onChange={e => setGradeFilter(e.target.value)}>
                    <option value="">Filter by Grade</option>
                    <option value="">All</option>
                    <option value="Elementary">Elementary</option>
                    <option value="Middle School">Middle School</option>
                    <option value="High School">High School</option>
                    <option value="University">University</option>
                </select>
                {loading ? <CircularProgress /> :
                    <div className={classes.cardContainer}>
                        {services.map((service, index) => {
                            return (
                                <div className={classes.card} key={index}>
                                    <div className={classes.cardSection}>
                                        <h4 className={classes.serviceTitle}>{service.serviceTitle}</h4>
                                    </div>
                                    <div className={classes.cardSection}>
                                        <h5 className={classes.subHeading}>About the Provider</h5>
                                        <p className={classes.name}>Name: {service.firstName} {service.lastName}</p>
                                        {service.companyName !== "" ? <p className={classes.name}>Company: {service.companyName}</p> : null}
                                        <p className={classes.name}>Qualification: {service.qualification}</p>
                                        <p className={classes.name}>Subjects offerred:
                                            {service.selectedSubjects.map((subject: any, index: any) => {
                                                return (
                                                    <span key={index}> {subject} </span>
                                                )
                                            })
                                            }
                                        </p>
                                        <p className={classes.name}>Grade: {service.grade}</p>
                                    </div>
                                    <div className={classes.cardSection}>
                                        <h5 className={classes.subHeading}>About the Service</h5>
                                        <p className={classes.name}>Mode of delivery: {service.serviceMode}</p>
                                        <p className={classes.name}>Free sample class included: {service.sampleClass}</p>
                                        <p className={classes.name}>Hourly rate: ${service.servicePrice} / hr </p>
                                        <div className={classes.starWrapper}>
                                            <p className={classes.name}>Average Rating: {service.reviews.length === 0 && <span>No Reviews Yet</span>}</p>
                                            {service.reviews.length !== 0 &&
                                                <div className={classes.starContainer}>{GenerateStars(GenerateOverallRatings(service.reviews).overallServiceRating)}</div>
                                            }
                                        </div>
                                        <p className={classes.name}>Number of services provided: </p>
                                    </div>

                                    <Button onClick={() => history.push(`/ServiceDetailTutoring/${service._id}`)} variant="contained" color="secondary" size="large" className={classes.button}>Book now</Button>

                                </div>
                            )
                        })
                        }
                    </div>
                }
            </div>
        </>
    )
}