import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    reviews: {
        fontFamily: "Proxima Nova",
        backgroundColor: "white",
    },
    reviews_title: {
        textAlign: "center",
        marginBottom: 1.5,
        margin: 0,
        fontWeight: 700,
        color: "#172c42",
        display: "block",
        marginBlockStart: "1em",
        marginBlockEnd: "1em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
    },
    reviews_profiles:{
        // display: "grid",
        // overflow: "auto",
        // marginBottom: "1.5rem",
        // gridColumnGap: "1.5rem",
        // gridTemplateColumns: "repeat(auto-fit, minmax(15rem, 1fr))",
        display: 'flex',
        flexDirection: 'row',
        marginLeft: "25rem",
        marginRight: "25rem",
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginLeft: "0rem",
            marginRight: "0rem",
        }
    },
    profile: {
        padding: "1.25rem 1rem",
        boxSizing: "border-box",
        borderRadius: "0.375rem",
        border: "1px solid",
        borderColor: "#e6e8ea",
        margin: "10px 20px"
    },
    profile_header: {
        display: "grid",
        gridColumnGap: "1.25rem",
        gridTemplateRows: "repeat(3, min-content)",
        gridTemplateColumns: "min-content 1fr",
        gridTemplateAreas: "'profile_img profile_title' 'profile_img profile_subtitle' 'profile_img profile_rating'"
    },
    profile_img: {
        gridArea: "profile_img",
        borderRadius: "50%",
    },
    profile_title: {
        gridArea: "profile_title",
        fontSize: "1rem",
        lineHeight: 1.375,
        margin: 0,
        fontWeight: 700,
    },
    profile_subtitle: {
        gridArea: "profile_subtitle",
        color: "#667483",
        marginBottom: "0.125rem",
        fontSize: "0.875rem",
        lineHeight: 1.429
    },
    rating: {
        gridArea: "profile_rating",
        display:"inline-flex"
    },
    icon_star: {
        marginRight: 0,
        width: "0.625rem",
        height: "0.625rem",
        backgroundImage: 'url("https://useprd-cdn-w.care.com/assets/img/welcomePage/star-f71f5c285d7ce11eea701dc394a4b08b.svg")',
        display: "block",
        backgroundRepeat: "no-repeat"
    },
    profile_description: {
        minHeight: "4.75rem",
        fontSize: "1rem",
        lineHeight: 1.375,
        padding: 0,
        display: "block",
        marginBlockStart: "1em",
        marginBlockEnd: "1em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
    },
    profile_info: {
        fontSize: "0.875rem",
        lineHeight: 1.429,
        color: "#505d6d"
    },
    profile_city: {
        color: "#505d6d",
        fontSize: "0.875rem",
        lineHeight: 1.429,
        marginLeft: "0.245rem"
    },
}));
