import history from "../../util/history";
import { apiFetch, Endpoint } from "../../util/endpoints";
import { saveToken } from "../../util/cookie";
import axios from "axios";

export const saveUserToken = async (token: string) => {
    const init: RequestInit = {
        method: "POST",
        body: token,
    };
    return await apiFetch(Endpoint.LOGIN, "", init).then((res) => {
        if (res.ok && res.status === 200) {
            res.json().then((data) => {
                saveToken(data.token);
                sessionStorage.setItem('name', data.user.first_name);
                axios.get(`https://helpercloud.herokuapp.com/api/users/getAccountType/${data.user.email}`)
                    .then(res => {
                        sessionStorage.setItem('accountType', res.data.role);
                        sessionStorage.setItem("userId", res.data._id);
                    });
                history.push(`/User`);
                return res.status;
            });
        }
        return res.status;
    });
};
