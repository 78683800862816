import { FormikHelpers } from "formik";
import { apiFetch, Endpoint } from "../../util/endpoints";
import { getToken } from "../../util/cookie";
import { IReserve } from "./ReserveInfo";
const updateReserve = async (userInfo: string, token: string) => {
    const init: RequestInit = {
        method: "PATCH",
        headers: {
            authorization: token,
            Accept: "application/json",
        },
        body: userInfo,
    };
    return await apiFetch(Endpoint.ORDER, "change", init)
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            return res as IReserve;
        });
};

const convertDateToString = (date: Date) => {
    const tmpDate = new Date(date);
    const dateString = `${tmpDate.getFullYear()}-${
        tmpDate.getMonth() + 1
    }-${tmpDate.getDate()}`;
    return dateString;
};

export const handleSubmit = async (
    values: IReserve,
    helpers: FormikHelpers<IReserve>,
    setIsEditing: (isEditing: boolean) => void,
    date: Date,
    setTimeCounter: (num: number) => void
) => {
    const userReserveInfo: { [key: string]: any } = {
        id: values.orderId,
        firstName: values.first_name,
        lastName: values.last_name,
        phoneNumber: values.phoneNumber,
        address: values.address,
        zipcode: values.zipcode,
        cleaningDate: convertDateToString(date),
        timeLine: values.timeLine,
    };

    try {
        await updateReserve(JSON.stringify(userReserveInfo), getToken());
        setTimeCounter(values.timeLine.length);
        setIsEditing(false);
    } catch (e) {
        alert("Encountered an error while trying to edit the user!");
    }
    helpers.setSubmitting(false);
};

const reserveOrder = async (
    userInfo: string,
    serviceId: string,
    token: string
) => {
    const init: RequestInit = {
        method: "POST",
        headers: {
            authorization: token,
            Accept: "application/json",
        },
        body: userInfo,
    };

    return await apiFetch(Endpoint.ORDER, "confirm", init)
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            return res as IReserve;
        });
};

export const handleReserve = async (
    values: IReserve,
    helpers: FormikHelpers<IReserve>,
    setIsEditing: (isEditing: boolean) => void
) => {
    const userReserveInfo: { [key: string]: any } = {
        id: values.orderId,
        serviceId: values.serviceId,
        cleaningDate: values.cleaningDate,
        timeLine: values.timeLine,
    };

    try {
        setIsEditing(false);
        await reserveOrder(
            JSON.stringify(userReserveInfo),
            userReserveInfo.serviceId,
            getToken()
        );
        alert("You added it successfully!");
    } catch (e) {
        alert("Encountered an error while trying to reserve the user!");
    }
    helpers.setSubmitting(false);
};

export const handleCancel = (
    setIsEditing: (isEditing: boolean) => void,
    resetForm: () => void
) => {
    if (
        window.confirm(
            "Would you like to cancel editing your profile?\nClicking OK will not save any edited information."
        )
    ) {
        resetForm();
        setIsEditing(false);
    }
};
