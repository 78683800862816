import AppBarTop from "../../components/AppBarTop/AppBarTop";
import {useStyles} from "./ProviderPayout.style";
import { useEffect, useState } from "react";
import { useHistory, useParams} from "react-router-dom";
import { GetPaymentStatus, AddZeroes } from "./handler";
import { CircularProgress } from "@material-ui/core";

export const ProviderPayout = () => {
    const classes = useStyles();
    const {clientId, orderId} = useParams<{clientId: string, orderId: string}>();
    const [order, setOrder] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [finalPrice, setFinalPrice] = useState(0);
    const [priceBeforeTax, setPriceBeforeTax] = useState(0);
    useEffect(() => {
        GetPaymentStatus(clientId, orderId)
        .then(res => {
            console.log(res.data)
            setOrder(res.data)
            let priceBeforeTax = res.data.servicePrice * res.data.preferredTime.length;
            setPriceBeforeTax(priceBeforeTax)
            let finalPrice = priceBeforeTax - 0.03*priceBeforeTax;
            setFinalPrice(finalPrice);
            setLoading(false)
        })
    }, [])
    return (<>
        <AppBarTop />
        <div className={classes.container}>
                {loading ? <CircularProgress /> : <>
                    <h1 className={classes.heading}>Payment Details</h1><br />
                    <hr className={classes.divider} />
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <h2 className={classes.subHeading}>Client Details</h2>
                            <p className={classes.p}>Name: {order.orderedBy}</p>
                            <p className={classes.p}>Phone number: {order.clientPhone}</p>
                            <p className={classes.p}>Email: {order.clientEmail}</p>
                            <p className={classes.p}>Address: {order.clientAddress}</p>
                        </div>
                        <div className={classes.column}>
                            <h2 className={classes.subHeading}>Your Details</h2>
                            <p className={classes.p}>Name: {order.serviceProvider}</p>
                            <p className={classes.p}>Phone number: {order.providerPhone}</p>
                        </div>
                    </div>
                    <hr className={classes.divider} />
                    <h2 className={classes.subHeading}>Order Summary</h2>
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}>Service Title: </p>
                            <p className={classes.p}>Service ID: </p>
                            <p className={classes.p}>Service Type: </p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}>{order.serviceTitle}</p>
                            <p className={classes.p}>{order.serviceId}</p>
                            <p className={classes.p}>{order.serviceType.charAt(0).toUpperCase() + order.serviceType.slice(1)}</p>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}><b>Hourly Rate</b></p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}><b>${order.servicePrice.toFixed(2)}</b></p>
                        </div>
                    </div>
                    <hr className={classes.smallDivider} />
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}><b>Number of slots booked by client</b></p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}><b>{order.preferredTime.length}</b></p>
                        </div>
                    </div>
                    <hr className={classes.smallDivider} />
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}><b>Total</b></p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}><b>{priceBeforeTax.toFixed(2)}</b></p>
                        </div>
                    </div>
                    <hr className={classes.smallDivider} />
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}><b>Van-Service Transaction Fee *</b></p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}><b>- ${(priceBeforeTax * 0.03).toFixed(2)}</b></p>
                        </div>
                    </div>
                    <hr className={classes.smallDivider} />
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}><b style={{color: "#008a43"}}>You will receive</b></p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}><b style={{color: "#008a43"}}>${finalPrice.toFixed(2)}</b></p>
                        </div>
                    </div>
                    <hr className={classes.smallDivider} />
                    {/* <Button variant="contained" color="secondary" style={{marginTop: "10px", width: "90%", margin: "0 auto"}}>Pay now</Button> */}
                    <p className={classes.star}>* Van-Service Transaction fees are equal to 3% of the service fees.</p>
                    <div className={classes.paypal}>
                </div>
                </>
                }
            </div>
    </>
    )
}