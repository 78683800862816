import { Button, ButtonGroup, Typography } from "@material-ui/core";
import { useStyles } from "./ProType.style";
import AppBarTopNoUser from "../../components/AppBarTop/AppBarTopNoUser";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ProTypeIconButtonsGroup from "../../components/LandboardIconButtonsGroup/ProTypeIconButtonsGroup";
import { useHistory } from "react-router-dom";

const ProType = () => {
    const styles = useStyles();
    const history = useHistory();

    return (
        <div>
            <AppBarTopNoUser />
            <br />
            <Button
                className={styles.backBtn}
                startIcon={<ArrowBackIosIcon />}
                onClick={() => history.push("/Pros/Landboard")}
            >
                Back
            </Button>
            <br />
            <br />

            <div className={styles.center}>
                <div className={styles.containDiv}>
                    <div className={styles.div1}>
                        <div className={styles.textDiv}>
                            <Typography
                                variant="h4"
                                className={styles.inlineTitle}
                            >
                                Which type of service would you provide?
                            </Typography>
                        </div>

                        <div className={styles.div2}>
                            <br />
                            <br />
                            <br />
                            <br />
                            <ProTypeIconButtonsGroup />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProType;
