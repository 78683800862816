export interface ITabPanel {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export const tabProps = (index: any) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};
