import * as Yup from "yup";
import { UserRole } from "../../util/users";
import { Validation } from "../../util/validations";

export interface IRouteParams {
    userId: string;
}

export enum UserField {
    userId = "id",
    username = "username",
    password = "password",
    confirm_password = "confirmPassword",
    first_name = "first_name",
    last_name = "last_name",
    user_role = "role",
    phoneNumber = "phoneNumber",
    email = "email",
    address = "address",
    gender = "gender",
    province = "province",
}

export const fieldLabels = {
    [UserField.userId]: "User Id",
    [UserField.username]: "Username",
    [UserField.password]: "Enter Password",
    [UserField.confirm_password]: "Confirm Password",
    [UserField.first_name]: "First Name",
    [UserField.last_name]: "Last Name",
    [UserField.user_role]: "Role",
    [UserField.phoneNumber]: "Phone Number",
    [UserField.email]: "Email",
    [UserField.address]: "Address",
    [UserField.gender]: "Gender",
    [UserField.province]: "Province",
};

export const initialValues = {
    [UserField.userId]: "",
    [UserField.username]: "",
    [UserField.password]: "",
    [UserField.first_name]: "",
    [UserField.last_name]: "",
    [UserField.confirm_password]: "",
    [UserField.user_role]: UserRole.Client,
    [UserField.phoneNumber]: "",
    [UserField.email]: "",
    [UserField.address]: "",
    [UserField.gender]: "Unknown",
    [UserField.province]: "British Columbia",
};

export const passwordInitialValues = {
    [UserField.password]: "",
    [UserField.confirm_password]: "",
};

export type TNewUserValues = typeof initialValues;
export type TPasswordValues = typeof passwordInitialValues;

const infoValidationShape = {
    [UserField.first_name]: Yup.string()
        .label(fieldLabels[UserField.first_name])
        .required()
        .max(50),
    [UserField.last_name]: Yup.string()
        .label(fieldLabels[UserField.last_name])
        .required()
        .max(50),
    [UserField.username]: Yup.string()
        .label(fieldLabels[UserField.username])
        .required()
        .max(50),
    // [UserField.phoneNumber]: Yup.string()
    //     .matches(Validation.phoneRegExp, "Phone number is not valid")
    //     .label(fieldLabels[UserField.phoneNumber])
    //     .max(50)
    //     .required(),
    [UserField.email]: Yup.string()
        .matches(Validation.emailRegExp, "Email is not valid")
        .label(fieldLabels[UserField.email])
        .max(50)
        .required(),
    [UserField.user_role]: Yup.string()
        .label(fieldLabels[UserField.user_role])
        .required(),
    // [UserField.address]: Yup.string()
    //     .label(fieldLabels[UserField.address])
    //     .required()
    //     .max(50),
};

//Referencing Daniel's answer https://stackoverflow.com/questions/55451304/formik-yup-password-strength-validation-with-react
const passwordValidationShape = {
    [UserField.password]: Yup.string()
        .label(fieldLabels[UserField.password])
        .matches(Validation.passwordRegExp, Validation.passwordInvalidMsg)
        .required(),
    [UserField.confirm_password]: Yup.string()
        .label(fieldLabels[UserField.confirm_password])
        .required()
        .oneOf([Yup.ref(UserField.password)], "Passwords must match"),
};

export const newValidationSchema = Yup.object().shape({
    ...infoValidationShape,
    ...passwordValidationShape,
});
export const editValidationSchema = Yup.object().shape(infoValidationShape);
export const passwordValidationSchema = Yup.object().shape(
    passwordValidationShape
);