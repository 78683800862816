import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    mainDiv: {
        width: "100%",
        height: "100%",
        maxWidth: "500px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 5,
    },
    avaDiv: {
        width: "100%",
        height: "30px",
        lineHeight: "30px",
        border: "1px solid black",
        backgroundColor: "lightblue",
        textAlign: "center",
        //cursor: "pointer",
        borderRadius: 5,
        margin: 5,
    },
    unavaDiv: {
        width: "100%",
        height: "30px",
        lineHeight: "30px",
        border: "1px solid black",
        backgroundColor: "grey",
        textAlign: "center",
        borderRadius: 5,
        margin: 5,
    },
    selectDiv: {
        width: "100%",
        height: "30px",
        lineHeight: "30px",
        border: "1px solid black",
        backgroundColor: "#ff9800",
        textAlign: "center",
        //cursor: "pointer",
        borderRadius: 5,
        margin: 5,
    },
    center: {
        display: "flex",
        justifyContent: "center",
    },
    introDiv: {
        width: "100%",
        height: "100%",
        maxWidth: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 5,
    },
    avaDivTag: {
        width: "100%",
        height: "25px",
        lineHeight: "30px",
        border: "1px solid black",
        backgroundColor: "lightblue",
        textAlign: "center",
        //cursor: "pointer",
        borderRadius: 5,
        margin: 5,
    },
    unavaDivTag: {
        width: "100%",
        height: "25px",
        lineHeight: "30px",
        border: "1px solid black",
        backgroundColor: "grey",
        textAlign: "center",
        borderRadius: 5,
        margin: 6,
    },
    selectDivTag: {
        width: "100%",
        height: "25px",
        lineHeight: "30px",
        border: "1px solid black",
        backgroundColor: "#ff9800",
        textAlign: "center",
        //cursor: "pointer",
        borderRadius: 5,
        margin: 5,
    },
}));
