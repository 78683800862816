import { makeStyles, Theme } from "@material-ui/core";


export const useStyles = makeStyles((theme: Theme) => ({
    joinNow: {
        marginBottom: "2.5rem",
        backgroundColor: "#cee0f3",
        borderRadius: "1.25rem",
        padding: "1rem",
        display: "block",
        marginLeft: "24.9rem",
        marginRight: "24.9rem",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "1.5rem",
            marginRight: "1.5rem",
        }
    },
    joinNowTitle: {
        textAlign: "center",
        margin: 0,
        paddingBottom: "1rem",
        fontSize: "2.25rem",
        lineHeight: 1.16,
        display: "block",
        marginBlockStart: "0.83em",
        marginBlockEnd: "0.83em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
        fontWeight: "bold"
    },
    highlighted: {
        color: "#3a94ed",
    },
    joinNowButton: {
        textAlign: "center",
    },
    btn: {
        backgroundColor: "#ffffff",
        border: "1px solid #213f5f",
        color: "#213f5f",
        minWidth: "9rem",
        padding: "0.5rem 1rem",
        fontSize: "1.125rem",
        lineHeight: 1.34,
        width: "auto",
        outline: "none",
        cursor: "pointer",
        borderRadius: "1.75rem",
        display: "inline-flex",
        boxSizing: "border-box",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 400,
        textDecoration: "none",
        transition: "background-color 300ms ease-out",
        marginBottom: "0.8rem"
    },
}));