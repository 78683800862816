import { useStyles } from "./LandboardPopularLinks.styles";

const LandboardPopularLinks = (props: any) => {
    const styles = useStyles();
    return (
        <div className={styles.popularLinks}>
            <details className={styles.popularLinksCollapsible}>
                <summary className={styles.popularLinksTitle}>
                    <div className={styles.popularLinksPointer}>
                        <h2 className={styles.popularLinksH2}>
                            Popular categories
                        </h2>
                        <span className={styles.popularLinksArrow}>
                            <span
                                className={styles.popularLinksSelectArrow}
                            ></span>
                        </span>
                    </div>
                </summary>

                <div className={styles.popularLinksWrapper}>
                    <details className={styles.popularLinksCollapsible}>
                        <summary className={styles.popularLinksTitle}>
                            <div className={styles.popularLinksPointer}>
                                <h3 className={styles.popularLinksH3}>
                                    House Cleaning
                                </h3>
                                <ul className={styles.ul}>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </summary>
                    </details>

                    <details className={styles.popularLinksCollapsible}>
                        <summary className={styles.popularLinksTitle}>
                            <div className={styles.popularLinksPointer}>
                                <h3 className={styles.popularLinksH3}>
                                    House Moving
                                </h3>
                                <ul className={styles.ul}>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </summary>
                    </details>

                    <details className={styles.popularLinksCollapsible}>
                        <summary className={styles.popularLinksTitle}>
                            <div className={styles.popularLinksPointer}>
                                <h3 className={styles.popularLinksH3}>
                                    Repair Services
                                </h3>
                                <ul className={styles.ul}>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </summary>
                    </details>

                    <details className={styles.popularLinksCollapsible}>
                        <summary className={styles.popularLinksTitle}>
                            <div className={styles.popularLinksPointer}>
                                <h3 className={styles.popularLinksH3}>
                                    Cooking Services
                                </h3>
                                <ul className={styles.ul}>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </summary>
                    </details>

                    <details className={styles.popularLinksCollapsible}>
                        <summary className={styles.popularLinksTitle}>
                            <div className={styles.popularLinksPointer}>
                                <h3 className={styles.popularLinksH3}>
                                    Children Services
                                </h3>
                                <ul className={styles.ul}>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </summary>
                    </details>

                    <details className={styles.popularLinksCollapsible}>
                        <summary className={styles.popularLinksTitle}>
                            <div className={styles.popularLinksPointer}>
                                <h3 className={styles.popularLinksH3}>
                                    Landscaping Services
                                </h3>
                                <ul className={styles.ul}>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                    <li className={styles.li}>
                                        <a
                                            className={styles.popularLinksRef}
                                            href=""
                                        >
                                            Babysitters
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </summary>
                    </details>
                </div>
            </details>
        </div>
    );
};

export default LandboardPopularLinks;
