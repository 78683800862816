import axios from "axios";
export const GetPaymentStatus = async (userId: string, orderId: string) => {
    return axios.get(`https://helpercloud.herokuapp.com/api/users/getParticularOrder/${userId}/${orderId}`)
}
export const AddZeroes = (num: number) => {
    // Cast as number
    let final;
    var num = Number(num);
    // If not a number, return 0
    if (isNaN(num)) {
        console.log("Not a number");
        return 0;
    }
    // If there is no decimal, or the decimal is less than 2 digits, toFixed
    if (String(num).split(".").length < 2 || String(num).split(".")[1].length<=2 ){
        final = num.toFixed(2);
    }
    else final=num;
    // Return the number
    console.log(final)
    
    return final;
}