import * as Yup from "yup";
import { UserRole } from "../../util/users";
import { Validation } from "../../util/validations";

export interface IRouteParams {
    userId: string;
}

export enum UserField {
    id = "id",
    username = "username",
    first_name = "first_name",
    last_name = "last_name",
    user_role = "user_role",
    email = "email",
    address = "address",
    province = "province",
    gender = "gender",
    phoneNumber = "phoneNumber",
    self_description = "self_description",
    picture = "",
    avatar = "",
}

export const fieldLabels = {
    [UserField.id]: "User Id",
    [UserField.username]: "Username",
    [UserField.first_name]: "First Name",
    [UserField.last_name]: "Last Name",
    [UserField.user_role]: "Role",
    [UserField.address]: "Address",
    [UserField.picture]: "Picture",
    [UserField.avatar]: "Avatar",
    [UserField.email]: "Email",
    [UserField.province]: "Province",
    [UserField.gender]: "Gender",
    [UserField.phoneNumber]: "Phone Number",
    [UserField.self_description]:
        "Please introduce yourself such as your job, family, hobby, etc",
};

export const initialValues = {
    [UserField.id]: 0,
    [UserField.username]: "",
    [UserField.first_name]: "",
    [UserField.last_name]: "",
    [UserField.user_role]: UserRole.Client,
    [UserField.address]: "",
    [UserField.picture]: "",
    [UserField.avatar]: "",
    [UserField.email]: "",
    [UserField.province]: "British Columbia",
    [UserField.gender]: "Unknown",
    [UserField.phoneNumber]: "",
    [UserField.self_description]: "",
};

export type TNewUserValues = typeof initialValues;

const infoValidationShape = {
    [UserField.first_name]: Yup.string()
        .label(fieldLabels[UserField.first_name])
        .required()
        .max(50),
    [UserField.last_name]: Yup.string()
        .label(fieldLabels[UserField.last_name])
        .required()
        .max(50),
    [UserField.username]: Yup.string()
        .label(fieldLabels[UserField.username])
        .required()
        .max(50),
    [UserField.phoneNumber]: Yup.string()
        .matches(Validation.phoneRegExp, "Invalid phone number")
        .label(fieldLabels[UserField.phoneNumber])
        .max(50),
    [UserField.user_role]: Yup.string()
        .label(fieldLabels[UserField.user_role])
        .required(),
    [UserField.address]: Yup.string()
        .label(fieldLabels[UserField.address])
        .max(50),
    [UserField.email]: Yup.string().label(fieldLabels[UserField.email]).max(50),
    [UserField.province]: Yup.string()
        .label(fieldLabels[UserField.province])
        .max(50),
    // [UserField.city]: Yup.string()
    //     .label(fieldLabels[UserField.city])
    //     .max(50),
    // [UserField.birthday]: Yup.string()
    //     .label(fieldLabels[UserField.birthday])
    //     .max(50),
    [UserField.gender]: Yup.string()
        .label(fieldLabels[UserField.gender])
        .required(),
};

export const editValidationSchema = Yup.object().shape(infoValidationShape);
