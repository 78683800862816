import AppBarTop from "../../components/AppBarTop/AppBarTop";
import { useStyles } from "./TutoringOrder.style";
import { CircularProgress, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { GetServiceDetails, SubmitOrder } from "./handler";
import { useParams, Link } from "react-router-dom";
import { MdPayment } from "react-icons/md";
import DatePicker from "react-multi-date-picker";

export const TutoringOrder = () => {
    const classes = useStyles();
    const { serviceId } = useParams<{ serviceId: string }>();
    const [service, setService] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [preferredTime, setPreferredTime] = useState([]);
    const [preferredMode, setPreferredMode] = useState("online");
    const [preferredSampleClass, setPreferredSampleClass] = useState("");
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date().getTime()+(3*24*60*60*1000));
    const [errorMessage, setErrorMessage] = useState(false);
    useEffect(() => {
        GetServiceDetails(serviceId)
            .then((res) => {
                console.log(res.data);
                setService(res.data);
                setLoading(false);
            })
    }, [serviceId]);

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
        console.log(date.toString());
    }

    const handleSubmit = () => {
        setLoadingSubmit(true);
        const name = firstName + " " + lastName;
        SubmitOrder({
            serviceId, selectedDate: selectedDate.toString(), clientId: sessionStorage.getItem("userId"), clientEmail: email, orderedBy: name, preferredTime, preferredMode, clientPhone: phone, clientAddress: address, sampleClassRequested: preferredSampleClass
        })
            .then(res => {
                if (res.data.success) {
                    setLoadingSubmit(false);
                    setSuccess(true);
                    setOrderId(res.data.orderId);
                    setErrorMessage(false);
                }
                else if (res.data.success!==true && res.data.message==="SLOT_BOOKED"){
                    setLoadingSubmit(false);
                    setSuccess(false);
                    setErrorMessage(true);
                }
            })
    }

    const handleSlotSelection = (e: any) => {
        const selected = e.target.value;
        if (!preferredTime.includes(selected) && e.target.checked) {
            setPreferredTime([...preferredTime, selected]);
        }
        else if (preferredTime.includes(selected) && !e.target.checked) {
            setPreferredTime(preferredTime.filter(time => time !== selected));
        }
    }

    return (
        <>
            <AppBarTop />
            {loading ? <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div> :
                <div className={classes.container}>
                    <p className={classes.heading}>Please fill out the following details to place your order</p>
                    <p className={classes.subHeading}>Part A: Your Details</p>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Please enter your first name</p>
                        <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="Jon" />
                    </div>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Please enter your last name</p>
                        <TextField value={lastName} onChange={(e) => setLastName(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="Snow" />
                    </div>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Please enter your phone number</p>
                        <TextField value={phone} onChange={e => setPhone(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="+123456789" />
                    </div>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Please enter your e-mail</p>
                        <TextField value={email} onChange={e => setEmail(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="Jonsnow@gmail.com" />
                    </div>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Please enter your address</p>
                        <TextField value={address} onChange={e => setAddress(e.target.value)} color="secondary" variant="outlined" className={classes.formInput} placeholder="602-717 Breslay St, Coquitlam" />
                    </div>
                    <p className={classes.subHeading}>Part B: Your Tutoring Order</p>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Please select your desired mode of delivery</p>
                        <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                                <label style={{ display: "inline", fontSize: '1.2rem' }}>Online</label>
                                <input checked={preferredMode === "online" ? true : false} onClick={() => setPreferredMode("online")} type="radio" name="radio" value="Online" className={classes.radio} />
                            </div>
                            <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                                <label style={{ display: "inline", fontSize: '1.2rem' }}>Offline</label>
                                <input checked={preferredMode === "offline" ? true : false} onClick={() => setPreferredMode("offline")} type="radio" name="radio" value="Offline" className={classes.radio} />
                            </div>
                            <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                                <label style={{ display: "inline", fontSize: '1.2rem' }}>Any</label>
                                <input checked={preferredMode === "Any" ? true : false} onClick={() => setPreferredMode("Any")} type="radio" name="radio" value="Any" className={classes.radio} />
                            </div>
                        </div>
                    </div>

                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Please select your preferred date</p>
                        <DatePicker value={selectedDate} onChange={handleDateChange} format="MM/DD/YYYY" minDate={new Date().getTime()+(3*24*60*60*1000)}/>
                        </div>

                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Please select your desired time slot</p>
                        <div style={{ display: 'flex' }}>
                            {service.slots.map((slot: any, index: any) => {
                                return (<div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                                    <label className={classes.label}>{slot}</label>
                                    <input onChange={handleSlotSelection} checked={preferredTime.includes(slot)} type="checkbox" name="math" value={slot} className={classes.radio} />
                                </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Would you like to request for a free sample class?</p>
                        <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                                <label style={{ display: "inline", fontSize: '1.2rem' }}>Yes</label>
                                <input onChange={() => setPreferredSampleClass("Yes")} checked={preferredSampleClass === "Yes" ? true : false} type="radio" value="Yes" className={classes.radio} />
                            </div>
                            <div style={{ display: 'flex', margin: "0px 5px", alignItems: 'center' }}>
                                <label style={{ display: "inline", fontSize: '1.2rem' }}>No</label>
                                <input onChange={() => setPreferredSampleClass("No")} checked={preferredSampleClass === "No" ? true : false} type="radio" value="No" className={classes.radio} />
                            </div>
                        </div>
                    </div>

                    {!loadingSubmit && !success &&
                        <div className={classes.purchaseButton} onClick={handleSubmit}>
                            <MdPayment />
                            <p style={{ margin: "0px 0px" }}>Place Order</p>
                            <p style={{ margin: "0px 0px" }}></p>
                        </div>
                    }
                    {loadingSubmit && !success && <div className={classes.loaderWrapper}><CircularProgress /></div>}
                    {success && <>
                     <p className={classes.success}>Your order has been placed successfully! Your order ID is: {orderId}. See all your orders here.</p>
                     <br/><Link to={`/client/orders/prePaymentConfirmation/${orderId}`} className={classes.success}>Click here to pay and complete your order</Link>
                     </>}
                    {errorMessage && <p className={classes.failure}>That day and time and slot has already been booked. Please choose another date/time.</p>}
                </div>
            }
        </>
    );
}