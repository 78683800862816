import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    divider: {
        background: "black",
    },
    checkButton: {
        // display: "flex",
        position: "absolute",
        right: 0,
        bottom: 0,
        justifyContent: "center",
    },
    titleBottom: {
        width: "100%",
        display: "flex",
        marginBottom: "15px",
        alignItems: "center",
        justifyContent: "space-between",
    },
    starAndRateDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    starShowDiv: {
        width: "129px",
        height: "30px",
    },
}));
