import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    {
        container: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        purchaseTitle: {
            fontWeight: "bold",
            fontSize: "50px"
        },
        purchaseAmount: {
            fontWeight: "bold",
            fontSize: "25px"
        }
    }
);
