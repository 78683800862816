import { Button, Typography } from "@material-ui/core";
import dashboard from "./dashboard-images/dashboard.jpg";
import { useStyles } from "./landboard.style";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";
import TwitterIcon from "@material-ui/icons/Twitter";
import JoinForm from "../../components/LandboardJoinForm/LandboardJoinForm";
import ServiceFlow from "../../components/ServiceFlow/ServiceFlow";
import AppBarTop from "../../components/AppBarTop/AppBarTop";
import { cards } from "./fields";
import LandboardReviews from "../../components/LandboardReviews/LandboardReviews";
import LandboardSlogan from "../../components/LandboardSlogan/LandboardSlogan";
import LandboardIconButtonsGroup from "../../components/LandboardIconButtonsGroup/LandboardIconButtonsGroup";
import LandboardWhyUs from "../../components/LandboardWhyUs/LandboardWhyUs";
import LandboardPopularLinks from "../../components/LandboardPopularLinks/LandboardPopularLinks";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const Landboard = () => {
    const styles = useStyles();
    const history = useHistory();
    const cardOnclickJumpFilter = (serviceType: string) => {
        history.push({
            pathname: "/PostList",
            search: serviceType,
        });
    };

    return (
        <>
            <AppBarTop />
            <div className={styles.container}>
                <header className={styles.header}>
                    {/* <img
                        src={dashboard}
                        className={styles.house}
                        alt="LOGO_background"
                    /> */}
                </header>
            </div>

            <LandboardIconButtonsGroup />
            <LandboardReviews />
            <LandboardSlogan />

            <div className={styles.heroContent}>
                <Container maxWidth="sm">
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="textPrimary"
                        gutterBottom
                    >
                        Van Services
                    </Typography>
                    <Typography
                        variant="h5"
                        align="center"
                        color="textSecondary"
                        paragraph
                    >
                        Van service, a professional construction platform
                        located in the greater Vancouver Area, BC. We are geared
                        to introduce the needs of retail businesses, interior
                        designers and architects with our unique, high-quality
                        and fashionable products to our customer with bid fair.
                    </Typography>
                    <div className={styles.heroButtons}>
                        <Grid container spacing={2} justify="center">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={styles.learnMoreButton}
                                >
                                    Learn More
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => history.push("/Register")}
                                >
                                    Become A Pros
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>

            <LandboardWhyUs />
            <LandboardPopularLinks />

            {/* new added , form for join in */}
            <div className={styles.joinPart}>
                <JoinForm />
            </div>

            <main>
                <Container className={styles.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                        {cards.map((card) => (
                            <Grid item key={card} xs={12} sm={6} md={4}>
                                <Card className={styles.card}>
                                    <CardMedia
                                        className={styles.cardMedia}
                                        image={card.serviceImageSrc}
                                        title="Image title"
                                    />
                                    <CardContent className={styles.cardContent}>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="h2"
                                        >
                                            {card.serviceType}
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                        >
                                            {card.servicePrice}
                                        </Typography>
                                        <Typography>
                                            {card.serviceDescription}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={() =>
                                                cardOnclickJumpFilter(
                                                    card.serviceType
                                                )
                                            }
                                        >
                                            View More
                                        </Button>
                                        <Button size="small" color="primary">
                                            Contact Pros
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>

            {/* new added ,the service flow */}
            <ServiceFlow />

            {/* Footer */}
            <footer className={styles.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                    About Us
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    component="p"
                >
                    Burnaby: 8888 University Dr, Burnaby, BC V5A 1S6 Phone:
                    (778) 782-3111
                </Typography>
                <br />
                <Typography variant="h6" align="center" gutterBottom>
                    Contact Us
                </Typography>

                <Typography variant="h6" align="center">
                    <FacebookIcon className={styles.icon} />
                    <InstagramIcon className={styles.icon} />
                    <TwitterIcon className={styles.icon} />
                    <EmailIcon className={styles.icon} />
                </Typography>
                <br />
                <Copyright />
            </footer>
        </>
    );
};

export default Landboard;
