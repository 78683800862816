import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@material-ui/core";
import * as React from "react";
import AppBarTopNoUser from "../../components/AppBarTop/AppBarTopNoUser";
import { useStyles } from "./ClientOrderList.style";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { getAuth } from "../../util/auth";
import { Endpoint } from "../../util/endpoints";
import { getToken } from "../../util/cookie";

interface Column {
    id: "name" | "code" | "population" | "size" | "density" | "status" | "bill";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: "name", label: "Service Name", minWidth: 170 },
    { id: "code", label: "Service Pro's Name", minWidth: 100 },
    {
        id: "population",
        label: "Service Type",
        minWidth: 70,
        align: "right",
        format: (value: number) => value.toLocaleString("en-US"),
    },
    {
        id: "size",
        label: "Service Date",
        minWidth: 70,
        align: "right",
        format: (value: number) => value.toLocaleString("en-US"),
    },
    {
        id: "density",
        label: "Selected Time",
        minWidth: 70,
        align: "right",
        format: (value: number) => value.toFixed(2),
    },
    {
        id: "status",
        label: "Status",
        minWidth: 70,
        align: "right",
        format: (value: number) => value.toFixed(2),
    },
    {
        id: "bill",
        label: "Bill",
        minWidth: 70,
        align: "right",
        format: (value: number) => value.toFixed(2),
    },
];

// interface Data {
//     name: string;
//     code: string;
//     population: string;
//     size: string;
//     density: string;
//     status: string;
//     bill: number;
// }

// function createData(
//     name: string,
//     code: string,
//     population: string,
//     size: string,
//     density: string,
//     status: string,
//     bill: number
// ): Data {
//     return { name, code, population, size, density, status, bill };
// }

export default function ClientOrderList() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);
    const styles = useStyles();
    const history = useHistory();

    useEffect(() => {
        getRows();
    }, []);

    const getRows = async () => {
        const token: string = await getToken();
        const orderListReturn = await getAuth(Endpoint.ORDER_LIST, token);
        setRows(orderListReturn);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div>
            <AppBarTopNoUser />
            <br />
            <Button
                className={styles.backBtn}
                startIcon={<ArrowBackIosIcon />}
                onClick={history.goBack}
            >
                Back
            </Button>
            <div className={styles.containDiv}>
                <div className={styles.div1}>
                    <Typography variant="h3"> Order History</Typography>
                    <br />
                    <div className={styles.textDiv}>
                        <Paper>
                            <>
                                <TableContainer>
                                    {rows !== undefined ? (
                                        <>
                                            <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map(
                                                            (column) => (
                                                                <TableCell
                                                                    key={
                                                                        column.id
                                                                    }
                                                                    align={
                                                                        column.align
                                                                    }
                                                                    style={{
                                                                        minWidth:
                                                                            column.minWidth,
                                                                    }}
                                                                >
                                                                    {
                                                                        column.label
                                                                    }
                                                                </TableCell>
                                                            )
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows
                                                        .slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage +
                                                                rowsPerPage
                                                        )
                                                        .map((row) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    key={
                                                                        row.code
                                                                    }
                                                                >
                                                                    {columns.map(
                                                                        (
                                                                            column
                                                                        ) => {
                                                                            const value =
                                                                                row[
                                                                                    column
                                                                                        .id
                                                                                ];
                                                                            return (
                                                                                <TableCell
                                                                                    key={
                                                                                        column.id
                                                                                    }
                                                                                    align={
                                                                                        column.align
                                                                                    }
                                                                                >
                                                                                    {column.format &&
                                                                                    typeof value ===
                                                                                        "number"
                                                                                        ? column.format(
                                                                                              value
                                                                                          )
                                                                                        : value}
                                                                                </TableCell>
                                                                            );
                                                                        }
                                                                    )}
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                            <TablePagination
                                                rowsPerPageOptions={[
                                                    10, 25, 100,
                                                ]}
                                                component="div"
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={
                                                    handleChangeRowsPerPage
                                                }
                                            />
                                        </>
                                    ) : (
                                        <Typography>
                                            Sorry, you have not any orders yet!
                                            Please{" "}
                                        </Typography>
                                    )}
                                </TableContainer>
                            </>
                        </Paper>
                    </div>
                </div>
            </div>
        </div>
    );
}
