export interface IUser {
    id: number;
    email: string;
    username: string;
    first_name: string;
    last_name: string;
    user_role: UserRole;
    address: string;
    gender: string;
    phoneNumber: string;
    province: string;
    favorites: string[];
    feedbackAvgRate: number;
    feedbackCounter: number;
    createdAt: Date;
    picture: string;
    avatar: string;
    self_description: string;
}

export enum UserRole {
    Client = "Client",
    Pros = "Pros",
}

export enum Gender {
    Male = "Male",
    Female = "Female",
    Unknown = "Unknown",
}

export enum Province {
    Alberta = "Alberta",
    BritishColumbia = "British Columbia",
    Manitoba = "Manitoba",
    NewBrunswick = "New Brunswick",
    NewfoundlandandLabrador = "Newfoundland and Labrador",
    NorthwestTerritories = "Northwest Territories",
    NovaScotia = "Nova Scotia",
    Nunavut = "Nunavut",
    Ontario = "Ontario",
    PrinceEdwardIsland = "Prince Edward Island",
    Quebec = "Quebec",
    Saskatchewan = "Saskatchewan",
    Yukon = "Yukon",
}

export enum BCCity {
    Burnaby = "Burnaby",
    Delta = "Delta",
    Kamloops = "Kamloops",
    Kelowna = "Kelowna",
    Langley = "Langley",
    NewWestminster = "New Westminster",
    NorthVancouver = "North Vancouver",
    PortCoquitlam = "Port Coquitlam",
    Richmond = "Richmond",
    Surrey = "Surrey",
    Vancouver = "Vancouver",
    Victoria = "Victoria",
    WhiteRock = "White Rock",
}

export const userRoles = {
    [UserRole.Client]: {
        name: "Client",
    },
    [UserRole.Pros]: {
        name: "Pros",
    },
};

export const userGenders = {
    [Gender.Male]: {
        name: "Male",
    },
    [Gender.Female]: {
        name: "Female",
    },
    [Gender.Unknown]: {
        name: "Unknown",
    },
};

export const userProvinces = {
    [Province.Alberta]: {
        name: "Alberta",
    },
    [Province.BritishColumbia]: {
        name: "British Columbia",
    },
    [Province.Manitoba]: {
        name: "Manitoba",
    },
    [Province.NewBrunswick]: {
        name: "New Brunswick",
    },
    [Province.NewfoundlandandLabrador]: {
        name: "Newfoundland and Labrador",
    },
    [Province.NorthwestTerritories]: {
        name: "Northwest Territories",
    },
    [Province.NovaScotia]: {
        name: "Nova Scotia",
    },
    [Province.Nunavut]: {
        name: "Nunavut",
    },
    [Province.Ontario]: {
        name: "Ontario",
    },
    [Province.PrinceEdwardIsland]: {
        name: "Prince Edward Island",
    },
    [Province.Quebec]: {
        name: "Quebec",
    },
    [Province.Saskatchewan]: {
        name: "Saskatchewan",
    },
    [Province.Yukon]: {
        name: "Yukon",
    },
};

export const bcCity = {
    [BCCity.Burnaby]: {
        name: "Burnaby",
    },
    [BCCity.NorthVancouver]: {
        name: "North Vancouver",
    },
    [BCCity.PortCoquitlam]: {
        name: "Port Coquitlam",
    },
    [BCCity.Richmond]: {
        name: "Richmond",
    },
    [BCCity.Surrey]: {
        name: "Surrey",
    },
    [BCCity.Vancouver]: {
        name: "Vancouver",
    },
    [BCCity.Victoria]: {
        name: "Victoria",
    },
    [BCCity.Delta]: {
        name: "Delta",
    },
    [BCCity.Kamloops]: {
        name: "Kamloops",
    },
    [BCCity.Kelowna]: {
        name: "Kelowna",
    },
    [BCCity.Langley]: {
        name: "Langley",
    },
    [BCCity.NewWestminster]: {
        name: "New Westminster",
    },
    [BCCity.WhiteRock]: {
        name: "White Rock",
    },
};
