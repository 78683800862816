// import history from "../../util/history";
import { FormikHelpers } from "formik";
import { apiFetch, Endpoint } from "../../util/endpoints";
import { IUser } from "../../util/users";
import { getToken } from "../../util/cookie";
const updateUser = async (userInfo: string, token: string) => {
    const init: RequestInit = {
        method: "PATCH",
        headers: {
            authorization: token,
            Accept: "application/json",
        },
        body: userInfo,
    };
    return await apiFetch(Endpoint.PROFILE, "", init)
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            return res as IUser;
        });
};
const updatePicture = async (pictureForm: FormData, token: string) => {
    const init: RequestInit = {
        method: "POST",
        headers: {
            authorization: token,
            ContentType: "form-data",
        },
        body: pictureForm,
        redirect: "follow",
    };
    return await apiFetch(Endpoint.AVATAR, "", init);
};

export const handleSubmit = async (
    values: IUser,
    helpers: FormikHelpers<IUser>,
    setIsEditing: (isEditing: boolean) => void
) => {
    const userInfo: { [key: string]: any } = {
        username: values.username,
        first_name: values.first_name,
        last_name: values.last_name,
        phoneNumber: values.phoneNumber,
        user_role: values.user_role,
        address: values.address,
        gender: values.gender,
        province: values.province,
        self_description: values.self_description,
    };
    if (values.avatar) {
        const pictureForm = new FormData();
        const clientProfilePicture = await (await fetch(values.avatar)).blob();
        pictureForm.append("avatar", clientProfilePicture, ".png");
        try {
            await updatePicture(pictureForm, getToken());
        } catch (e) {
            alert("Encountered an error while trying to edit the picture!");
        }
    }
    try {
        await updateUser(JSON.stringify(userInfo), getToken());
        setIsEditing(false);
    } catch (e) {
        alert("Encountered an error while trying to edit the user!");
    }
    helpers.setSubmitting(false);
};

export const handleCancel = (
    setIsEditing: (isEditing: boolean) => void,
    resetForm: () => void
) => {
    if (
        window.confirm(
            "Would you like to cancel editing your profile?\nClicking OK will not save any edited information."
        )
    ) {
        resetForm();
        setIsEditing(false);
    }
};
