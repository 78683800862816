import { useStyles } from "./OrderPage.style";
import AppBarTopNoUser from "../../components/AppBarTop/AppBarTopNoUser";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useEffect, useState } from "react";
import { getAuth } from "../../util/auth";
import { getToken } from "../../util/cookie";
import { Endpoint } from "../../util/endpoints";
import { useHistory, useParams } from "react-router-dom";
import { IUser } from "../../util/users";
import { IService } from "../../util/service";
import { Field, Form, Formik } from "formik";
import { Alert, Skeleton } from "@material-ui/lab";
import { editValidationSchema, fieldLabels, OrderField } from "./fields";
import { TextField } from "formik-material-ui";
import { Divider, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { handleChange, handleCancel } from "./handler";

export interface IReserve {
    first_name: string;
    last_name: string;
    address: string;
    phoneNumber: string;
    zipcode: string;
    orderId: string;
    serviceId: string;
    cleaningDate: string;
    timeLine: number[];
    orderAmount: number;
    isReceived: boolean;
}

interface IServiceDetail {
    service: IService;
    serviceOwner: IUser;
}

const OrderResultDetail = () => {
    const styles = useStyles();
    const [loadingError, setLoadingError] = useState(false);
    const [reserveInfo, setReserveInfo] = useState<IReserve>();
    const [service, setService] = useState<IService>();
    const [serviceOwner, setServiceOwner] = useState<IUser>();
    const [isEditing, setIsEditing] = useState(false);
    const { orderId } = useParams<{ orderId: string }>();
    const history = useHistory();

    const getRerserveDetail = async () => {
        try {
            const token: string = getToken();
            const theReserve = await getAuth(
                Endpoint.ORDER + orderId,
                token
            );
            const serviceObj: IServiceDetail = await getAuth(
                Endpoint.DETAIL_SERVICE + theReserve.serviceId,
                token
            );
            const theReserveInfo = {
                orderId: theReserve._id,
                serviceId: theReserve.serviceId,
                equipment: theReserve.equipment,
                address: theReserve.address,
                zipcode: theReserve.zipcode,
                first_name: theReserve.firstName,
                last_name: theReserve.lastName,
                phoneNumber: theReserve.phoneNumber,
                cleaningDate: theReserve.cleaningDate,
                timeLine: theReserve.timeLine,
                orderAmount: theReserve.orderAmount,
                isReceived: true,
            } as IReserve;
            console.log(serviceObj);
            setReserveInfo(theReserveInfo);
            setServiceOwner(serviceObj.serviceOwner);
            setService(serviceObj.service);
            var parts = theReserveInfo.cleaningDate.split("-");
            var orderDate = new Date(
                parseInt(parts[0]),
                parseInt(parts[1]) - 1,
                parseInt(parts[2])
            );
        } catch (e) {
            setLoadingError(true);
        }
    };

    useEffect(() => {
        getRerserveDetail();
    }, []);

    return loadingError ? (
        <Alert severity="error">
            Something went wrong trying to load that user. Please go back and
            try again.
        </Alert>
    ) : reserveInfo ? (
        <Formik
            initialValues={reserveInfo}
            validationSchema={editValidationSchema}
            onSubmit={(values, setSubmitting) => {
                handleChange(values, setSubmitting, setIsEditing);
            }}
        >
            {({ isSubmitting, resetForm, setFieldValue }) => (
                <div>
                    <AppBarTopNoUser />
                    <br />
                    <Button
                        className={styles.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={history.goBack}
                    >
                        Back
                    </Button>
                    <div className={styles.infoDiv}>
                        {reserveInfo.isReceived ? (
                            <div className={styles.noticeDiv}>
                                <div className={styles.noticeInfo}>
                                    Your order has been received. Please wait
                                    for the pro's confirmation.
                                </div>
                            </div>
                        ) : (
                            <div className={styles.noticeDiv2}>
                                <div className={styles.noticeInfo2}>
                                    Sorry, you transaction is not successful.
                                    Please try it again.
                                </div>
                            </div>
                        )}
                        <Form className={styles.formStyle}>
                            <br />
                            <Grid item md={4} xs={12}>
                                {/*<Field*/}
                                {/*    component={TextField}*/}
                                {/*    disabled={!isEditing}*/}
                                {/*    className={styles.fieldStyle}*/}
                                {/*    name={*/}
                                {/*        OrderField.first_name*/}
                                {/*    }*/}
                                {/*    variant="filled"*/}
                                {/*    label={*/}
                                {/*        fieldLabels[OrderField.cleaningDate]*/}
                                {/*    }*/}
                                {/*    required*/}
                                {/*    fullWidth*/}
                                {/*/>*/}
                                <Typography
                                    variant="h6"
                                    align="left"
                                    className={styles.boldFont}
                                >
                                    {reserveInfo.first_name +
                                        " " +
                                        reserveInfo.last_name}
                                </Typography>
                            </Grid>
                            <Divider className={styles.divider} />
                            <div className={styles.detailDiv}>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    className={styles.boldFont}
                                >
                                    Order Detail
                                </Typography>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    className={styles.fieldStyle}
                                >
                                    Payment Amount: {reserveInfo.orderAmount}{" "}
                                    CAD
                                </Typography>
                                <Grid item md={4} xs={12}>
                                    <Field
                                        component={TextField}
                                        disabled={!isEditing}
                                        className={styles.fieldStyle}
                                        name={OrderField.cleaningDate}
                                        variant="filled"
                                        label={
                                            fieldLabels[OrderField.cleaningDate]
                                        }
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Field
                                        component={TextField}
                                        disabled={!isEditing}
                                        className={styles.fieldStyle}
                                        name={OrderField.address}
                                        variant="filled"
                                        label={fieldLabels[OrderField.address]}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Field
                                        component={TextField}
                                        disabled={!isEditing}
                                        className={styles.fieldStyle}
                                        name={OrderField.zipcode}
                                        variant="filled"
                                        label={fieldLabels[OrderField.zipcode]}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </div>
                            <Divider className={styles.divider} />
                            <div className={styles.detailDiv}>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    className={styles.boldFont}
                                >
                                    Service Detail
                                </Typography>
                                <Grid item md={4} xs={12}>
                                    <Field
                                        component={TextField}
                                        disabled={!isEditing}
                                        className={styles.fieldStyle}
                                        name={OrderField.address}
                                        variant="filled"
                                        label={fieldLabels[OrderField.address]}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Field
                                        component={TextField}
                                        disabled={!isEditing}
                                        className={styles.fieldStyle}
                                        name={OrderField.phoneNumber}
                                        variant="filled"
                                        label={
                                            fieldLabels[OrderField.phoneNumber]
                                        }
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </div>
                            <Divider className={styles.divider} />
                            <div>Contact us to get support</div>
                            {/* <div>
                                Phone call:{" "}
                                {!serviceOwner ? "" : serviceOwner.phoneNumber}
                            </div> */}
                            <br />
                            {/* <Divider className={styles.divider} /> */}
                            <br />
                            {isEditing ? (
                                <Grid justify="center" container spacing={1}>
                                    <Button
                                        variant="outlined"
                                        className={styles.cancelBtn}
                                        onClick={() => {
                                            handleCancel(
                                                setIsEditing,
                                                resetForm
                                            );
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={styles.saveBtn}
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            ) : (
                                <br />
                                // <Button
                                //     variant="contained"
                                //     className={styles.changeBtn}
                                //     onClick={() => {
                                //         setIsEditing(true);
                                //     }}
                                // >
                                //     Change your reservation
                                // </Button>
                            )}
                        </Form>
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            )}
        </Formik>
    ) : (
        <Skeleton variant="rect" height={500} />
    );
};

export default OrderResultDetail;
