import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        impOutcomeAccordion: {
            "&.MuiAccordion-root": {
                backgroundColor: "red",
            },
            "& .MuiAccordionDetails-root": {
                display: "block",
            },
            "& .MuiCardContent-root": {
                padding: "8px 16px",
            },
        },
        root: {
            display: "flex",
            flexWrap: "wrap",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: "50",
            height: "200px",
        },
        completeIcon: {
            color: "green",
            verticalAlign: "text-top",
        },
        pendingIcon: {
            color: "red",
            verticalAlign: "text-top",
        },
        resolveAccordion: {
            display: "block",
            textAlign: "right",
        },
        resolveBtn: {
            color: "green",
        },
        editorDivStyle: {
            width: "100%",
            height: "100px",
            backgroundColor: "white",
            color: "black",
            border: "1px solid grey",
            borderRadius: "5px",
        },
    })
);
