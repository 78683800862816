import { FormikHelpers } from "formik";
import { apiFetch, Endpoint } from "../../util/endpoints";
import { TFormValues } from "./formFields";
import history from "../../util/history";
import { getToken } from "../../util/cookie";

const deleteService = async (serviceId: string) => {
    const init: RequestInit = {
        method: "DELETE",
        headers: {
            authorization: getToken(),
            Accept: "application/json",
        },
        // body: serviceId,
    };
    return await apiFetch(Endpoint.SERVICE, "/" + serviceId, init)
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            return res;
        });
};

export const handleDelete = async (
    serviceId: string,
    // helpers: FormikHelpers<TFormValues>,
    setSubmissionError: React.Dispatch<React.SetStateAction<boolean>>
) => {
    // const newSurvey = JSON.stringify({
    //     //  TODO:
    // });

    try {
        deleteService(serviceId);
        history.go(0);
    } catch (e) {
        // helpers.setSubmitting(false);
        setSubmissionError(true);
    }
};