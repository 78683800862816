import { Field, Form, Formik } from "formik";
import { CardContent, Grid, IconButton, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import {
    fieldLabels,
    LoginField,
    initialValues,
    newValidationSchema,
} from "./fields";
import { TextField } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import {
    AppBar,
    Card,
    CardHeader,
    Divider,
    Tab,
    Tabs,
} from "@material-ui/core";
import { tabProps } from "../../util/tabPanel";
import { useStyles } from "./Login.style";
import { saveUserToken } from "./handler";
import { useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { FormikHelpers } from "formik";
import { TNewUserValues, errorMessage } from "./fields";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { Helmet } from "react-helmet";
import { ReactComponent as Logo } from "../../images/logo.svg";

const Login = () => {
    const styles = useStyles();
    const history = useHistory();
    const {updateUser} = useContext(UserContext);
    const [value, setValue] = useState(0);
    const [status, setStatus] = useState(0);

    const handleDash = () => {
        return history.push("/");
    };

    const handleRegister = () => {
        return history.push("/Register");
    };
    const handleForgetPassword = () => {
        return history.push("/ForgetPassword");
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleLogin = async (
        values: TNewUserValues,
        helpers: FormikHelpers<TNewUserValues>
    ) => {
        const newUser = JSON.stringify({
            email: values.email,
            password: values.password,
        });
        try {
            await saveUserToken(newUser)
            .then(res => {
                setStatus(res);
                updateUser(sessionStorage.getItem("userId"), sessionStorage.getItem("name"), sessionStorage.getItem("accountType"));
            })

        } catch (e) {
            setStatus(-1);
            helpers.setSubmitting(false);
        }
    };

    const LoginAlert = () => {
        return (
            <Collapse in={status !== 0}>
                <Alert
                    severity="error"
                    className={styles.alertBox}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setStatus(0);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    <AlertTitle>Login Error</AlertTitle>
                    {errorMessage[status]} —{" "}
                    <strong>Please check it out!</strong>
                </Alert>
            </Collapse>
        );
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={newValidationSchema}
                onSubmit={handleLogin}
            >
                {({ isSubmitting }) => (
                    <div className={styles.container}>
                        <Button
                            onClick={handleDash}
                            className={styles.topLeftBtn}
                        >
                            <Logo />
                        </Button>

                        <LoginAlert />

                        <br />
                        <br />
                        <Card className={styles.card}>
                            <CardHeader
                                title="Login"
                                className={styles.header}
                                titleTypographyProps={{ variant: "h2" }}
                            />
                            <Divider variant="middle" />
                            <AppBar position="static">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Loading"
                                    centered
                                >
                                    <Tab label="Email" {...tabProps(0)} />
                                </Tabs>
                            </AppBar>
                            <br />
                            <Form>
                                <Grid container spacing={2} justify="center">
                                    <Grid item md={10} xs={12}>
                                        <Field
                                            component={TextField}
                                            name={LoginField.email}
                                            variant="filled"
                                            label={
                                                fieldLabels[LoginField.email]
                                            }
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <br />

                                    <Grid item md={10} xs={12}>
                                        <Field
                                            component={TextField}
                                            name={LoginField.password}
                                            variant="filled"
                                            type="password"
                                            label={
                                                fieldLabels[LoginField.password]
                                            }
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <br />
                                </Grid>
                                <br />
                                <div>
                                    <Grid
                                        container
                                        justify="center"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                disabled={isSubmitting}
                                                className={styles.submitButton}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                </div>
                            </Form>
                            <Divider variant="middle" />

                            <Button onClick={handleForgetPassword}>
                                Forget Your Password?
                            </Button>

                            <Button onClick={handleRegister}>
                                Don't have an account?
                            </Button>
                        </Card>
                    </div>
                )}
            </Formik>
        </>
    );
};

export default Login;

// <div className={styles.container}>
//     <header>
//         <Button onClick={handleDash} className={styles.topLeftBtn}>
//             LOGO
//         </Button>
//     </header>
//     <br />
//     <br />
//     <Card className={styles.card}>
//         <CardHeader title="Login" className={styles.header} />
//         <Divider variant="middle" />
//         <TabPanel value={value} index={0}>
//             Login via Email
//             <CardContent>
//                 <Typography variant="h4" align="center">
//                     <form onSubmit={(e) => handleLogin(e)}>
//                         <TextField
//                             label="Email"
//                             fullWidth
//                             inputProps={{ autoCapitalize: "off" }}
//                             required
//                             InputLabelProps={{ required: false }}
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                         />
//                         <br />
//                         <br />
//                         <TextField
//                             label="Password"
//                             type="password"
//                             fullWidth
//                             required
//                             InputLabelProps={{ required: false }}
//                             value={password}
//                             onChange={(e) =>
//                                 setPassword(e.target.value)
//                             }
//                         />
//                     </form>
//                     {/* <LoginAlert /> */}
//                     {/* <Button
//                         type="submit"
//                         variant="contained"
//                         color="primary"
//                         disabled={status === LoginStatus.SUBMITTING}
//                     >
//                         Login
//                     </Button> */}
//                     <br />
//                     <br />
//                 </Typography>
//             </CardContent>
//         </TabPanel>
//         <TabPanel value={value} index={1}>
//             Login via Phone number
//             <CardContent>
//                 <Typography variant="h4" align="center">
//                     <form onSubmit={(e) => handleLogin(e)}>
//                         <TextField
//                             label="Phone Number"
//                             fullWidth
//                             inputProps={{ autoCapitalize: "off" }}
//                             required
//                             InputLabelProps={{ required: false }}
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                         />
//                         <br />
//                         <br />
//                         <TextField
//                             label="Password"
//                             type="password"
//                             fullWidth
//                             required
//                             InputLabelProps={{ required: false }}
//                             value={password}
//                             onChange={(e) =>
//                                 setPassword(e.target.value)
//                             }
//                         />
//                     </form>
//                 </Typography>
//             </CardContent>
//         </TabPanel>
//         <LoginAlert />
//         <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             disabled={status === LoginStatus.SUBMITTING}
//         >
//             Login
//         </Button>
//         <br />
//         <br />
//         <Divider variant="middle" />
//         <Button onClick={handleSignIn}>Forget Your Password?</Button>
//         <Button onClick={handleSignIn}>Don't have an account?</Button>
//     </Card>
// </div>
