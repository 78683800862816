import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { getToken } from "../../util/cookie";
import { Endpoint } from "../../util/endpoints";
import { getAuth } from "../../util/auth";

interface IProps {
    value?: Date;
    field?: string;
    updateDate?: (value: any) => any;
    disabled?: boolean;
    orders?: [];
    setTimeList?: (value: any) => void;
    serviceId?: any;
    orderTimeLine?: number[];
    orderDate?: any;
}

const DatePicker = (props: IProps) => {
    const getTimeList = async (date: Date) => {
        const token: string = getToken();
        let timeListReturn = await getAuth(
            Endpoint.ORDER_TIME + props.serviceId + "/" + date,
            token
        );
        if (!!props.orderTimeLine && !!props.orderDate) {
            await Promise.all(
                props.orderTimeLine.map(async (timeline: any) => {
                    timeListReturn[parseInt(timeline) - 9] = 0;
                })
            );
        }
        return timeListReturn;
    };
    const handleChange = async (date: Date) => {
        props.updateDate(date);
        if (props.setTimeList) {
            const timeListTemp = await getTimeList(date);
            props.setTimeList(timeListTemp);
        }
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                    margin="normal"
                    format="MM/dd/yyyy"
                    value={props.value}
                    disabled={props.disabled}
                    disablePast={true}
                    onChange={handleChange}
                    showTodayButton={true}
                    maxDate={new Date().getTime() + 12 * 24 * 60 * 60 * 1000} //maxDate
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

export default DatePicker;
