import * as Yup from "yup";
import { Validation } from "../../util/validations";

export interface IRouteParams {
    userId: string;
}

export enum ServiceField {
    serviceId = "_id",
    serviceType = "serviceType",
    serviceTitle = "serviceTitle",
    serviceArea = "serviceArea",
    serviceCity = "serviceCity",
    serviceAddress = "serviceAddress",
    servicePostcode = "servicePostcode",
    serviceContact = "serviceContact",
    serviceContactPhoneNumber = "serviceContactPhoneNumber",
    serviceContactBackupPhoneNumber = "serviceContactBackupPhoneNumber",
    serviceContactEmail = "serviceContactEmail",
    serviceLanguage = "serviceLanguage",
    serviceDescription = "serviceDescription",
    serviceDate = "serviceDate",
    serviceTimeRange = "serviceTimeRange",
}

export const fieldLabels = {
    [ServiceField.serviceId]: "ID",
    [ServiceField.serviceType]: "Type",
    [ServiceField.serviceTitle]: "Title",
    [ServiceField.serviceArea]: "Area",
    [ServiceField.serviceCity]: "City",
    [ServiceField.serviceAddress]: "Address",
    [ServiceField.servicePostcode]: "Postcode",
    [ServiceField.serviceContact]: "Contact",
    [ServiceField.serviceContactPhoneNumber]: "Phone Number",
    [ServiceField.serviceContactBackupPhoneNumber]: "Back up Phone Number",
    [ServiceField.serviceContactEmail]: "Email",
    [ServiceField.serviceLanguage]: "Language",
    [ServiceField.serviceDescription]: "Description",
    [ServiceField.serviceTimeRange]: "Service Time Range",
    [ServiceField.serviceDate]: "Service Date",
};

export const initialValues = {
    [ServiceField.serviceId]: "",
    [ServiceField.serviceType]: "",
    [ServiceField.serviceTitle]: "",
    [ServiceField.serviceArea]: "",
    [ServiceField.serviceCity]: "",
    [ServiceField.serviceAddress]: "",
    [ServiceField.servicePostcode]: "",
    [ServiceField.serviceContact]: "",
    [ServiceField.serviceContactPhoneNumber]: "",
    [ServiceField.serviceContactBackupPhoneNumber]: "",
    [ServiceField.serviceContactEmail]: "",
    [ServiceField.serviceLanguage]: [] as string[],
    [ServiceField.serviceDescription]: "",
    [ServiceField.serviceDate]: "",
    [ServiceField.serviceTimeRange]: [] as number[],
};

export type TNewServiceValues = typeof initialValues;

const infoValidationShape = {
    [ServiceField.serviceType]: Yup.string()
        .label(fieldLabels[ServiceField.serviceType])
        .required()
        .max(50),
    [ServiceField.serviceTitle]: Yup.string()
        .label(fieldLabels[ServiceField.serviceTitle])
        .required()
        .max(50),
    [ServiceField.serviceArea]: Yup.string()
        .label(fieldLabels[ServiceField.serviceArea])
        .required()
        .max(50),
    [ServiceField.serviceAddress]: Yup.string()
        .label(fieldLabels[ServiceField.serviceAddress])
        .required()
        .max(50),
    [ServiceField.serviceCity]: Yup.string()
        .label(fieldLabels[ServiceField.serviceAddress])
        .required()
        .max(50),
    [ServiceField.servicePostcode]: Yup.string()
        .label(fieldLabels[ServiceField.servicePostcode])
        .required()
        .max(50),
    [ServiceField.servicePostcode]: Yup.string()
        .label(fieldLabels[ServiceField.servicePostcode])
        .required()
        .max(50),
    [ServiceField.serviceContact]: Yup.string()
        .label(fieldLabels[ServiceField.serviceContact])
        .required()
        .max(50),
    [ServiceField.serviceContactPhoneNumber]: Yup.string()
        .matches(Validation.phoneRegExp, "Phone number is not valid")
        .label(fieldLabels[ServiceField.serviceContactPhoneNumber])
        .max(50)
        .required(),
    [ServiceField.serviceContactBackupPhoneNumber]: Yup.string()
        .matches(Validation.phoneRegExp, "Phone number is not valid")
        .label(fieldLabels[ServiceField.serviceContactBackupPhoneNumber])
        .max(50),

    [ServiceField.serviceContactEmail]: Yup.string()
        .matches(Validation.emailRegExp, "Email is not valid")
        .label(fieldLabels[ServiceField.serviceContactEmail])
        .max(50),
    [ServiceField.serviceDescription]: Yup.string()
        .label(fieldLabels[ServiceField.serviceDescription])
        .required(),
    [ServiceField.serviceDate]: Yup.date()
        .label(fieldLabels[ServiceField.serviceDate])
        .max(new Date(), "Service Date cannot be in the past")
        .required(),
};

export const newValidationSchema = Yup.object().shape({
    ...infoValidationShape,
});
export const editValidationSchema = Yup.object().shape(infoValidationShape);

export enum ServiceTypeList {
    HouseholdCleaning = "Cleaning",
    ChannelLeakage = "Channel leakage",
    Gardening = "Gardening",
    InteriorDesign = "Interior design",
    BuildingDecoration = "Building decoration",
    Painting = "Painting",
    Electrician = "Electrician",
}

export const serviceTypeList = {
    [ServiceTypeList.HouseholdCleaning]: {
        name: "Household cleaning",
    },
    [ServiceTypeList.ChannelLeakage]: {
        name: "Channel leakage",
    },
    [ServiceTypeList.Gardening]: {
        name: "Gardening",
    },
    [ServiceTypeList.InteriorDesign]: {
        name: "Interior design",
    },
    [ServiceTypeList.BuildingDecoration]: {
        name: "Building decoration",
    },
    [ServiceTypeList.Painting]: {
        name: "Painting",
    },
    [ServiceTypeList.Electrician]: {
        name: "Electrician",
    },
};
