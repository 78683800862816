import { makeStyles, Theme } from "@material-ui/core";
import { Repeat } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

export const useStyles = makeStyles((theme: Theme) => ({
    whyUs: {
        marginBottom: "2.5rem",
        backgroundColor: "#cee0f3",
        borderRadius: "1.25rem",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        marginLeft: "24.9rem",
        marginRight: "24.9rem",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "1.5rem",
            marginRight: "1.5rem",
        }
    },
    whyUsTitle: {
        textAlign: "center",
        paddingBottom: "0.8rem",
        fontSize: "1.75rem",
        lineHeight: 1.215,
        margin: 0,
        fontWeight: 700,
        display: "block",
        marginBlockStart: "0.83em",
        marginBlockEnd: "0.83em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
    },
    whyUsItems: {
        padding: "0 3.5rem",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.6rem',
            padding: "0 1.5rem",
        }
    },
    whyUsItem: {
        display: "flex",
        justifyContent: "space-between",
        width: "33%",
        paddingRight: "1.5rem"
    },
    whyUsBullet: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#00a878",
        borderRadius: "50%",
        minWidth: "1.375rem",
        height: "1.375rem",
        marginRight: "1rem"
    },
    whyUsText: {
        fontSize: "1.125rem",
        lineHeight: 1.333,
        display: "block",
        paddingBottom: "0.8rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.6rem',
        }
    },
}));