import { FormikHelpers } from "formik";
import { apiFetch, Endpoint } from "../../util/endpoints";
import { getToken } from "../../util/cookie";
import { IEditService } from "./ProServiceProfile";

const updateService = async (
    serviceInfo: string,
    token: string,
    serviceId: string
) => {
    const init: RequestInit = {
        method: "PATCH",
        headers: {
            authorization: token,
            Accept: "application/json",
        },
        body: serviceInfo,
    };
    return await apiFetch(Endpoint.SERVICE, "/" + serviceId, init)
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            return res;
        });
};

export const handleSubmit = async (
    values: IEditService,
    helpers: FormikHelpers<IEditService>,
    setIsEditing: (isEditing: boolean) => void,
    serviceId: string
) => {
    const serviceInfo: { [key: string]: any } = {
        firstName: values.firstName,
        lastName: values.lastName,
        servicePrice: values.servicePrice,
        serviceDescription: values.serviceDescription,
        phoneNumber: values.phoneNumber,
    };

    try {
        await updateService(JSON.stringify(serviceInfo), getToken(), serviceId);
        setIsEditing(false);
    } catch (e) {
        alert("Encountered an error while trying to edit the user!");
    }
    helpers.setSubmitting(false);
};

export const handleCancel = (
    setIsEditing: (isEditing: boolean) => void,
    resetForm: () => void
) => {
    if (
        window.confirm(
            "Would you like to cancel editing your profile?\nClicking OK will not save any edited information."
        )
    ) {
        resetForm();
        setIsEditing(false);
    }
};
