import * as Yup from "yup";
import { Validation } from "../../util/validations";

export enum OrderField {
    orderId = "orderId",
    first_name = "first_name",
    last_name = "last_name",
    address = "address",
    phoneNumber = "phoneNumber",
    zipcode = "zipcode",
    cleaningDate = "cleaningDate",
    timeLine = "timeLine",
}

export const fieldLabels = {
    [OrderField.first_name]: "First Name",
    [OrderField.last_name]: "Last Name",
    [OrderField.address]: "Address",
    [OrderField.zipcode]: "Zipcode",
    [OrderField.phoneNumber]: "Phone Number",
    [OrderField.cleaningDate]: "Cleaning Date",
    [OrderField.timeLine]: "Time Line",
};

export const initialValues = {
    [OrderField.first_name]: "",
    [OrderField.last_name]: "",
    [OrderField.address]: "",
    [OrderField.zipcode]: "",
    [OrderField.phoneNumber]: "",
    [OrderField.cleaningDate]: "",
    // @ts-ignore
    [OrderField.timeLine]: [],
};

export type TNewUserValues = typeof initialValues;

const infoValidationShape = {
    [OrderField.first_name]: Yup.string()
        .label(fieldLabels[OrderField.first_name])
        .required()
        .max(50),
    [OrderField.last_name]: Yup.string()
        .label(fieldLabels[OrderField.last_name])
        .required()
        .max(50),

    [OrderField.phoneNumber]: Yup.string()
        .matches(Validation.phoneRegExp, "Invalid phone number")
        .label(fieldLabels[OrderField.phoneNumber])
        .max(50),
    [OrderField.address]: Yup.string()
        .label(fieldLabels[OrderField.address])
        .max(50),
    [OrderField.zipcode]: Yup.string()
        .matches(Validation.zipcodeRegExp, "Invalid zip code")
        .label(fieldLabels[OrderField.zipcode])
        .max(50),
    [OrderField.timeLine]: Yup.array()
        // @ts-ignore
        .label(fieldLabels[OrderField.timeLine])
        .required()
        .min(1, "Please select at least one timeline")
        .test('arraytest', 'Invalid TimeLine', (value) => {
            let copy: number[] = [];
            // let preNum = 0;
            // let nextNum = 0;
            if (value.length > 1) {
                value.forEach(v => {
                    copy.push(parseInt(v));
                })
                // copy = value.concat();
                copy.sort((a, b) => {
                    return a-b;
                });
                for (let i=0; i < value.length-1; i++) {
                    if (copy[i+1] !== copy[i]+1) {
                        return false;
                    }
                }
            }
            return true;
        }),
};

export const editValidationSchema = Yup.object().shape(infoValidationShape);
