import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: '85vh',
        width: '100%',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    left: {
        width: '20%',
        height: '100%',
        borderRight: '1px solid #ebebeb',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    right: {
        width: '20%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    heading: {
        fontSize: '3.5rem',
        textAlign: 'center',
    },
    middle: {
        width: '60%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRight: '1px solid #ebebeb',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    inputBox: {
        width: '70%',
        marginTop: '30px',
        padding: '1px',
        fontSize: '1rem',
    },
    infoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 10px',
        margin: "5px 0px",
        backgroundColor: '#f5f5f5',
        transition: '0.2s all ease',
        "&:hover": {
            backgroundColor: '#ffe39c',
            cursor: 'pointer'
        },
        "&:active": {
            backgroundColor: '#e37d24',
            cursor: 'pointer'
        }
    },
    inputBoxWrapper: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    conversationWrapper: {
        marginTop: '-30px',
        paddingLeft: '80px',
        paddingRight: '20px',
        height: '550px',
        display: 'flex !important',
        flexDirection: 'column',
    },
    chatMessage: {
        // backgroundColor: '#f5f5f5',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
        padding: '10px 10px',
        maxWidth: '60%'
    },
    chatHeader: {
        fontWeight: 'bold',
        margin: '0px 0px',

    },
    chatMsg: {
        margin: '0px 0px',
        fontSize: '1.2rem',
    },
    infoRight: {
        fontSize: '1.2rem',
        fontWeight: 700
    },
    divider: {
        width: '100%',
        border: '0.4px solid #ebebeb'
    },
    peekMessage: {
        textOverflow: 'ellipsis',
        margin: "0px 0px"
    },
}))