import {
    Button,
    Card,
    FormControl,
    FormLabel,
    InputAdornment,
    MenuItem,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from "@material-ui/core";
import {
    Field,
    Form,
    Formik,
    FormikHelpers,
    FormikProps,
    useFormikContext,
} from "formik";
import { CheckboxWithLabel, Select, TextField } from "formik-material-ui";
import { useEffect, useState } from "react";
import {
    fieldLabels,
    emptyValidationSchema,
    initialValues,
    PersonalValidationSchema,
    AddressValidationSchema,
    HomeValidationSchema,
    FormField,
    ContactValidationSchema,
} from "./ProCleanSurveyFormFields";
import { handleSubmit } from "./formHandler";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { useHistory, useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { useStyles } from "./ProCleanSurvey.style";
import { ReactComponent as Clean } from "../../images/buttonIcon/clean.svg";
import { getAuth } from "../../util/auth";
import { Endpoint } from "../../util/endpoints";
import { IServiceDetail } from "../ServiceDetail/ServiceDetail";
import { getToken } from "../../util/cookie";
import { entity as identity, language, languages } from "../../util/proSurvey";
import DatePicker from "../../components/DatePicker/DatePicker";
interface IFormProps {
    formikProps: FormikProps<any>;
}

const surveyTypes: FormField[] = [
    FormField.homeSituation,
    FormField.personal,
    FormField.cleaningTime,
    FormField.cleaningFocus,
    FormField.currentAddress,
    // FormField.contactInfo,
];

export const countObjectKeys = (obj: any): number => {
    let count = 0;
    for (const key in obj) {
        ++count;
    }
    return count;
};

interface ISurvey {
    label: string;
    Form: (props: IFormProps) => JSX.Element;
    validationSchema: () => any;
}
const TimeForm = (props: IFormProps, startDate: Date, setStartDate: any) => {
    const styles = useStyles();
    // const [startDate, setStartDate] = useState(new Date());

    return (
        <div>
            <FormControl fullWidth variant="filled">
                <br />

                <Typography className={styles.stepperLabel}>
                    Please select the start date you are available to provide
                    the service
                </Typography>
                <br />
                <div className={styles.leftDateDiv}>
                    {/* <Typography
                        // className={styles.price}
                        variant="body1"
                        // align="center"
                    >
                        Start Date:
                    </Typography> */}
                    <DatePicker
                        value={startDate}
                        updateDate={
                            // ts-ignore
                            setStartDate
                        }
                        // setTimeList={setTimeList}
                        // orders={service.orders}
                    />
                    {/* <Typography
                        // className={styles.price}
                        variant="body1"
                        align="center"
                    >
                        End Date:
                    </Typography>

                    <DatePicker
                        value={endDate}
                        updateDate={setEndDate}
                        // setTimeList={setTimeList}
                        // orders={service.orders}
                    /> */}
                </div>
                <br />
                <Typography className={styles.stepperLabel}>
                    Please select the time range you are free
                </Typography>
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble9]}
                    name={FormField.timeRange9}
                    Label={{ label: fieldLabels[FormField.timeRange9] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble10]}
                    name={FormField.timeRange10}
                    Label={{ label: fieldLabels[FormField.timeRange10] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble11]}
                    name={FormField.timeRange11}
                    Label={{ label: fieldLabels[FormField.timeRange11] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble12]}
                    name={FormField.timeRange12}
                    Label={{ label: fieldLabels[FormField.timeRange12] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble13]}
                    name={FormField.timeRange13}
                    Label={{ label: fieldLabels[FormField.timeRange13] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble14]}
                    name={FormField.timeRange14}
                    Label={{ label: fieldLabels[FormField.timeRange14] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble15]}
                    name={FormField.timeRange15}
                    Label={{ label: fieldLabels[FormField.timeRange15] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble16]}
                    name={FormField.timeRange16}
                    Label={{ label: fieldLabels[FormField.timeRange16] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble17]}
                    name={FormField.timeRange17}
                    Label={{ label: fieldLabels[FormField.timeRange17] }}
                />
                <br />
            </FormControl>
        </div>
    );
};

const ServiceBasicForm = (props: IFormProps) => {
    const styles = useStyles();

    return (
        <div>
            <FormControl fullWidth variant="filled">
                <Field
                    component={TextField}
                    select
                    required
                    className={styles.questionTxt}
                    variant="standard"
                    label={fieldLabels[FormField.identity]}
                    name={FormField.identity}
                >
                    {Object.entries(identity).map(([value, { name }]) => (
                        <MenuItem key={value} value={value}>
                            {name}
                        </MenuItem>
                    ))}
                </Field>
                <br />
            </FormControl>
            <FormLabel>Please provide a title for your service</FormLabel>
            <br />
            <Field
                component={TextField}
                multiline
                required
                variant="standard"
                label={fieldLabels[FormField.serviceTitle]}
                name={FormField.serviceTitle}
                fullWidth
            />
            <br />
            <br />
            <FormLabel>How many years have you done this service?</FormLabel>
            <br />
            <Field
                className={styles.hipWidth}
                component={TextField}
                type="number"
                name={FormField.numberYearService}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            year<sup></sup>
                        </InputAdornment>
                    ),
                }}
            />
            <br />
            <br />
        </div>
    );
};

const PreferenceForm = () => {
    const styles = useStyles();
    return (
        <FormControl fullWidth variant="filled">
            <Typography variant="h6">
                Please describe your service in detail
            </Typography>
            <Field
                className={styles.DescriptionHeight}
                multiline
                rows={6}
                component={TextField}
                name={FormField.serviceDescription}
                variant="standard"
                required
                label={fieldLabels[FormField.serviceDescription]}
                fullWidth
            />
            <br />
        </FormControl>
    );
};

const ServiceDetailForm = (props: IFormProps) => {
    const styles = useStyles();

    return (
        <div className={styles.fieldIndent}>
            <div className={styles.fieldIndent}>
                <FormControl fullWidth variant="filled">
                    <br />
                    <Typography variant="h6">
                        What is your preferred service rate?
                    </Typography>
                    <FormLabel>
                        The average pricing rate of the service is $30 / hour
                    </FormLabel>
                    <br />
                    <Field
                        className={styles.hipWidth}
                        component={TextField}
                        type="number"
                        name={FormField.price}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    CAD / Hour<sup></sup>
                                </InputAdornment>
                            ),
                        }}
                    />{" "}
                    <br />
                </FormControl>
                {/* <br /> */}
            </div>
        </div>
    );
};

const PersonalForm = (props: IFormProps) => {
    const styles = useStyles();
    const {
        values: { identity },
    } = useFormikContext();

    return (
        <div className={styles.fieldIndent}>
            <div className={styles.fieldIndent}>
                <FormControl fullWidth variant="filled">
                    {identity === "COMPANY" ? (
                        <div>
                            <FormLabel>What is your company name?</FormLabel>
                            <br />
                            <Field
                                component={TextField}
                                multiline
                                className={styles.questionTxt}
                                required
                                variant="standard"
                                label={fieldLabels[FormField.companyName]}
                                name={FormField.companyName}
                                fullWidth
                            />
                            <br />
                        </div>
                    ) : (
                        <></>
                    )}
                    <br />
                    <FormLabel>What is your name?</FormLabel>
                    <Field
                        component={TextField}
                        multiline
                        className={styles.questionTxt}
                        required
                        variant="standard"
                        label={fieldLabels[FormField.firstName]}
                        name={FormField.firstName}
                        fullWidth
                    />
                    <Field
                        component={TextField}
                        multiline
                        className={styles.questionTxt}
                        required
                        variant="standard"
                        label={fieldLabels[FormField.lastName]}
                        name={FormField.lastName}
                        fullWidth
                    />
                    <br />
                    <FormLabel>What is your Phone Number?</FormLabel>
                    <Field
                        component={TextField}
                        className={styles.questionTxt}
                        name={FormField.phoneNumber}
                        variant="standard"
                        required
                        label={fieldLabels[FormField.phoneNumber]}
                        fullWidth
                    />
                    <br />

                    <FormLabel>What language can you speak?</FormLabel>
                    <br />
                    <FormControl fullWidth variant="filled">
                        {/* <Field
                            component={Select}
                            required
                            name={FormField.language}
                            className={styles.questionTxt}
                            variant="filled"
                            type="TextField"
                            multiple={true}
                            label={fieldLabels[FormField.language]}
                        >
                            {Object.entries(language).map(
                                ([value, { name }]) => (
                                    <MenuItem key={value} value={value}>
                                        {name}
                                    </MenuItem>
                                )
                            )}
                        </Field> */}
                        <Field
                            component={Select}
                            name={FormField.language}
                            variant="standard"
                            type="TextField"
                            fullwidth
                            label={fieldLabels[FormField.language]}
                            multiple
                        >
                            {/*{Object.entries(languages).map(([value]) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}*/}
                            {languages.map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Field>

                        <br />
                        <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name={FormField.isSmoker}
                            Label={{ label: fieldLabels[FormField.isSmoker] }}
                        />
                        <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name={FormField.haveCar}
                            Label={{ label: fieldLabels[FormField.haveCar] }}
                        />
                        <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name={FormField.petAvail}
                            Label={{ label: fieldLabels[FormField.petAvail] }}
                        />
                    </FormControl>
                </FormControl>
                <br />
            </div>
        </div>
    );
};

const ProCleanSurvey = () => {
    const styles = useStyles();
    const history = useHistory();
    const [step, setStep] = useState<number>(0);
    const [submissionError, setSubmissionError] = useState(false);
    const { serviceId } = useParams<{ serviceId: string }>();
    const queryParams = new URLSearchParams(window.location.search);
    const token: string = getToken();
    const pickedDate = queryParams.get("Date");
    const [timeList, setTimeList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());

    const { serviceType } = useParams<{ serviceType: string }>();

    console.log(serviceType);
    const surveySteps: ISurvey[] = [
        {
            label: "Service Basic Information",
            Form: (formikProps) => ServiceBasicForm(formikProps),
            validationSchema: HomeValidationSchema,
        },
        {
            label: "Personal Information",
            Form: (formikProps) => PersonalForm(formikProps),
            validationSchema: PersonalValidationSchema,
        },
        {
            label: "Set Service Time",
            Form: (formikProps) =>
                TimeForm(formikProps, startDate, setStartDate),
            validationSchema: emptyValidationSchema,
        },
        {
            label: "Service Price",
            Form: (formikProps) => ServiceDetailForm(formikProps),
            validationSchema: emptyValidationSchema,
        },
        {
            label: "Service Description",
            Form: () => PreferenceForm(),
            validationSchema: emptyValidationSchema,
        },
    ];

    const getServiceDetail = async () => {
        const theOrders = await getAuth(
            Endpoint.SERVICE_ORDER + serviceId,
            token
        );

        var tempSchema = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
        ];
        if (theOrders !== undefined && theOrders.length !== 0) {
            const theSchedule = theOrders.find(
                (order: { cleaningDate: any }) =>
                    JSON.stringify(order.cleaningDate).substring(1, 11) ===
                    pickedDate
            );
            console.log(theSchedule);
            if (
                theSchedule !== undefined &&
                theSchedule.timeRange !== undefined
            ) {
                for (var i = 0; i < theSchedule.timeRange.length; i++) {
                    if (theSchedule.timeRange[i] !== null) {
                        tempSchema[i] = true;
                    }
                }
            }
        }

        initialValues.time_range_able_9 = tempSchema[0];
        initialValues.time_range_able_10 = tempSchema[1];
        initialValues.time_range_able_11 = tempSchema[2];
        initialValues.time_range_able_12 = tempSchema[3];
        initialValues.time_range_able_13 = tempSchema[4];
        initialValues.time_range_able_14 = tempSchema[5];
        initialValues.time_range_able_15 = tempSchema[6];
        initialValues.time_range_able_16 = tempSchema[7];
        initialValues.time_range_able_17 = tempSchema[8];
        setTimeList(tempSchema);
    };

    useEffect(() => {
        getServiceDetail();
    }, []);

    const isFinalStep = step + 1 === surveyTypes.length && step !== 0;
    const prevStep = () => setStep(step - 1);
    const nextStep = async (values: any, helpers: FormikHelpers<any>) => {
        if (isFinalStep) {
            handleSubmit(values, helpers, setSubmissionError, startDate);
        } else {
            if (step === 0) {
                helpers.setFieldValue(
                    `${[FormField.serviceType]}`,
                    serviceType
                );
            }
            setStep(step + 1);
            helpers.setSubmitting(false);
            helpers.setTouched({});
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={surveySteps[step].validationSchema}
            onSubmit={nextStep}
            enableReinitialize
        >
            {(formikProps) => (
                <Form>
                    {submissionError && (
                        <Alert
                            onClose={() => setSubmissionError(false)}
                            severity="error"
                        >
                            Something went wrong submitting the survey. Please
                            try again.
                        </Alert>
                    )}

                    <div className={styles.container}>
                        <Button onClick={history.goBack}>
                            <Logo />
                        </Button>
                        <div className={styles.cardBoard}>
                            <Card className={styles.card}>
                                <div className={styles.headIcon}>
                                    <Clean />
                                </div>
                                <Stepper
                                    activeStep={step}
                                    orientation="vertical"
                                >
                                    {surveySteps.map((surveyStep, index) => (
                                        <Step key={index}>
                                            <StepLabel
                                                className={styles.stepperLabel}
                                            >
                                                {surveyStep.label}
                                            </StepLabel>
                                            <StepContent>
                                                <surveyStep.Form
                                                    formikProps={formikProps}
                                                />
                                                <br />
                                                {step !== 0 && (
                                                    <Button
                                                        style={{
                                                            marginRight: "5px",
                                                        }}
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={prevStep}
                                                    >
                                                        Prev Step
                                                    </Button>
                                                )}
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {isFinalStep &&
                                                    index === step
                                                        ? "Submit"
                                                        : "Next Step"}
                                                </Button>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Card>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ProCleanSurvey;
