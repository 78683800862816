import { Field, Form, Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import { fieldLabels, UserField, editValidationSchema } from "./fields";
import { TextField } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import { FormControl, MenuItem } from "@material-ui/core";
import { handleCancel, handleSubmit } from "./handler";
import { useStyles } from "./UserProfile.style";
import { IUser, userRoles, userGenders, userProvinces } from "../../util/users";
import { useEffect, useState } from "react";
import { Endpoint } from "../../util/endpoints";
import { getAuth } from "../../util/auth";
import { getToken, logout } from "../../util/cookie";
import { Alert, Skeleton } from "@material-ui/lab";
import ProfilePicCard from "../../components/ProfilePicCard/ProfilePicCard";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomCard from "../../components/Card/Card.js";
import CustomCardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import AppBarTopNoUser from "../../components/AppBarTop/AppBarTopNoUser";
import IconButton from "@material-ui/core/IconButton";
import { Settings, History, Message, Favorite } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const UserProfile = () => {
    const styles = useStyles();
    const [user, setUser] = useState<IUser>();
    const [loadingError, setLoadingError] = useState(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        const getUser = async () => {
            try {
                const token: string = getToken();
                const theUser: IUser = (await getAuth(
                    Endpoint.PROFILE,
                    token
                )) as IUser;
                theUser.avatar = theUser.avatar
                    ? "data:image/png;base64," +
                      Buffer.from(theUser.avatar).toString("base64")
                    : "/images/profile_pic_icon.png";
                setUser(theUser);
            } catch (e) {
                setLoadingError(true);
            }
        };
        getUser();
    }, []);

    return loadingError ? (
        <Alert severity="error">
            Something went wrong trying to load that user. Please go back and
            try again.
        </Alert>
    ) : user ? (
        <Formik
            initialValues={user}
            validationSchema={editValidationSchema}
            onSubmit={(values, setSubmitting) => {
                handleSubmit(values, setSubmitting, setIsEditing);
            }}
        >
            {({ values, isSubmitting, resetForm, setFieldValue }) => (
                <div>
                    <AppBarTopNoUser />
                    <br />
                    <br />
                    <GridContainer>
                        <Grid
                            className={styles.container}
                            container
                            direction="row"
                            justify="flex-start"
                            spacing={1}
                        >
                            <Grid item md={8} xs={12}>
                                <Grid justify="flex-end" container spacing={1}>
                                    <IconButton className={styles.iconBtn}>
                                        <Message />
                                    </IconButton>
                                    <IconButton
                                        className={styles.iconBtn}
                                        onClick={() =>
                                            history.push("/OrderList")
                                        }
                                    >
                                        <History />
                                    </IconButton>
                                    {values.user_role === "Pros" ? (
                                        <IconButton
                                            className={styles.iconBtn}
                                            onClick={() =>
                                                history.push("/EditServiceCards")
                                            }
                                        >
                                            <Settings />
                                        </IconButton>
                                    ) : (
                                        <IconButton className={styles.iconBtn}>
                                            <Favorite />
                                        </IconButton>
                                    )}
                                </Grid>
                                <Form>
                                    <CustomCard>
                                        <CustomCardHeader color="warning">
                                            <h4
                                                className={
                                                    styles.cardTitleWhite
                                                }
                                            >
                                                User Profile
                                            </h4>
                                        </CustomCardHeader>
                                        <CardBody>
                                            <div className={styles.central}>
                                                <Grid item md={5} xs={12}>
                                                    <div
                                                        className={
                                                            styles.central
                                                        }
                                                    >
                                                        <ProfilePicCard
                                                            isEditing={true}
                                                            setFieldValue={
                                                                setFieldValue
                                                            }
                                                            picture={
                                                                values.avatar
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                            </div>

                                            <GridContainer>
                                                <GridItem>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            UserField.username
                                                        }
                                                        className={
                                                            styles.disabledTextField
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                UserField
                                                                    .username
                                                            ]
                                                        }
                                                        required
                                                        fullWidth
                                                    />
                                                </GridItem>

                                                <GridItem>
                                                    <FormControl
                                                        fullWidth
                                                        variant="standard"
                                                    >
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            select
                                                            disabled={true}
                                                            required
                                                            variant="standard"
                                                            className={
                                                                styles.disabledTextField
                                                            }
                                                            label={
                                                                fieldLabels[
                                                                    UserField
                                                                        .user_role
                                                                ]
                                                            }
                                                            name={
                                                                UserField.user_role
                                                            }
                                                        >
                                                            {Object.entries(
                                                                userRoles
                                                            ).map(
                                                                ([
                                                                    value,
                                                                    { name },
                                                                ]) => (
                                                                    <MenuItem
                                                                        key={
                                                                            value
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Field>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem>
                                                    <FormControl
                                                        fullWidth
                                                        variant="standard"
                                                    >
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            select
                                                            disabled={
                                                                !isEditing
                                                            }
                                                            required
                                                            variant="standard"
                                                            className={
                                                                styles.disabledTextField
                                                            }
                                                            label={
                                                                fieldLabels[
                                                                    UserField
                                                                        .gender
                                                                ]
                                                            }
                                                            name={
                                                                UserField.gender
                                                            }
                                                        >
                                                            {Object.entries(
                                                                userGenders
                                                            ).map(
                                                                ([
                                                                    value,
                                                                    { name },
                                                                ]) => (
                                                                    <MenuItem
                                                                        key={
                                                                            value
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Field>
                                                    </FormControl>
                                                </GridItem>
                                            </GridContainer>
                                            <br />
                                            <GridContainer>
                                                <GridItem>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            UserField.first_name
                                                        }
                                                        className={
                                                            styles.disabledTextField
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                UserField
                                                                    .first_name
                                                            ]
                                                        }
                                                        required
                                                        fullWidth
                                                    />
                                                </GridItem>

                                                <GridItem>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            UserField.last_name
                                                        }
                                                        className={
                                                            styles.disabledTextField
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                UserField
                                                                    .last_name
                                                            ]
                                                        }
                                                        required
                                                        fullWidth
                                                    />
                                                </GridItem>

                                                {/* <ProfilePicCard
                                            isEditing={true}
                                            setFieldValue={setFieldValue}
                                            picture={values.picture}
                                        /> */}
                                            </GridContainer>
                                            <br />
                                            <GridContainer>
                                                <GridItem>
                                                    <Field
                                                        component={TextField}
                                                        disabled={true}
                                                        name={UserField.email}
                                                        className={
                                                            styles.disabledTextField
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                UserField.email
                                                            ]
                                                        }
                                                        fullWidth
                                                    />
                                                </GridItem>
                                                <GridItem>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            UserField.phoneNumber
                                                        }
                                                        className={
                                                            styles.disabledTextField
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                UserField
                                                                    .phoneNumber
                                                            ]
                                                        }
                                                        fullWidth
                                                    />
                                                </GridItem>
                                            </GridContainer>

                                            <br />
                                            <GridContainer>
                                                <GridItem>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={UserField.address}
                                                        className={
                                                            styles.disabledTextField
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                UserField
                                                                    .address
                                                            ]
                                                        }
                                                        fullWidth
                                                    />
                                                </GridItem>

                                                <GridItem>
                                                    <FormControl
                                                        fullWidth
                                                        variant="standard"
                                                    >
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            select
                                                            disabled={
                                                                !isEditing
                                                            }
                                                            required
                                                            variant="standard"
                                                            className={
                                                                styles.disabledTextField
                                                            }
                                                            label={
                                                                fieldLabels[
                                                                    UserField
                                                                        .province
                                                                ]
                                                            }
                                                            name={
                                                                UserField.province
                                                            }
                                                        >
                                                            {Object.entries(
                                                                userProvinces
                                                            ).map(
                                                                ([
                                                                    value,
                                                                    { name },
                                                                ]) => (
                                                                    <MenuItem
                                                                        key={
                                                                            value
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Field>
                                                    </FormControl>
                                                </GridItem>
                                                <br />
                                                <br />
                                                <br />
                                                {values.user_role === "Pros" ? (
                                                    <GridItem>
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            disabled={
                                                                !isEditing
                                                            }
                                                            name={
                                                                UserField.self_description
                                                            }
                                                            className={
                                                                styles.DescriptionHeight
                                                            }
                                                            variant="standard"
                                                            label={
                                                                fieldLabels[
                                                                    UserField
                                                                        .self_description
                                                                ]
                                                            }
                                                            multiline
                                                            rows={8}
                                                            fullWidth
                                                        />
                                                    </GridItem>
                                                ) : (
                                                    <br />
                                                )}
                                            </GridContainer>

                                            <br />
                                            <br />
                                            <Grid
                                                justify="flex-end"
                                                container
                                                spacing={1}
                                            >
                                                {isEditing ? (
                                                    <>
                                                        <Grid item>
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                type="submit"
                                                                className={
                                                                    styles.btn
                                                                }
                                                                disabled={
                                                                    isSubmitting
                                                                }
                                                            >
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                className={
                                                                    styles.btn
                                                                }
                                                                onClick={() => {
                                                                    handleCancel(
                                                                        setIsEditing,
                                                                        resetForm
                                                                    );
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <Grid item>
                                                        {" "}
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            className={
                                                                styles.btn
                                                            }
                                                            onClick={() => {
                                                                setIsEditing(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className={
                                                                styles.logoutBtn
                                                            }
                                                            onClick={logout}
                                                        >
                                                            LogOut
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <br />
                                        </CardBody>
                                    </CustomCard>
                                </Form>
                            </Grid>
                        </Grid>
                    </GridContainer>
                </div>
            )}
        </Formik>
    ) : (
        <Skeleton variant="rect" height={500} />
    );
};

export default UserProfile;
