import { ReactComponent as Landscaping } from "../../images/buttonIcon/landscaping.svg";
import { ReactComponent as Clean } from "../../images/buttonIcon/clean.svg";
import { ReactComponent as Repairing } from "../../images/buttonIcon/repair.svg";
import { ReactComponent as ChildrenCare } from "../../images/buttonIcon/childrenCare.svg";
import { ReactComponent as Cooking } from "../../images/buttonIcon/cooking.svg";
import { ReactComponent as Moving } from "../../images/buttonIcon/moving.svg";
import { Box, Button } from "@material-ui/core";
import { useStyles } from "./LandboardIconButtonsGroup.styles";
import { useHistory } from "react-router-dom";

export const svgButton: any[] = [
    {
        buttonId: "Cleaning",
        buttonIcon: Clean,
        buttonLabel: "Cleaning Services",
    },
    {
        buttonId: "Moving",
        buttonIcon: Moving,
        buttonLabel: "Moving Services",
    },
    {
        buttonId: "Tutoring",
        buttonIcon: Repairing,
        buttonLabel: "Tutoring Services",
    },
    {
        buttonId: "Cooking",
        buttonIcon: Cooking,
        buttonLabel: "Cooking Services",
    },
    {
        buttonId: "ChildCare",
        buttonIcon: ChildrenCare,
        buttonLabel: "ChildCare Services",
    },
    {
        buttonId: "Landscaping",
        buttonIcon: Landscaping,
        buttonLabel: "Landscaping Services",
    },
];

const LandboardIconButtonsGroup = () => {
    const styles = useStyles();
    const history = useHistory();
    return (
        <div className={styles.buttonGroup}>
            {svgButton.map((button) => (
                <Button
                    id={button.buttonLabel}
                    variant="outlined"
                    classes={{
                        root: styles.buttonInCard,
                        label: styles.label,
                    }}
                    onClick={() =>
                        history.push(`/SuggestionCards/${button.buttonId}`)
                    }
                >
                    <br />
                    <button.buttonIcon />
                    <Box fontFamily="Proxima Nova" fontSize="h6.fontSize">
                        {button.buttonLabel}
                    </Box>
                </Button>
            ))}
        </div>
    );
};

export default LandboardIconButtonsGroup;
