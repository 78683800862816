import * as Yup from "yup";
import { UserRole } from "../../util/users";
import { Validation } from "../../util/validations";

export interface IRouteParams {
    userId: string;
}

export enum UserField {
    id = "id",
    firstName = "firstName",
    lastName = "lastName",
    phoneNumber = "phoneNumber",
    serviceDescription = "serviceDescription",
    servicePrice = "servicePrice",
    isSmoker = "isSmoker",
    haveCar = "haveCar",
    petAvail = "petAvail",
}

export const fieldLabels = {
    [UserField.id]: "User Id",
    [UserField.firstName]: "First Name",
    [UserField.lastName]: "Last Name",
    [UserField.haveCar]: "haveCar",
    [UserField.petAvail]: "petAvail",
    [UserField.isSmoker]: "isSmoker",
    [UserField.phoneNumber]: "Phone Number",
    [UserField.servicePrice]: "Service Price",
    [UserField.serviceDescription]:
        "Describe which part are you good at of your service",
};

// export const initialValues = {
//     [UserField.id]: 0,
//     [UserField.username]: "",
//     [UserField.first_name]: "",
//     [UserField.last_name]: "",
//     [UserField.user_role]: UserRole.Client,
//     [UserField.address]: "",
//     [UserField.picture]: "",
//     [UserField.avatar]: "",
//     [UserField.email]: "",
//     [UserField.province]: "British Columbia",
//     [UserField.gender]: "Unknown",
//     [UserField.phoneNumber]: "",
//     [UserField.self_description]: "",
// };

// export type TNewUserValues = typeof initialValues;

const infoValidationShape = {
    [UserField.firstName]: Yup.string()
        .label(fieldLabels[UserField.firstName])
        .required()
        .max(50),
    [UserField.lastName]: Yup.string()
        .label(fieldLabels[UserField.lastName])
        .required()
        .max(50),
    [UserField.phoneNumber]: Yup.string()
        .matches(Validation.phoneRegExp, "Invalid phone number")
        .label(fieldLabels[UserField.phoneNumber])
        .max(50),
};

export const editValidationSchema = Yup.object().shape(infoValidationShape);
