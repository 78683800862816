import * as Yup from "yup";
import { Validation } from "../../util/validations";

export enum FormField {
    serviceId = "client_id",

    timeSchedule = "time_schedule",
    timeRange = "rate_level",

    homeSituation = "home_situation",
    numberBedRoom = "num_bedroom",
    numberWashRoom = "num_washroom",

    cleaningFocus = "cleaning_focus",
    focusBathCleaning = "focus_bath_cleaning",
    focusKitchenCleaning = "focus_kitchen_cleaning",
    focusRoomCleaning = "focus_room_cleaning",
    focusWindowWashing = "focus_window_washing",
    focusCarpetShampooing = "focus_carpet_shampooing",
    focusFurnitureTreatment = "focus_furniture_treatment",
    focusLaunary = "focus_lanuary",

    cleaningTime = "cleaning_time",
    timeRange9 = "time_range_9",
    timeRange10 = "time_range_10",
    timeRange11 = "time_range_11",
    timeRange12 = "time_range_12",
    timeRange13 = "time_range_13",
    timeRange14 = "time_range_14",
    timeRange15 = "time_range_15",
    timeRange16 = "time_range_16",
    timeRange17 = "time_range_17",

    timeRangeAble9 = "time_range_able_9",
    timeRangeAble10 = "time_range_able_10",
    timeRangeAble11 = "time_range_able_11",
    timeRangeAble12 = "time_range_able_12",
    timeRangeAble13 = "time_range_able_13",
    timeRangeAble14 = "time_range_able_14",
    timeRangeAble15 = "time_range_able_15",
    timeRangeAble16 = "time_range_able_16",
    timeRangeAble17 = "time_range_able_17",
    timeList = "time_list",

    keeperBring = "keeper_bring",
    currentAddress = "current_address",
    address = "address",
    zipcode = "zipcode",

    personal = "personal",
    firstName = "first_name",
    lastName = "last_name",

    contactInfo = "contactInfo",
    phoneNumber = "phone_number",
    area = "area",
    equipment = "equipment",
    supplies = "supplies",
}

export enum KeeperBring {
    SUPPLIES = "SUP",
    EQUIPMENT = "EQU",
}

export const keeperBring = {
    [KeeperBring.SUPPLIES]: "Supplies",
    [KeeperBring.EQUIPMENT]: "Equipment",
};

export const fieldLabels = {
    [FormField.serviceId]: "Client",
    [FormField.timeSchedule]: "Time Schedule",
    [FormField.timeRange]: "Time Range",

    [FormField.homeSituation]: "Basic Home situation",
    [FormField.numberWashRoom]: "Number of washrooms",
    [FormField.numberBedRoom]: "Number of bedrooms",
    [FormField.supplies]: "Supplies",
    [FormField.equipment]: "Equipment",

    [FormField.cleaningFocus]: "What part of cleaning would you focus on",
    [FormField.focusBathCleaning]: "Bathroom Cleaning",
    [FormField.focusKitchenCleaning]: "Kitchen Cleaning",
    [FormField.focusRoomCleaning]: "General Room Cleaning",
    [FormField.focusWindowWashing]: "Window Washing",
    [FormField.focusCarpetShampooing]: "Carpet Shampooing",
    [FormField.focusLaunary]: "Launary",
    [FormField.focusFurnitureTreatment]: "Furniture Treatment",

    [FormField.cleaningTime]: "When would you have the service?",
    [FormField.timeRange9]: "9:00-10:00",
    [FormField.timeRange10]: "10:00-11:00",
    [FormField.timeRange11]: "11:00-12:00",
    [FormField.timeRange12]: "12:00-13:00",
    [FormField.timeRange13]: "13:00-14:00",
    [FormField.timeRange14]: "14:00-15:00",
    [FormField.timeRange15]: "15:00-16:00",
    [FormField.timeRange16]: "16:00-17:00",
    [FormField.timeRange17]: "17:00-18:00",

    [FormField.timeList]: "timeList",

    [FormField.keeperBring]: "What should your housekeeper bring?",
    [FormField.currentAddress]: "What is your address",
    [FormField.address]: "Address",

    [FormField.personal]: "Personal Informnation",
    [FormField.zipcode]: "Zip Code",
    [FormField.firstName]: "First Name",
    [FormField.lastName]: "Last Name",
    [FormField.contactInfo]: "Contact Information",
    [FormField.phoneNumber]: "Phone Number",
    [FormField.area]: "Area",
};

export const initialValues = {
    [FormField.serviceId]: "",
    [FormField.numberBedRoom]: 1,
    [FormField.numberWashRoom]: 1,
    [FormField.area]: 200,
    [FormField.equipment]: "",

    [FormField.focusBathCleaning]: false,
    [FormField.focusKitchenCleaning]: false,
    [FormField.focusRoomCleaning]: false,
    [FormField.focusWindowWashing]: false,
    [FormField.focusCarpetShampooing]: false,
    [FormField.focusFurnitureTreatment]: false,
    [FormField.focusLaunary]: false,

    [FormField.timeRange9]: false,
    [FormField.timeRange10]: false,
    [FormField.timeRange11]: false,
    [FormField.timeRange12]: false,
    [FormField.timeRange13]: false,
    [FormField.timeRange14]: false,
    [FormField.timeRange15]: false,
    [FormField.timeRange16]: false,
    [FormField.timeRange17]: false,

    [FormField.timeRangeAble9]: false,
    [FormField.timeRangeAble10]: false,
    [FormField.timeRangeAble11]: false,
    [FormField.timeRangeAble12]: false,
    [FormField.timeRangeAble13]: false,
    [FormField.timeRangeAble14]: false,
    [FormField.timeRangeAble15]: false,
    [FormField.timeRangeAble16]: false,
    [FormField.timeRangeAble17]: false,

    [FormField.timeList]: [0, 0, 0, 0, 0, 0, 0, 0, 0],

    [FormField.address]: "",
    [FormField.zipcode]: "",

    [FormField.contactInfo]: "",
    [FormField.firstName]: "",
    [FormField.lastName]: "",
    [FormField.phoneNumber]: "",
};

export const emptyValidationSchema = () => Yup.object().shape({});

export const TimeValidationSchema = () =>
    Yup.object().shape({
        [FormField.timeRange]: Yup.string().label(
            fieldLabels[FormField.timeRange]
        ),
    });

export const TimeValidationSchemaNew = () =>
    Yup.object().shape({
        [FormField.timeRange]: Yup.array()
            .label(fieldLabels[FormField.timeRange])
            .required(),
    });

export const HomeValidationSchema = () =>
    Yup.object().shape({
        [FormField.numberBedRoom]: Yup.number()
            .label(fieldLabels[FormField.numberBedRoom])
            .max(10)
            .positive()
            .required(),
        [FormField.numberWashRoom]: Yup.number()
            .label(fieldLabels[FormField.numberWashRoom])
            .max(10)
            .positive()
            .required(),
        [FormField.area]: Yup.number()
            .label(fieldLabels[FormField.area])
            .positive()
            .required(),
    });

export const AddressValidationSchema = () =>
    Yup.object().shape({
        [FormField.address]: Yup.string()
            .label(fieldLabels[FormField.address])
            .max(50)
            .required(),

        [FormField.zipcode]: Yup.string()
            .label(fieldLabels[FormField.zipcode])
            .matches(Validation.zipcodeRegExp, "Zip code is not valid."),
    });

export const PersonalValidationSchema = () =>
    Yup.object().shape({
        [FormField.firstName]: Yup.string()
            .label(fieldLabels[FormField.firstName])
            .max(50)
            .required(),
        [FormField.lastName]: Yup.string()
            .label(fieldLabels[FormField.lastName])
            .max(50)
            .required(),
    });

export const ContactValidationSchema = () =>
    Yup.object().shape({
        [FormField.phoneNumber]: Yup.string()
            .label(fieldLabels[FormField.phoneNumber])
            .max(50)
            .matches(Validation.phoneRegExp, "Phone number is not valid."),
    });

export const cleaningFocusValidationSchema = () => Yup.object().shape({});

export type TFormValues = typeof initialValues;
