import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: "75%",
        margin: "0 auto",
        padding: "20px 20px",
    },
    heading: {
        fontSize: "2.5rem",
        color: "#2e2e2e",
        fontFamily: "Lato",
        textAlign: "center",
    },
    subHeading: {
        fontSize: "1.8rem",
        color: "#2e2e2e",
        margin: "0px 0px",
        fontFamily: "Lato",
        fontWeight: "bold"
    },
    order: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "10px 0px",
        padding: "20px 20px",
        background: "#f5f5f5",
    },
    orderWrapper: {
        backgroundColor: "#f0f0f0",
        width: "100%",
        padding: "20px 20px",
        margin: "10px auto",
        display: "flex",
        flexDirection: "column",
    },
    orderHeading: {
        fontSize: "1.6rem",
        margin: "5px 0px",
        color: "#1e2882",
        textDecoration: "underline"
    },
    orderText: {
        fontSize: "1.5rem",
        margin: "2px 0px",
        color: "#3b3b3b"
    },
    paymentButton: {
        width: "200px",
        marginTop: "10px",
        backgroundColor: "#5f87f5",
        "&:hover": {
            backgroundColor: "#4a68bd"
        }
    },
    buttonWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
    }
}))