import { useHistory } from "react-router-dom";
import { useStyles } from "./LandboardSlogan.styles";

const LandboardSlogan = () => {
    const styles = useStyles();
    const history = useHistory();

    return (
        <div className={styles.joinNow}>
            <h2 className={styles.joinNowTitle}>
                {"Are you a "}
                <span className={styles.highlighted}>Service Provider?</span>
            </h2>

            <div className={styles.joinNowButton}>
                <a
                    href=""
                    className={styles.btn}
                    onClick={() => {
                        history.push("/Pros/Landboard");
                    }}
                >
                    Find a Job
                </a>
            </div>
        </div>
    );
};

export default LandboardSlogan;
