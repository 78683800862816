import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    mainDiv: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        marginTop: "20px",
        marginBottom: "20px",
        borderRadius: "20px",
    },
    leftDiv: {
        width: "60%",
    },
    titleDiv: {
        border: "1px solid lightBlue",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    checkButton: {
        display: "flex",
        justifyContent: "center",
    },
    titleName: {
        alignSelf: "center",
        fontSize: "35px",
        fontWeight: "bold",
    },
    divider: {
        background: "black",
    },
    titleIntro: {
        margin: "15px",
    },
    imageContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        marginLeft: 10,
    },
    inlineTitle: {
        display: "inline",
        margin: 10,
        fontWeight: "bold",
    },
    imageList: {
        width: "auto",
        height: 450,
    },
    TimeLineDiv: {
        margin: "15px",
    },
    icon: {
        color: "rgba(255, 255, 255, 0.54)",
    },
    titleBottom: {
        width: "100%",
        display: "flex",
        marginBottom: "15px",
        alignItems: "center",
        justifyContent: "space-between",
    },
    price: {
        marginRight: "20px",
        justifyContent: "center",
        fontWeight: "bold",
    },
    contentDiv: {
        border: "1px solid lightBlue",
        marginTop: "20px",
    },
    rightDiv: {
        width: "35%",
        border: "1px solid lightBlue",
    },
    starAndRateDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    starShowDiv: {
        width: "129px",
        height: "30px",
    },
    rightInsideDiv: {
        margin: "15px",
    },
    contentInsideDiv: {
        margin: "15px",
    },
    imageSection: {
        width: "100%",
        height: "400px",
    },
}));
