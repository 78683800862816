import * as Yup from "yup";
import { Validation } from "../../util/validations";

export enum ServiceTypeList {
    HouseholdCleaning = "Cleaning",
    HouseMoving = "Moving",
    RepairServices = "Repairing",
    Cooking = "Cooking",
    Childcare = "Childcare",
    LandscapingServices = "Landscaping",
}

export const serviceTypeList = {
    [ServiceTypeList.HouseholdCleaning]: {
        name: "Cleaning Service",
    },
    [ServiceTypeList.HouseMoving]: {
        name: "Moving service",
    },
    [ServiceTypeList.RepairServices]: {
        name: "Repairing service",
    },
    [ServiceTypeList.Cooking]: {
        name: "Cooking service",
    },
    [ServiceTypeList.Childcare]: {
        name: "Childcare service",
    },
    [ServiceTypeList.LandscapingServices]: {
        name: "Landscaping service",
    },
};

export enum ServiceField {
    _id = "_id",
    firstName = "firstName",
    lastName = "lastName",
    phoneNumber = "phoneNumber",
    serviceDescription = "serviceDescription",
    isSmoker = "isSmoker",
    haveCar = "haveCar",
    petAvail = "petAvail",
    servicePrice = "servicePrice",
}

export const fieldLabels = {
    [ServiceField._id]: "Id",
    [ServiceField.firstName]: "First Name",
    [ServiceField.lastName]: "Last Name",
    [ServiceField.phoneNumber]: "Phone Number",
    [ServiceField.isSmoker]: "Is smoker",
    [ServiceField.haveCar]: "Have a car",
    [ServiceField.petAvail]: "Pet Avaliable",
    [ServiceField.servicePrice]: "Service Price",
    [ServiceField.serviceDescription]: "Service Description",
};

export const initialValues = {
    [ServiceField._id]: "",
    [ServiceField.serviceDescription]: "",
    [ServiceField.servicePrice]: 90,
    [ServiceField.isSmoker]: false,
    [ServiceField.haveCar]: false,
    [ServiceField.petAvail]: false,
    [ServiceField.firstName]: "fsdfasad",
    [ServiceField.lastName]: "",
    [ServiceField.phoneNumber]: "",
};

export const emptyValidationSchema = () => Yup.object().shape({});

export const PersonalValidationSchema = () =>
    Yup.object().shape({
        [ServiceField.firstName]: Yup.string()
            .label(fieldLabels[ServiceField.firstName])
            .max(50)
            .required(),
        [ServiceField.lastName]: Yup.string()
            .label(fieldLabels[ServiceField.lastName])
            .max(50)
            .required(),
    });

export const newValidationSchema = () =>
    Yup.object().shape({
        [ServiceField.phoneNumber]: Yup.string()
            .label(fieldLabels[ServiceField.phoneNumber])
            .max(50)
            .matches(Validation.phoneRegExp, "Phone number is not valid."),
    });

export const editValidationSchema = () => Yup.object().shape({});

// export type TFormValues = typeof initialValues;
export type TNewServiceValues = typeof initialValues;
