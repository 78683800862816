import React from "react";
import {
    makeStyles
} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    starDiv: {
      width: "129px",
      height: "30px",
    },
    starNoStyle: {
        zIndex: 1,
        width: "129px",
        height: "30px",
        position: "absolute",
    },
    starYesStyle: {
        zIndex: 2,
        width: "129px",
        height: "30px",
        position: "absolute",
    }
}));

const StarShow = (props: any) => {

    const styles = useStyles();
    const rate = ((props.rate/5)*129).toString() + "px";
    const clipValue = "rect(0px, " + rate + ", 30px, 0px)";
    const starStyle = makeStyles((theme) => ({starYesStyle: {
        zIndex: 2,
        width: "129px",
        height: "30px",
        position: "absolute",
        clip: clipValue,
    }}));

    // const rate = ((props.rate/5)*129).toString() + "px";
    // setStarStyle(makeStyles((theme) => ({starYesStyle: {
    //     zIndex: 2,
    //     width: "129px",
    //     height: "30px",
    //     position: "absolute",
    //     clip: "rect(0px, 20px, 30px, 0px)",
    // }})));
    // console.log(clipValue);

    return (
        <div className={styles.starDiv}>
            <img className={styles.starNoStyle} src={"/images/StarNo.png"} />
            <img className={starStyle().starYesStyle} src={"/images/StarYes.png"} />
        </div>
    );
};

export default StarShow;
