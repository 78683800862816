import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    {
        fieldIndent: {
            paddingLeft: "9px",
        },
        container: {
            backgroundColor: "#FF9800",
        },
        hipWidth: {
            maxWidth: "160px",
        },
        inches: {
            verticalAlign: "sub",
        },
        stepperLabel: {
            fontWeight: "bold",
        },
        cardBoard: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        card: {
            borderRadius: 12,
            minWidth: 1000,
            justifyContent: "center",
            marginTop: 50,
            marginBottom: 50,
            minHeight: 800,
        },
        buttonInCard: {
            margin: 25,
            borderRadius: 20,
            width: "200px",
            height: "200px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        label: {
            // Aligns the content of the button vertically.
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 50,
        },
        headIcon: {
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    { index: 1 }
);
