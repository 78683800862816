import { Field, Form, Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import {
    fieldLabels,
    UserField as ServiceField,
    editValidationSchema,
} from "./fields";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { TextField, CheckboxWithLabel } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import { FormControl, MenuItem } from "@material-ui/core";
import { handleCancel, handleSubmit } from "./handler";
import { useStyles } from "./ProServiceProfile.style";
import { IUser, userRoles, userGenders, userProvinces } from "../../util/users";
import { useEffect, useState } from "react";
import { Endpoint } from "../../util/endpoints";
import { getAuth } from "../../util/auth";
import { getToken, logout } from "../../util/cookie";
import { Alert, Skeleton } from "@material-ui/lab";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomCard from "../../components/Card/Card.js";
import CustomCardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import AppBarTopNoUser from "../../components/AppBarTop/AppBarTopNoUser";
import IconButton from "@material-ui/core/IconButton";
import { Settings, History, Message, Favorite } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import { IServiceDetail } from "../ServiceDetail/ServiceDetail";
export interface IEditService {
    _id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    serviceDescription: string;
    servicePrice: number;
    isSmoker: boolean;
    haveCar: boolean;
    petAvail: boolean;
}
const ProServiceProfile = () => {
    const styles = useStyles();
    const [user, setUser] = useState<IUser>();
    const [loadingError, setLoadingError] = useState(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const history = useHistory();
    const { serviceId } = useParams<{ serviceId: string }>();
    const token: string = getToken();
    const [service, setService] = useState<IEditService>();
    const [serviceTitle, setServiceTitle] = useState("");

    const getServiceDetail = async () => {
        const serviceObj: IServiceDetail = await getAuth(
            Endpoint.DETAIL_SERVICE + serviceId,
            token
        );
        const serviceInfo = {
            _id: serviceObj.service._id,
            firstName: serviceObj.service.firstName,
            lastName: serviceObj.service.lastName,
            phoneNumber: serviceObj.service.phoneNumber,
            serviceDescription: serviceObj.service.serviceDescription,
            servicePrice: serviceObj.service.servicePrice,
            isSmoker: serviceObj.service.isSmoker,
            haveCar: serviceObj.service.haveCar,
            petAvail: serviceObj.service.petAvail,
        } as IEditService;
        setServiceTitle(serviceObj.service.serviceTitle);
        // setServiceOwner(serviceObj.serviceOwner);
        setService(serviceInfo);
        console.log(serviceInfo);
    };

    useEffect(() => {
        const getUser = async () => {
            try {
                const token: string = getToken();
                const theUser: IUser = (await getAuth(
                    Endpoint.PROFILE,
                    token
                )) as IUser;
                theUser.avatar = theUser.avatar
                    ? "data:image/png;base64," +
                      Buffer.from(theUser.avatar).toString("base64")
                    : "/images/profile_pic_icon.png";
                setUser(theUser);
            } catch (e) {
                setLoadingError(true);
            }
        };
        getServiceDetail();
        getUser();
    }, []);

    return loadingError ? (
        <Alert severity="error">
            Something went wrong trying to load that user. Please go back and
            try again.
        </Alert>
    ) : service ? (
        <Formik
            initialValues={service}
            validationSchema={editValidationSchema}
            onSubmit={(values, setSubmitting) => {
                handleSubmit(values, setSubmitting, setIsEditing, service._id);
            }}
        >
            {({ values, isSubmitting, resetForm, setFieldValue }) => (
                <div>
                    <AppBarTopNoUser />
                    <Button
                        // className={styles.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={history.goBack}
                    >
                        Back
                    </Button>
                    <GridContainer>
                        <Grid
                            className={styles.container}
                            container
                            direction="row"
                            justify="flex-start"
                            spacing={1}
                        >
                            <Grid item md={8} xs={12}>
                                <Form>
                                    <CustomCard>
                                        <CustomCardHeader color="warning">
                                            <h4
                                                className={
                                                    styles.cardTitleWhite
                                                }
                                            >
                                                Set The Service({serviceTitle})
                                                Detail
                                            </h4>
                                        </CustomCardHeader>
                                        <CardBody>
                                            {/* <div className={styles.central}>
                                                <Grid item md={5} xs={12}>
                                                    <div
                                                        className={
                                                            styles.central
                                                        }
                                                    >
                                                        <ProfilePicCard
                                                            isEditing={true}
                                                            setFieldValue={
                                                                setFieldValue
                                                            }
                                                            picture={
                                                                values.avatar
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                            </div> */}

                                            <GridContainer>
                                                <GridItem>
                                                    {/* <Field
                                                        component={
                                                            CheckboxWithLabel
                                                        }
                                                        disabled={!isEditing}
                                                        name={
                                                            ServiceField.haveCar
                                                        }
                                                        className={
                                                            styles.disabledTextField
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                ServiceField
                                                                    .haveCar
                                                            ]
                                                        }
                                                        required
                                                        fullWidth
                                                    /> */}
                                                </GridItem>

                                                <GridItem>
                                                    <FormControl
                                                        fullWidth
                                                        variant="standard"
                                                    >
                                                        {/* <Field
                                                            component={
                                                                TextField
                                                            }
                                                            select
                                                            disabled={true}
                                                            required
                                                            variant="standard"
                                                            className={
                                                                styles.disabledTextField
                                                            }
                                                            label={
                                                                fieldLabels[
                                                                    UserField
                                                                        .user_role
                                                                ]
                                                            }
                                                            name={
                                                                UserField.user_role
                                                            }
                                                        >
                                                            {Object.entries(
                                                                userRoles
                                                            ).map(
                                                                ([
                                                                    value,
                                                                    { name },
                                                                ]) => (
                                                                    <MenuItem
                                                                        key={
                                                                            value
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Field> */}
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem>
                                                    <FormControl
                                                        fullWidth
                                                        variant="standard"
                                                    >
                                                        {/* <Field
                                                            component={
                                                                TextField
                                                            }
                                                            select
                                                            disabled={
                                                                !isEditing
                                                            }
                                                            required
                                                            variant="standard"
                                                            className={
                                                                styles.disabledTextField
                                                            }
                                                            label={
                                                                fieldLabels[
                                                                    UserField
                                                                        .gender
                                                                ]
                                                            }
                                                            name={
                                                                UserField.gender
                                                            }
                                                        >
                                                            {Object.entries(
                                                                userGenders
                                                            ).map(
                                                                ([
                                                                    value,
                                                                    { name },
                                                                ]) => (
                                                                    <MenuItem
                                                                        key={
                                                                            value
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Field> */}
                                                    </FormControl>
                                                </GridItem>
                                            </GridContainer>
                                            <br />
                                            <GridContainer>
                                                <GridItem>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            ServiceField.firstName
                                                        }
                                                        className={
                                                            styles.disabledTextField
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                ServiceField
                                                                    .firstName
                                                            ]
                                                        }
                                                        required
                                                        fullWidth
                                                    />
                                                </GridItem>

                                                <GridItem>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            ServiceField.lastName
                                                        }
                                                        className={
                                                            styles.disabledTextField
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                ServiceField
                                                                    .lastName
                                                            ]
                                                        }
                                                        required
                                                        fullWidth
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            <br />
                                            <GridContainer>
                                                <GridItem>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            ServiceField.phoneNumber
                                                        }
                                                        className={
                                                            styles.disabledTextField
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                ServiceField
                                                                    .phoneNumber
                                                            ]
                                                        }
                                                        fullWidth
                                                    />
                                                </GridItem>
                                                <GridItem></GridItem>
                                            </GridContainer>

                                            <br />
                                            <GridContainer>
                                                <GridItem>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            ServiceField.servicePrice
                                                        }
                                                        className={
                                                            styles.disabledTextField
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                ServiceField
                                                                    .servicePrice
                                                            ]
                                                        }
                                                        fullWidth
                                                    />
                                                </GridItem>
                                                <GridItem>
                                                    <FormControl
                                                        fullWidth
                                                        variant="standard"
                                                    >
                                                        {/* <Field
                                                            component={
                                                                TextField
                                                            }
                                                            select
                                                            disabled={
                                                                !isEditing
                                                            }
                                                            required
                                                            variant="standard"
                                                            className={
                                                                styles.disabledTextField
                                                            }
                                                            label={
                                                                fieldLabels[
                                                                    UserField
                                                                        .province
                                                                ]
                                                            }
                                                            name={
                                                                UserField.province
                                                            }
                                                        >
                                                            {Object.entries(
                                                                userProvinces
                                                            ).map(
                                                                ([
                                                                    value,
                                                                    { name },
                                                                ]) => (
                                                                    <MenuItem
                                                                        key={
                                                                            value
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Field> */}
                                                    </FormControl>
                                                </GridItem>
                                                <br />
                                                <br />
                                                <br />
                                                <GridItem>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            ServiceField.serviceDescription
                                                        }
                                                        className={
                                                            styles.DescriptionHeight
                                                        }
                                                        variant="standard"
                                                        label={
                                                            fieldLabels[
                                                                ServiceField
                                                                    .serviceDescription
                                                            ]
                                                        }
                                                        multiline
                                                        rows={8}
                                                        fullWidth
                                                    />
                                                </GridItem>
                                            </GridContainer>

                                            <br />
                                            <br />
                                            <Grid
                                                justify="flex-end"
                                                container
                                                spacing={1}
                                            >
                                                {isEditing ? (
                                                    <>
                                                        <Grid item>
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                type="submit"
                                                                className={
                                                                    styles.btn
                                                                }
                                                                disabled={
                                                                    isSubmitting
                                                                }
                                                            >
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                className={
                                                                    styles.btn
                                                                }
                                                                onClick={() => {
                                                                    handleCancel(
                                                                        setIsEditing,
                                                                        resetForm
                                                                    );
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <Grid item>
                                                        {" "}
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            className={
                                                                styles.btn
                                                            }
                                                            onClick={() => {
                                                                setIsEditing(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className={
                                                                styles.logoutBtn
                                                            }
                                                            onClick={logout}
                                                        >
                                                            LogOut
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <br />
                                        </CardBody>
                                    </CustomCard>
                                </Form>
                            </Grid>
                        </Grid>
                    </GridContainer>
                </div>
            )}
        </Formik>
    ) : (
        <Skeleton variant="rect" height={500} />
    );
};

export default ProServiceProfile;
