import React from "react";
import {
    makeStyles,
    ThemeProvider,
    createMuiTheme,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Container, Grid } from "@material-ui/core";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import { orange } from "@material-ui/core/colors";

const theme = createMuiTheme({
    palette: {
        primary: orange,

        secondary: {
            main: "#ff8a65",
        },
    },
});

export const useStyles = makeStyles((theme) => ({
    addIcCallIcon: {
        marginLeft: 15,
    },
    banner: {
        marginBottom: "20px",
        marginTop: "40px",
        margin: "auto",
        justifyContent: "space-around",

        fontFamily: "Microsoft yahei",
        // border: "2px red solid",
        // backgroundColor: "#f8f6f4",
        flexGrow: 1,
        maxWidth: 1000,
    },
    block: {
        // marginTop: "20px",
        marginBottom: "20px",
        justifyContent: "center",
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    role1: {
        backgroundColor: "white",
        paddingTop: "20px",
        color: "Green",
        fontSize: "18px",
    },
    role2: {
        backgroundColor: "white",
        paddingTop: "20px",
        color: "#e4007f",
        fontSize: "18px",
    },
    stepNotes: {
        fontSize: "14px",
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(4),
    },
}));

function getStepsForC() {
    return [
        "Register",
        "Post your needs",
        "Match the service provider",
        "Contact and confirm",
        "Payment",
        "Got the service",
        ,
        "Write a review",
    ];
}

function getStepContentForC(step: number) {
    switch (step) {
        case 0:
            return "";
        // `For each ad campaign that you create, you can control how much
        //     you're willing to spend on clicks and conversions, which networks
        //     and geographical locations you want your ads to show on, and more.`;
        case 1:
            return "Fill in your needs on the website or contact us through online consultation and telephone.";
        case 2:
            return `Match the right aunt according to your needs / Choose the right aunt directly from the website.`;
        case 3:
            return "You contact with the provider and confirm the details of contracts.";
        case 4:
            return "Payment of service charges";
        case 5:
            return "";
        case 6:
            return "";
        // default:
        //     return 'Unknown step';
    }
}

function getStepsForS() {
    return [
        "Register",
        "Post the service you can provide",
        "Match the customer",
        "Contact and confirm",
        "Serve your customer",
        ,
        "Receive the payment",
        "Write a review",
    ];
}

function getStepContentForS(step: number) {
    switch (step) {
        case 0:
            return "";
        // `For each ad campaign that you create, you can control how much
        //     you're willing to spend on clicks and conversions, which networks
        //     and geographical locations you want your ads to show on, and more.`;
        case 1:
            return "Fill in your needs on the website or contact us through online consultation and telephone.";
        case 2:
            return `Match the right aunt according to your needs / Choose the right aunt directly from the website.`;
        case 3:
            return "You contact with the provider and confirm the details of contracts.";
        case 4:
            return "Payment of service charges";
        case 5:
            return "";
        case 6:
            return "";
        // default:
        //     return 'Unknown step';
    }
}
const ServiceFlow = () => {
    const classes = useStyles();
    // with this, the button color is blue not orange
    // const styles = useStyles(theme);

    // for customer
    const stepsForC = getStepsForC();
    const [activeStepForC, setActiveStepForC] = React.useState(0);
    const handleNextForC = () => {
        setActiveStepForC((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBackForC = () => {
        setActiveStepForC((prevActiveStep) => prevActiveStep - 1);
    };

    // for server
    const stepsForS = getStepsForS();
    const [activeStepForS, setActiveStepForS] = React.useState(0);
    const handleNextForS = () => {
        setActiveStepForS((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBackForS = () => {
        setActiveStepForS((prevActiveStep) => prevActiveStep - 1);
    };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    return (
        <Container>
            <ThemeProvider theme={theme}>
                <Grid container className={classes.banner} spacing={2}>
                    <Grid item className={classes.block} xs={6}>
                        <div className={classes.role1}>Looking for service</div>
                        <Stepper
                            activeStep={activeStepForC}
                            orientation="vertical"
                        >
                            {stepsForC.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>

                                    <StepContent>
                                        <Typography
                                            className={classes.stepNotes}
                                        >
                                            {getStepContentForC(index)}
                                        </Typography>
                                        <div
                                            className={classes.actionsContainer}
                                        >
                                            <div>
                                                <Button
                                                    disabled={
                                                        activeStepForC === 0
                                                    }
                                                    onClick={handleBackForC}
                                                    className={classes.button}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNextForC}
                                                    className={classes.button}
                                                >
                                                    {activeStepForC ===
                                                    stepsForC.length - 1
                                                        ? "Finish"
                                                        : "Next"}
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>

                    {/* xs num == num of steps */}
                    <Grid item className={classes.block} xs={6}>
                        <div className={classes.role2}>Joining</div>
                        <Stepper
                            activeStep={activeStepForS}
                            orientation="vertical"
                        >
                            {stepsForS.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                    <StepContent>
                                        <Typography
                                            className={classes.stepNotes}
                                        >
                                            {getStepContentForS(index)}
                                        </Typography>
                                        <div
                                            className={classes.actionsContainer}
                                        >
                                            <div>
                                                <Button
                                                    disabled={
                                                        activeStepForS === 0
                                                    }
                                                    onClick={handleBackForS}
                                                    className={classes.button}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNextForS}
                                                    className={classes.button}
                                                >
                                                    {activeStepForS ===
                                                    stepsForS.length - 1
                                                        ? "Finish"
                                                        : "Next"}
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </Container>
    );
};

export default ServiceFlow;
