import { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@material-ui/core";
import { useStyles } from "./ReviewDialog.style";
import { Alert, Rating } from "@material-ui/lab";
import { addFeedback, addServiceRate } from "./handler";
import { getToken } from "../../util/cookie";
import { Endpoint } from "../../util/endpoints";

interface IProps {
    serviceId: String;
    refreshList: () => void;
}

const ReviewDialog = (props: IProps) => {
    const [open, setOpen] = useState(true);

    const [loadingError, setLoadingError] = useState(false);
    const styles = useStyles();

    const [onTimeRating, setOnTimeRating] = useState(5);
    const [attitudeRating, setAttitudeRating] = useState(5);
    const [qualityRating, setQualityRating] = useState(5);
    const [serviceContent, setServiceContent] = useState("");

    const [feedbackRate, setFeedbackRate] = useState(5);
    const [feedbackContent, setFeedbackContent] = useState("");

    const onClose = () => {
        setOpen(false);
        setLoadingError(false);
    };

    const postAllRateAndFeedback = async () => {
        try {
            const newServiceRating = JSON.stringify({
                onTimeRate: onTimeRating,
                attitudeRate: attitudeRating,
                qualityRate: qualityRating,
                serviceContent: serviceContent,
            });

            const newFeedback = JSON.stringify({
                feedbackRate: feedbackRate,
                feedbackContent: feedbackContent,
            });

            const token: string = getToken();
            const apiStringServiceRate =
                Endpoint.SERVICE_RATEING + props.serviceId;
            const returnObj = await addServiceRate(
                newServiceRating,
                token,
                apiStringServiceRate
            );
            const serviceRating = returnObj.serviceRating;
            const userId = returnObj.userId;

            const apiStringFeedback = Endpoint.FEEDBACK + userId;
            const userFeedback = await addFeedback(
                newFeedback,
                token,
                apiStringFeedback
            ).then(() => props.refreshList());
        } catch (e) {
            setLoadingError(true);
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitle>
                <b>Review this service</b>
            </DialogTitle>
            <DialogContent>
                {loadingError ? (
                    <Alert severity="error">
                        Something went wrong. Please try again.
                    </Alert>
                ) : (
                    <>
                        <>
                            <b> On time: </b>
                            <br />
                            <Rating
                                name={"onTime"}
                                value={onTimeRating}
                                onChange={(event: any, onTimeValue: any) => {
                                    setOnTimeRating(onTimeValue);
                                }}
                            />
                            <br />
                        </>
                        <>
                            <b> Service Attitude:</b>
                            <br />
                            <Rating
                                name={"attitude"}
                                value={attitudeRating}
                                onChange={(event: any, attitudeValue: any) => {
                                    setAttitudeRating(attitudeValue);
                                }}
                            />
                            <br />
                        </>
                        <>
                            <b> Service Quality: </b>
                            <br />
                            <Rating
                                name={"quality"}
                                value={qualityRating}
                                onChange={(event: any, qualityValue: any) => {
                                    setQualityRating(qualityValue);
                                }}
                            />
                            <br />
                        </>

                        <b> Comment on service: </b>
                        <div className={styles.editorDivStyle}>
                            <textarea
                                className={styles.editorDivStyle}
                                value={serviceContent}
                                onChange={(e) =>
                                    setServiceContent(e.target.value)
                                }
                            />
                            <small className={"form-text text-muted"}>
                                Your feedback is important to us
                            </small>
                        </div>
                        <br />
                        <br />

                        {/* <Field
                            component={TextField}
                            label={"comment"}
                            name={reviewField.serviceContent}
                            variant="outlined"
                            helperText="Your feedback is important to us"
                            defaultValue=""
                            className={styles.textField}
                            fullWidth
                            required
                            multiline
                        /> */}
                        {/* <TextField
                                  
                                    variant="outlined"
                                    margin="normal"
                                    size="medium"
                                    fullWidth
                                    
                                /> */}
                    </>
                )}
            </DialogContent>
            <DialogTitle>
                <b>Review this Service Provider</b>
            </DialogTitle>
            <DialogContent>
                {loadingError ? (
                    <Alert severity="error">
                        Something went wrong. Please try again.
                    </Alert>
                ) : (
                    <>
                        <b> Rate: </b>
                        <br />
                        <Rating
                            name={"Rate"}
                            value={feedbackRate}
                            onChange={(event: any, newValue: any) => {
                                setFeedbackRate(newValue);
                            }}
                        />
                        <br />

                        <b> Comment on Pros: </b>
                        <div className={styles.editorDivStyle}>
                            <textarea
                                className={styles.editorDivStyle}
                                value={feedbackContent}
                                onChange={(e) =>
                                    setFeedbackContent(e.target.value)
                                }
                            />
                            <small className={"form-text text-muted"}>
                                Say something to the service provider
                            </small>
                        </div>
                        <br />
                        <br />
                        {/* <Form>
                            <TextField
                                defaultValue=""
                                className={styles.textField}
                                helperText="Say something to the service provider"
                                variant="outlined"
                                margin="normal"
                                size="medium"
                                fullWidth
                                multiline
                            />
                        </Form> */}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        postAllRateAndFeedback();
                        onClose();
                    }}
                    color="primary"
                    variant="outlined"
                >
                    Submit the review
                </Button>
                <Button onClick={() => setOpen(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReviewDialog;
