import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        fontFamily: "Lato",
        paddingBottom: "20px"
    },
    heading: {
        fontSize: "2.5rem",
        textAlign: "center",
    },
    centerParagraph: {
        textAlign: "center",
        fontSize: "1.5rem",
    },
    cardContainer: {
        display: "grid",
        margin: "0 auto",
        width: "80%",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "1rem",
        marginTop: "2rem",
    },
    card: {
        minHeight: "350px",
        width: "350px",
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 10px',
        borderRadius: "1rem",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        transition: "0.2s all ease",
        position: 'relative',
        "&:hover": {
            backgroundColor: "rgba(50, 50, 93, 0.1)",
        }
    },
    cardSection: {
        borderBottom: '1px solid #e0e0e0',
        width: '100%',
        padding: '5px 5px',
        position: 'relative'
    },
    serviceTitle: {
        fontSize: "2rem",
        margin: "0px 0px",
        textAlign: "center",
    },
    subHeading: {
        fontSize: "1.5rem",
        margin: "5px 0px",
        fontWeight: "normal",
        textDecoration: "underline",
        color: "#00bcd4",
    },
    name: {
        fontSize: "1.2rem",
        margin: "7px 0px"
    },
    button: {
        display: 'block',
    },
    filter: {
        width: "200px",
        margin: "0 auto",
        display: 'block',
        height: '40px',
        fontSize: '1.1rem'
    },
    star: {
        color: "#ed9f21",
        fontSize: "1.3rem",
        marginTop: "5px"
    },
    starWrapper: {
        display: "flex",
        alignItems: "center",
    },
    starContainer: {
        display: "flex",
        marginLeft: "10px",
    },
}))