import cookie from "react-cookies";

export const getToken = () => {
    return cookie.load("userInfo");
};

export const saveToken = (user: any) => {
    cookie.save("userInfo", user, { path: "/" });
};

export const logout = () => {
    cookie.remove("userInfo");
    sessionStorage.clear();
    window.location.replace("/");
};

