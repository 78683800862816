import { createContext, useState } from "react";

export const UserContext = createContext(null);

export const UserProvider = (props: any) => {
    const [userId, setUserId] = useState(null);
    const [first_name, setFirstName] = useState(null);
    const [accountType, setAccountType] = useState(null);

    const updateUser = (userId: string, first_name: string, accountType: string) => {
        setUserId(userId);
        setFirstName(first_name);
        setAccountType(accountType);
    }
    return (
        <UserContext.Provider value={{userId, first_name, accountType, updateUser}}>
            {props.children}
        </UserContext.Provider>
    )
}

