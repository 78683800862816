import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    menu: {
        width: 240,
        marginLeft: 140,
        flexDirection: "row",
    },
    container: {
        textAlign: "center",
    },
    house: {
        width: "100%",
        // height: "100vh",
        margin: "auto",
    },
    header: {
        backgroundColor: "white",
        /* min-height: 100vh; */
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "calc(10px + 2vmin)",
        color: "black",
    },
    listFormDivStyle: {
        width: "65%",
        display: "center",
        // position: 'fixed',
        // top:"100px",
        // left:"500px",
        // position:'absolute',
        // position:'relative',
    },
    center: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    listFilterDivStyle: {
        width: "35%",
        marginTop: "50px",
    },
    listDivStyle: {
        display: "flex !important",
        // flexDirection: "row",

        width: "100%",
        marginLeft: theme.spacing(-5),
    },
    // listDivStyle: {
    //     display: "flex",
    //     height: "100%",
    //     flexDirection: "row",
    //     width: "100%",
    // },
    cardGrid: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(8),
        // paddingLeft: theme.spacing(20),
        // position:"absolute",
        // marginLeft:theme.spacing(50),
        // position:"relative"
    },
    stickyMenu: {
        topSpacing: 0,
        top: "0",
        position: "sticky",
        paddingTop: theme.spacing(4),
        marginRight: theme.spacing(20),
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardMedia: {
        paddingTop: "56.25%", // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    test: {
        flexGrow: 1,
    },
    banner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    boldFont: {
        fontWeight: "bold",
    },
}));
