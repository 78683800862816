import * as Yup from "yup";
import { Validation } from "../../util/validations";

export interface IRouteParams {
    userId: string;
}

//unnecessary computed property keys with []
export const errorMessage: { [key: number]: string } = {
    201: "You have not verified your email",
    202: "Your password is incorret",
    [-1]: "The user's email does not exist",
};

export enum LoginField {
    password = "password",
    email = "email",
}

export const fieldLabels = {
    [LoginField.password]: "Enter Password",
    [LoginField.email]: "Email",
};

export const initialValues = {
    [LoginField.password]: "",
    [LoginField.email]: "",
};

export type TNewUserValues = typeof initialValues;

const infoValidationShape = {
    [LoginField.email]: Yup.string()
        .matches(Validation.emailRegExp, "Email is not valid")
        .label(fieldLabels[LoginField.email])
        .max(50)
        .required(),
    [LoginField.password]: Yup.string()
        .label(fieldLabels[LoginField.password])
        .matches(Validation.passwordRegExp, Validation.passwordInvalidMsg)
        .required(),
};

export const newValidationSchema = Yup.object().shape({
    ...infoValidationShape,
});
export const editValidationSchema = Yup.object().shape(infoValidationShape);
