import { makeStyles, Theme } from "@material-ui/core";
import { Block, Repeat } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { findByLabelText } from "@testing-library/dom";

export const useStyles = makeStyles((theme: Theme) => ({
    popularLinks: {
        margin: "5rem 0",
        display: "block",
        marginLeft: "25rem",
        marginRight: "25rem",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "1rem",
            marginRight: "1rem",
        }
    },
    popularLinksCollapsible: {
        display: "block"
    },
    popularLinksTitle: {
        textAlign: "center",
        listStyle: "none",
        outline: "none",
        marginBottom: "2.6rem",
        display: "list-item",
        counterIncrement: "list-item 0"
    },
    popularLinksPointer: {
        display: "inline-block",
        cursor: "pointer",
        position: "relative",
    },
    popularLinksH2: {
        fontSize: "1.3125rem",
        lineHeight: 1.238,
        margin: 0,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
        }
    },
    popularLinksArrow: {
        transform: "rotate(180reg)",
        position: "absolute",
        right: "-2rem",
        top: "0.125rem"
    },
    popularLinksSelectArrow: {
        backgroundImage: 'url("https://useprd-cdn-w.care.com/assets/img/welcomePage/select-arrow-9562c5311611d4b87f084027d6008bfd.svg")',
        display: "block",
        height: "1.5rem",
        width: "1.5rem",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "black"
    },
    popularLinksWrapper: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "1rem"
    },
    popularLinksRef: {
        color: "#505d6d",
        fontSize: "1rem",
        lineHeight: 2,
        textDecoration: "none",
        textAlign: "match-parent"
    },
    popularLinksH3: {
        fontSize: "1.125rem",
        lineHeight: 1.333,
        margin: 0,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.8rem",
        }
    },
    ul: {
        margin: "1rem 0 0",
        padding: 0,
        pointerEvents: "all",
        display: "block",
        listStyleType: "disc",
    },
    li: {
        listStyle: "none",
        display: "list-item",
        textAlign: "left",
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.4rem',
        }
    },
}));