import AppBarTop from "../../components/AppBarTop/AppBarTop";
import { useEffect, useState } from "react";
import { GetOrderDetails } from "./handler";
import { useParams } from "react-router-dom";
import { useStyles } from "./PrePaymentConfirmation.style";
import { Button, CircularProgress } from "@material-ui/core";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

export const PrePaymentConfirmation = () => {

    const { orderId } = useParams<{ orderId: string }>();
    const classes = useStyles();
    const [order, setOrder] = useState<any>();
    const [loading, setLoading] = useState(true);
    const initialOptions = {
        "client-id": "AeakRx5k-eymtfSPdd-gZYeUCmJm455yRHEEjmTQ5t6OszBYuHQlUs8Csimm8klyiS_dIdnA7R18H8pl",
        currency: "CAD",
        intent: "capture",
        // "data-client-token": "abc123xyz==", // TODO: Change this later
    }
    const [priceBeforeTax, setPriceBeforeTax] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);

    useEffect(() => {
        GetOrderDetails(sessionStorage.getItem("userId"), orderId)
            .then(res => {
                console.log(res.data);
                setOrder(res.data);
                setLoading(false);
                let price = res.data.servicePrice * res.data.preferredTime.length;
                console.log(price);
                setPriceBeforeTax(price);
                let finalPrice = price + 0.12 * price;
                setFinalPrice(finalPrice)
            })
    }, [])

    const handleApprove = async (data: any, actions: any) => {
        const order = await actions.order.capture();
        console.log(order);
    }
    const handleError = (err: any) => {
        console.log(err);
    }
    const handleCancel = (data: any, actions: any) => {
        console.log(data);
        console.log(actions);
    }

    return (
        <>
            <AppBarTop />
            <div className={classes.container}>
                {loading ? <CircularProgress /> : <>
                    <h1 className={classes.heading}>Pre Payment Confirmation</h1><br />
                    <hr className={classes.divider} />
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <h2 className={classes.subHeading}>Your Details</h2>
                            <p className={classes.p}>Name: {order.orderedBy}</p>
                            <p className={classes.p}>Phone number: {order.clientPhone}</p>
                            <p className={classes.p}>Email: {order.clientEmail}</p>
                            <p className={classes.p}>Address: {order.clientAddress}</p>
                        </div>
                        <div className={classes.column}>
                            <h2 className={classes.subHeading}>Provider Details</h2>
                            <p className={classes.p}>Name: {order.serviceProvider}</p>
                            <p className={classes.p}>Phone number: {order.providerPhone}</p>
                        </div>
                    </div>
                    <hr className={classes.divider} />
                    <h2 className={classes.subHeading}>Order Summary</h2>
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}>Service Title: </p>
                            <p className={classes.p}>Service ID: </p>
                            <p className={classes.p}>Service Type: </p>
                            <p className={classes.p}>Selected Time(s): </p>
                            <p className={classes.p}>Service Date: </p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}>{order.serviceTitle}</p>
                            <p className={classes.p}>{order.serviceId}</p>
                            <p className={classes.p}>{order.serviceType.charAt(0).toUpperCase() + order.serviceType.slice(1)}</p>
                            <p className={classes.p}>{order.selectedDate}</p>
                            <p className={classes.p}>{order.preferredTime.map((time: any) => {
                                return <>{time} </>
                            })} </p>
                        </div>

                    </div>

                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}><b>Service Fees (Hourly Rate)</b></p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}><b>${order.servicePrice.toFixed(2)}</b></p>
                        </div>
                    </div>
                    <hr className={classes.smallDivider} />
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}><b>Number of slots booked</b></p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}><b>{order.preferredTime.length}</b></p>
                        </div>
                    </div>
                    <hr className={classes.smallDivider} />
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}><b>Final Price</b></p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}><b>{priceBeforeTax.toFixed(2)}</b></p>
                        </div>
                    </div>
                    <hr className={classes.smallDivider} />
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}><b>Tax Rate *</b></p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}><b>${(0.12 * priceBeforeTax).toFixed(2)}</b></p>
                        </div>
                    </div>
                    <hr className={classes.smallDivider} />
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <p className={classes.p}><b style={{ color: "#008a43" }}>Total</b></p>
                        </div>
                        <div className={classes.column}>
                            <p className={classes.p}><b style={{ color: "#008a43" }}>${finalPrice.toFixed(2)}</b></p>
                        </div>
                    </div>
                    <hr className={classes.smallDivider} />
                    {/* <Button variant="contained" color="secondary" style={{marginTop: "10px", width: "90%", margin: "0 auto"}}>Pay now</Button> */}
                    <p className={classes.star}>* Total BC taxes include Provincial Sales Tax (7%) + Goods and Services Tax (5%)</p>
                    <div className={classes.paypal}>
                        <PayPalScriptProvider options={initialOptions} >
                            <PayPalButtons
                                style={{ layout: "vertical", }}
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        purchase_units: [
                                            {
                                                amount: {
                                                    value: finalPrice.toString(),
                                                },
                                            },
                                        ],
                                    })
                                }}
                                onApprove={handleApprove} onError={handleError} onCancel={handleCancel}
                            />
                        </PayPalScriptProvider>
                    </div>
                </>
                }
            </div>
        </>
    )
}