import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from "@material-ui/core/Container";
import ServiceCategoryStyle from "./ServiceCategory.style";

const ImageBackdrop = styled("div")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: "#000",
    opacity: 0.5,
    transition: theme.transitions.create("opacity"),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("md")]: {
        width: "100% !important",
        height: 100,
    },
    "&:hover": {
        zIndex: 1,
        color: "#ffffff",
        opacity: 0.8,
    },
    "&:hover .imageBackdrop": {
        opacity: 0.15,
    },
    "&:hover .imageMarked": {
        opacity: 0,
    },
    "&:hover .imageTitle": {
        border: "4px solid currentColor",
        // color:'warning.main',
        color: "#ffffff",
    },
    "& .imageTitle": {
        position: "relative",
        padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
    },
    "& .imageMarked": {
        height: 3,
        width: 18,
        background: theme.palette.common.white,
        position: "absolute",
        bottom: -2,
        left: "calc(50% - 9px)",
        transition: theme.transitions.create("opacity"),
    },
}));

const images = [
    {
        url: "https://tse4-mm.cn.bing.net/th/id/OIP-C.Fl8BUCwLs22fli59E3bkKgHaE8?pid=ImgDet&rs=1",
        title: "Child Care",
        width: "33%",
    },
    {
        url: "https://tse4-mm.cn.bing.net/th/id/OIP-C.ho9C1lsls18upz7x-nJ9vgHaE8?pid=ImgDet&rs=1",
        title: "School Support",
        width: "33%",
    },
    {
        url: "https://tse2-mm.cn.bing.net/th/id/OIP-C._UtcVs4lL--_WajKtJ4HmwHaE1?pid=ImgDet&rs=1",
        title: "Senior Care",
        width: "33%",
    },
    {
        url: "https://tse1-mm.cn.bing.net/th/id/OIP-C.BW8ChFbbPxrjZo9x8dmdXAHaE8?pid=ImgDet&rs=1",
        title: "House cleaning",
        width: "33%",
    },
    {
        url: "https://tse1-mm.cn.bing.net/th/id/R-C.5d4aa5682e046463204ed496d62c15d9?rik=cZ7Bl9aOWgm%2fWA&riu=http%3a%2f%2funitedwaypbc.org%2fwp-content%2fuploads%2f2018%2f07%2fSpecial-needs.jpg&ehk=PSAU%2bHF6YWWIIBhrbtsiaA%2fAkZmI5cSzHxJ0JrzR3Ow%3d&risl=&pid=ImgRaw&r=0",
        title: "Special Needs",
        width: "33%",
    },
    {
        url: "https://tse2-mm.cn.bing.net/th/id/OIP-C.HOEggme0kMpeUokQE5UYdwHaE8?pid=ImgDet&rs=1",
        title: "Pet Care",
        width: "33%",
    },
    {
        url: "https://tse3-mm.cn.bing.net/th/id/OIP-C.1hhzrb7FeaVNHoV84bLv0QHaFC?pid=ImgDet&rs=1",
        title: "Bed Assembly",
        width: "33%",
    },
    {
        url: "https://tse1-mm.cn.bing.net/th/id/R-C.fabd63a77d8e805891365a4662f7c6c1?rik=lsWcEhbLM4bVVw&riu=http%3a%2f%2fwww.overallplumbing.com%2fwp-content%2fuploads%2f2015%2f03%2ftoilet-trouble-255x300.jpg&ehk=%2fuWjD0Yh1mMZWuVUc1ng8zb1k3pWP1Z8VEG9pOwKwnk%3d&risl=&pid=ImgRaw&r=0",
        title: "Toilet Trouble",
        width: "33%",
    },
    {
        url: "https://tse1-mm.cn.bing.net/th/id/R-C.935088ca1b46d7f8686f000a82a9f46c?rik=NWRndi5ZU4RYFA&riu=http%3a%2f%2f247servicecall.com%2fwp-content%2fuploads%2f2019%2f10%2fbg-9.jpg&ehk=SSUTPb3XhcK7xQyXVnm3IKijFH1vijcHTu9OVtfKM4Y%3d&risl=&pid=ImgRaw&r=0",
        title: "Plumbing Service",
        width: "33%",
    },
];

export default function ServiceCategory() {
    return (
        <Container component="section" sx={{ mt: 8, mb: 4 }}>
            <br />
            <br />

            <Box sx={{ mt: 8, display: "flex", flexWrap: "wrap" }}>
                {images.map((image) => (
                    <ImageIconButton
                        key={image.title}
                        style={{
                            width: image.width,
                        }}
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                backgroundSize: "cover",
                                backgroundPosition: "center 40%",
                                backgroundImage: `url(${image.url})`,
                            }}
                        />
                        <ImageBackdrop className="imageBackdrop" />
                        <Box
                            sx={{
                                position: "absolute",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                fontSize: "medium",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#ffffff",
                            }}
                        >
                            <ServiceCategoryStyle
                                variant="h4"
                                color="#ffffff"
                                className="imageTitle"
                                fontSize="large"
                            >
                                {image.title}
                                <div className="imageMarked" />
                            </ServiceCategoryStyle>
                        </Box>
                    </ImageIconButton>
                ))}
            </Box>
        </Container>
    );
}
