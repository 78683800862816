import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    {
        backBtn: {
            marginLeft: "20px",
        },
        infoDiv: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        },
        noticeDiv: {
            height: "100px",
            width: "60%",
            borderRadius: "15px",
            backgroundColor: "orange",
            display: "flex",
            justifyContent: "center",
        },
        noticeInfo: {
            width: "80%",
            fontSize: "27px",
            fontWeight: "bold",
            color: "white",
            marginTop: "10px",
        },
        noticeDiv2: {
            height: "100px",
            width: "60%",
            borderRadius: "15px",
            backgroundColor: "hotpink",
            display: "flex",
            justifyContent: "center",
        },
        noticeInfo2: {
            width: "80%",
            fontSize: "27px",
            fontWeight: "bold",
            color: "white",
            marginTop: "10px",
        },
        fieldStyle: {
            marginTop: "20px",
        },
        formStyle: {
            width: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        },
        divider: {
            marginTop: "20px",
            background: "orange",
            height: "5px",
            width: "100%",
        },
        boldFont: {
            fontWeight: "bold",
        },
        detailDiv: {
            width: "100%",
            marginLeft: "40%",
        },
        changeBtn: {
            width: "300px",
            height: "50px",
            backgroundColor: "lightblue",
        },
        cancelBtn: {
        },
        saveBtn: {
            marginLeft: "20px",
            backgroundColor: "lightblue",
        },
    },
);
