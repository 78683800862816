import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FF9800",
        minHeight: 900,
    },
    topLeftBtn: {
        position: "absolute",
        top: "8px",
        left: "0px",
    },
    divider: {
        background: "black",
    },
    card: {
        borderRadius: 30,
        minWidth: "90%",
        // textAlign: "center",
        marginTop: 150,
        marginBottom: 50,
        minHeight: 1200,
    },
    header: {
        textAlign: "center",
        spacing: 10,
    
    },
    cardGrid: {
        // paddingTop: theme.spacing(8),
        // paddingBottom: theme.spacing(8),
        // boardWidth: "20px",
    },
    cardBoarder: {
        // boardWidth: "20px",\
        borderRadius: 30,
        backgroundColor: "#FF9800",
        // alignItems: "center",
        minWidth: "80%",
        display: "flex",
        flexDirection: "row",
        margin: 60,
    },

    suggestCard: {
        borderRadius: 20,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: 800,
        margin: 10,
        maxHeight: 1000,
    },
    cardMedia: {
        paddingTop: "56.25%", // 16:9
    },
    cardContent: {
        flexGrow: 1,
        // alignItems: "center",
    },
    cardActionSection: {
        display: "flex",
        flexDirection: "column",
        align: "right",
        float: "right",
        marginBottom: 30,
    },
    chooseButton: {
        backgroundColor: "#ff9800",
        // position: "relative",
        display: "flex",
        flexDirection: "column",
        align: "right",
        float: "right",
        justifyContent: "right",
        color: "#FFFFFF",
    },
    pagination: {
        color: "#ff9800",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 30,
        // margin: 30,
    },
    suggestWord: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 30,
    },
    viewMore: {
        backgroundColor: "white",
        textAlign: "right",
        marginRight: 30,
    },
    serviceTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    boldFont: {
        fontWeight: "bold",
    },
}));
