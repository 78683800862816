import axios from "axios";
export const GetData = async () => {
    const response:any = await axios.get("https://helpercloud.herokuapp.com/api/services/getServices/tutoring");
    return response.data;
}
export const InitializeConversation = async (proName: string) => {
    return await axios.post("https://helpercloud.herokuapp.com/api/contact/initializeConversation", {
        proName: proName,
        clientName: sessionStorage.getItem("name")
    })
}