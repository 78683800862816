import { Router, Route, Switch } from "react-router-dom";
import Landboard from "./pages/Landboard/Landboard";
import Login from "./pages/Login/Login";
// import PostInfo from "./pages/ProEditPostInfo/ProEditPostInfo";
import ServiceListNew from "./pages/ServiceListNew/ServiceListNew";
import UserProfile from "./pages/UserProfile/UserProfile";
import ReserveInfo from "./pages/ReserveInfo/ReserveInfo";
import Register from "./pages/Register/Register";
import ServiceDetail from "./pages/ServiceDetail/ServiceDetail";
import history from "./util/history";
import WebFont from "webfontloader";
import Survey from "./pages/CleanSurvey/CleanSurvey";
import SuggestCards from "./pages/SuggestServiceCards/SuggestCards";
import Pay from "./pages/Pay/Pay";
import OrderDetail from "./pages/OrderDetail/OrderDetail";
import ProLandboard from "./pages/ProLandPage/ProLandboard";
import OrderPage from "./pages/OrderPage/OrderPage";
import ProServiceType from "./pages/ProType/ProType";
import ClientOrderList from "./pages/ClientOrderList/ClientOrderList";
import ProCleanSurvey from "./pages/ProCleanSurvey/ProCleanSurvey";
import Chat from "./pages/Chat/Chat";
import ProServiceDetail from "./pages/ServiceDetail/ProServiceDetail";
import EditServiceCards from "./pages/EditServiceCards/EditServiceCards";
import ForgetPassword from "./pages/Login/ForgetPassword";
import ProServiceProfile from "./pages/ProEditPostInfo/ProServiceProfile";
import { ContactServiceProvider } from "./pages/ContactServiceProvider/ContactServiceProvider";
import { ProsChat } from "./pages/ProsChat/ProsChat";
import { io } from "socket.io-client";
import { ProTutoringSurvey } from "./pages/ProTutoringSurvey/ProTutoringSurvey";
import { SuggestServiceCardsTutoring } from "./pages/SuggestServiceCardsTutoring/SuggestServiceCardsTutoring";
import { ServiceDetailTutoring } from "./pages/ServiceDetailTutoring/ServiceDetailTutoring";
import { ProProfile } from "./pages/ProProfile/ProProfile";
import { ServiceFeedback } from "./pages/ServiceFeedback/ServiceFeedback";
import { TutoringOrder } from "./pages/TutoringOrder/TutoringOrder";
import { ProviderDashboard } from "./pages/ProviderDashboard/ProviderDashboard";
import { ClientViewOrders } from "./pages/ClientViewOrders/ClientViewOrders";
import { PrePaymentConfirmation } from "./pages/PrePaymentConfirmation/PrePaymentConfirmation";
import { ClientPublicProfile } from "./pages/ClientPublicProfile/ClientPublicProfile";
import { ProviderPayout } from "./pages/ProviderPayout/ProviderPayout";
import { UserProvider } from "./context/UserContext";
const url = "https://helpercloud.herokuapp.com";
const socket = io(url);

{
    /* <style>@import url('https://fonts.googleapis.com/css?family=Roboto');</style>; */
}

const App = () => {
    return (
        <Router history={history}>
            <UserProvider>
            <Switch>
                <Route exact path="/">
                    <Landboard />
                </Route>
                <Route path="/Login">
                    <Login />
                </Route>
                <Route path="/Register">
                    <Register />
                </Route>
                <Route path="/User">
                    <UserProfile />
                </Route>
                <Route path="/PostInfo/:serviceId">
                    <ProServiceProfile />
                </Route>
                <Route path="/PostList">
                    <ServiceListNew />
                </Route>
                <Route path="/OrderDetail">
                    <OrderDetail />
                </Route>
                <Route path="/OrderPage/:orderId">
                    <OrderPage />
                </Route>
                <Route exact path="/SuggestionCards/Cleaning">
                    <SuggestCards />
                </Route>
                <Route exact path="/SuggestionCards/Tutoring">
                    <SuggestServiceCardsTutoring />
                </Route>
                <Route path="/ServiceDetail/:serviceId">
                    <ServiceDetail />
                </Route>
                <Route path="/ServiceDetailTutoring/:serviceId">
                    <ServiceDetailTutoring />
                </Route>
                <Route path="/Survey/:serviceId">
                    <Survey />
                </Route>
                <Route path="/ReverseDetail/:orderId">
                    <ReserveInfo />
                </Route>
                <Route path="/OrderList">
                    <ClientOrderList />
                </Route>
                <Route path="/Pay/:orderId">
                    <Pay />
                </Route>
                <Route path="/Pros/ServiceType">
                    <ProServiceType />
                </Route>
                <Route path="/Pros/Landboard">
                    <ProLandboard />
                </Route>
                <Route exact path="/Pros/Survey/cleaning">
                    <ProCleanSurvey />
                </Route>
                <Route exact path="/Pros/Survey/tutoring">
                    <ProTutoringSurvey />
                </Route>
                <Route path="/Pros/ServiceDetail/:serviceId">
                    <ProServiceDetail />
                </Route>
                <Route path="/EditServiceCards">
                    <EditServiceCards />
                </Route>
                <Route path="/Chat">
                    <Chat />
                </Route>
                <Route path="/ForgetPassword">
                    <ForgetPassword />
                </Route>
                <Route exact path="/contact/client/:clientName">
                    <ContactServiceProvider socket={socket} />
                </Route>
                <Route exact path="/contact/provider/:proName">
                    <ProsChat socket={socket} />
                </Route>
                <Route exact path="/profile/provider/:proName">
                    <ProProfile />
                </Route>
                <Route exact path="/services/feedback/:serviceId">
                    <ServiceFeedback />
                </Route>
                <Route exact path="/services/tutoring/order/:serviceId">
                    <TutoringOrder />
                </Route>
                <Route exact path="/pros/dashboard/:proName">
                    <ProviderDashboard />
                </Route>
                <Route exact path="/client/orders/:clientName">
                    <ClientViewOrders />
                </Route>
                <Route exact path="/client/orders/prePaymentConfirmation/:orderId">
                    <PrePaymentConfirmation />
                </Route>
                <Route path="/client/profile/:userId">
                    <ClientPublicProfile />
                </Route>
                <Route path="/provider/orders/prePaymentConfirmation/:clientId/:orderId">
                    <ProviderPayout />
                </Route>

                {/* <Route path="/Pros/ProServiceCards/:userId">
                    <ProServiceCards />
                </Route> */}
            </Switch>
            </UserProvider>
        </Router>
    );
};
export default App;
