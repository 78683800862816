import { useTheme } from "@material-ui/styles"
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AppBarTop from "../../components/AppBarTop/AppBarTop"
import { GetProviderDetails, GetAllProviders } from "./handler";
import { useStyles } from "./ProProfile.style";
import UserIcon from "../../images/userIcon.png";
import { BsFillPersonFill, BsFillTelephoneFill } from 'react-icons/bs';
import { CircularProgress } from "@material-ui/core";
import { MdPayment } from 'react-icons/md';


export const ProProfile = () => {
    const [data, setData] = useState<any>()
    const { proName } = useParams<{ proName: string }>();
    const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [loadingProviders, setLoadingProviders] = useState(true);
    const [providers, setProviders] = useState<any>();
    useEffect(() => {
        GetProviderDetails(proName)
            .then(res => {
                console.log(res)
                setData(res.data)
                setLoading(false)
            })
        GetAllProviders()
            .then(res => {
                setProviders(res.data);
                setLoadingProviders(false);
            })
    }, [proName])
    return (
        <>
            <AppBarTop />
            <div className={classes.container}>
                {loading ? <div style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div> :
                    <>
                        <div className={classes.left}>
                            <img src={UserIcon} className={classes.profileImage} />
                            <p className={classes.subHeading} style={{ fontWeight: "bold" }}><Link to={`/profile/provider/${data.first_name}`}>{data.first_name} {data.last_name}</Link></p>
                            <p className={classes.sub}>{data.companyName}</p>
                            <p className={classes.sub}>{data.servicesOffered[0].address}</p>
                            <p className={classes.sub}>{data.servicesOffered[0].phoneNumber}</p>
                            <div className={classes.button}>
                                <BsFillTelephoneFill className={classes.icon} />
                                <p style={{ margin: "0px 0px" }} onClick={() => history.push(`/contact/${data.first_name}`)}>Message Provider</p>
                                <p style={{ margin: "0px 0px" }}></p>
                            </div>
                            <hr className={classes.divider} />
                            <p className={classes.subHeading}>Additional Details</p>
                            <p className={classes.sub}>Experience: {data.servicesOffered[0].numberYearService} years</p>
                            <p className={classes.sub}>Qualification: {data.servicesOffered[0].qualification}</p>
                            {/* <p className={classes.sub}>Service Type: {data.serviceType}</p> */}
                            <hr className={classes.divider} />
                        </div>
                        <div className={classes.center}>
                            <h1 className={classes.heading}>
                                {data.first_name} has {data.servicesOffered.length} {data.servicesOffered.length === 1 ? "service" : "services"}
                            </h1>
                            {data.servicesOffered.map((service: any, index: any) => {
                                return (
                                    <div className={classes.serviceWrapper} key={index}>
                                        <h3 className={classes.subHeading}>{index + 1}. {service.serviceTitle}</h3>
                                        <p className={`${classes.alignLeft} ${classes.sub}`}>{service.serviceDescription}</p>
                                        <p className={`${classes.alignLeft} ${classes.sub}`}>Rate: {service.servicePrice} CAD / hour</p>
                                        <p className={`${classes.alignLeft} ${classes.sub}`}>Mode of delivery: {service.serviceMode}</p>
                                        <Link to={`/ServiceDetailTutoring/${service._id}`} style={{ textDecoration: 'none', color: "inherit" }}>
                                            <div className={classes.purchaseButton}>
                                                <MdPayment />
                                                <p style={{ margin: "0px 0px" }}>View Details</p>
                                                <p style={{ margin: "0px 0px" }}></p>
                                            </div>
                                        </Link>
                                        <hr className={classes.divider} />
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div className={classes.right}>
                            <h3 className={classes.heading} style={{ textAlign: 'center', marginTop: "20px", fontSize: '2rem' }}>Other Providers</h3>
                            {loadingProviders ? <CircularProgress /> :
                                providers.map((pro: any, index: any) => {
                                    return (
                                        <Link to={`/profile/provider/${pro.first_name}`} style={{ textDecoration: 'none' }}>
                                            <div className={classes.similarService} key={pro.first_name} >
                                                <p className={classes.subHeading}>{pro.first_name} {pro.last_name}</p>
                                                <p className={`${classes.sub} ${classes.alignLeft}`}>Number of services: {pro.servicesOffered.length}</p>
                                            </div>
                                        </Link>


                                    )
                                })
                            }

                        </div>
                    </>
                }
            </div>

        </>
    )
}