/* API endpoint locally */
export const API_URL =
    // process.env.NODE_ENV === "development"
        // ?
        //  `http://${window.location.hostname}:5000/api/`
        // : "https://vanservice-backend-server.herokuapp.com/api/";
        "https://helpercloud.herokuapp.com/api/"

// /* API endpoint cloud server */
// export const API_URL = "https://vanservice-backend-server.herokuapp.com/api/";

/* API endpoint cloud server */
// export const API_URL =
//     process.env.NODE_ENV === "development"
//         ? "https://vanservice-backend-server.herokuapp.com/api/"
//         : "/api/";

export enum Endpoint {
    REGISTER = "users",
    LOGIN = "users/login",
    PROFILE = "users/me",
    CLIENT = "users/",
    SERVICE = "services",
    AVATAR = "users/profile/avatar",
    FAVORITE = "users/favorite",
    FEEDBACK = "feedbacks/",
    TYPE_SERVICE = "services/types/",
    DETAIL_SERVICE = "services/detail/",
    SERVICE_RATEING = "serviceRating/",
    USER_ORDER = "users/orders/",
    SUGGEST = "suggest",
    SERVICE_ORDER = "services/orders/",
    SERVICE_USER = "services/user",
    SERVICE_SERVICEID = "services/serviceId/",
    ORDER = "order/",
    ORDER_TIME = "order/time/",
    ORDER_LIST = "orderlist",
    NEW_TUTORING_SERVICE = "services/new/tutoring"
}

export const apiFetch = async (
    endpoint: Endpoint,
    urlParams: string = "",
    customInit: RequestInit = {}
): Promise<Response> => {
    const url = API_URL + endpoint + urlParams;
    console.log(url);
    const init: RequestInit = {
        ...customInit,
        headers: {
            "Content-Type": "application/json",
            ...customInit.headers,
        },
    };

    if (init.body instanceof FormData) {
        delete (init.headers as any)["Content-Type"];
    }

    return fetch(url, init).then(async (resp) => {
        if (!resp.ok) {
            const msg = "API Fetch failed with HTTP Status " + resp.status;
            return Promise.reject(msg);
        }

        return resp;
    });
};

export const APILoadError = "APILoadError";
export type TAPILoadError = typeof APILoadError;
