import { Button, Typography } from "@material-ui/core";
import dashboard from "./dashboard-images/dashboard.jpg";
import timeMoney from "./dashboard-images/timeMoney.jpg";
import { useStyles } from "./prolandboard.style";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";
import TwitterIcon from "@material-ui/icons/Twitter";
import AppBarTop from "../../components/AppBarTop/AppBarTop";
import ProServiceFlow from "../../components/ServiceFlow/ProServiceFlow";
import { useHistory } from "react-router-dom";
import { useState } from "react";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>
            {new Date().getFullYear()}
        </Typography>
    );
}

const ProLandboard = () => {
    const styles = useStyles();
    const history = useHistory();
    const [isShown, setShown] = useState(false);

    return (
        <>
            <AppBarTop />
            <div className={styles.container}>
                <header className={styles.header}>
                    <img
                        src={dashboard}
                        className={styles.house}
                        alt="LOGO_background"
                    />
                </header>
            </div>

            <div className={styles.heroContent}>
                <Container maxWidth="sm">
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="textPrimary"
                        gutterBottom
                    >
                        Van Services
                    </Typography>
                    <Typography
                        variant="h4"
                        align="center"
                        color="textSecondary"
                        paragraph
                    >
                        <Typography
                            variant="h4"
                            align="center"
                            color="textSecondary"
                            paragraph
                        ></Typography>
                        Van Service is a professional platform connecting
                        customers to Pro’s.
                    </Typography>
                    <div className={styles.heroButtons}>
                        <Grid container spacing={2} justify="center">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={styles.learnMoreButton}
                                    onClick={() =>
                                        history.push("/Pros/ServiceType")
                                    }
                                >
                                    Join Us
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>

            <div className={styles.timeImageAndTxt}>
                <img
                    src={timeMoney}
                    className={styles.timeImage}
                    alt="LOGO_background"
                />
                <Typography variant="h4" align="center" gutterBottom>
                    Use your free time to make more money on VanService!
                </Typography>
            </div>

            <div className={styles.centerButton}>
                <Button
                    variant="contained"
                    color="primary"
                    className={styles.learnMoreButton}
                    onClick={() => setShown(!isShown)}
                >
                    5 steps to become a Pro
                </Button>
            </div>
            {isShown ? <ProServiceFlow /> : <></>}

            <footer className={styles.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                    About Us
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    component="p"
                >
                    Burnaby: 8888 University Dr, Burnaby, BC V5A 1S6 Phone:
                    (778) 782-3111
                </Typography>
                <br />
                <Typography variant="h6" align="center" gutterBottom>
                    Contact Us
                </Typography>

                <Typography variant="h6" align="center">
                    <FacebookIcon className={styles.icon} />
                    <InstagramIcon className={styles.icon} />
                    <TwitterIcon className={styles.icon} />
                    <EmailIcon className={styles.icon} />
                </Typography>
                <br />
                <Copyright />
            </footer>
        </>
    );
};

export default ProLandboard;
