import { FormikHelpers } from "formik";
import { apiFetch, Endpoint } from "../../util/endpoints";
import { FormField, TFormValues } from "./ProCleanSurveyFormFields";
import history from "../../util/history";
import { getToken } from "../../util/cookie";
import { language } from "../../util/proSurvey";

const addSurvey = async (surveyInfo: string, token: string) => {
    const init: RequestInit = {
        headers: {
            authorization: token,
            Accept: "application/json",
        },
        method: "POST",
        body: surveyInfo,
    };
    return await apiFetch(Endpoint.SERVICE, "", init)
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            return res;
        });
};

// export const addPostInfo = async (postInfo: string, token: string) => {
//     const init: RequestInit = {
//         method: "POST",
//         headers: {
//             authorization: token,
//             Accept: "application/json",
//         },
//         body: postInfo,
//     };
//     return await apiFetch(Endpoint.SERVICE, "", init)
//         .then((res) => {
//             return res.json();
//         })
//         .then((res) => {
//             return res as IPostInfo;
//         });
// };

export const handleSubmit = async (
    values: TFormValues,
    helpers: FormikHelpers<TFormValues>,
    setSubmissionError: React.Dispatch<React.SetStateAction<boolean>>,
    date: Date
) => {
    let TimeLines: string[] = [];
    const getTimeLine = () => {
        if (values[FormField.timeRange9] === true) {
            TimeLines.push("9");
        }
        if (values[FormField.timeRange10] === true) {
            TimeLines.push("10");
        }
        if (values[FormField.timeRange11] === true) {
            TimeLines.push("11");
        }
        if (values[FormField.timeRange12] === true) {
            TimeLines.push("12");
        }
        if (values[FormField.timeRange13] === true) {
            TimeLines.push("13");
        }
        if (values[FormField.timeRange14] === true) {
            TimeLines.push("14");
        }
        if (values[FormField.timeRange15] === true) {
            TimeLines.push("15");
        }
        if (values[FormField.timeRange16] === true) {
            TimeLines.push("16");
        }
        if (values[FormField.timeRange17] === true) {
            TimeLines.push("17");
        }
    };
    const tran_date = new Date(date.setDate(date.getDate() - 1));
    await getTimeLine();
    const newSurvey = JSON.stringify({
        serviceType: values[FormField.serviceType],
        numberYearService: values[FormField.numberYearService],
        serviceTitle: values[FormField.serviceTitle],
        identity: values[FormField.identity],
        startDate: tran_date.toISOString().slice(0, 10),
        isSmoker: values[FormField.isSmoker],
        haveCar: values[FormField.haveCar],
        petAvail: values[FormField.petAvail],
        servicePrice: values[FormField.price],
        serviceLanguage: values[FormField.language],
        timeLine: TimeLines,
        firstName: values[FormField.firstName],
        lastName: values[FormField.lastName],
        phoneNumber: values[FormField.phoneNumber],
        serviceDescription: values[FormField.serviceDescription] as String,
        companyName: values[FormField.companyName],
    });
    console.log(newSurvey);
    try {
        const service = await addSurvey(newSurvey, getToken());
        history.push(`/Pros/ServiceDetail/${service._id}`);
    } catch (e) {
        helpers.setSubmitting(false);
        setSubmissionError(true);
    }
};
