import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { AccountCircle } from "@material-ui/icons";
import { Button, Divider, Typography } from "@material-ui/core";
import { useStyles } from "./ReviewListForm.styles";
import ReviewDialog from "../ReviewDialog/ReviewDialog";
import { IServiceRating } from "../../util/service";
import { Skeleton } from "@material-ui/lab";
import StarShow from "../../components/StarShow/StarShow";

interface IProps {
    serviceId: String;
    serviceRatingArray: IServiceRating[];
    refreshList: () => void;
}

const convertDateToString = (date: Date) => {
    const tmpDate = new Date(date);
    const dateString =
        tmpDate.getFullYear() +
        "-" +
        tmpDate.getMonth() +
        "-" +
        tmpDate.getDate();
    return dateString;
};

const ReviewList = (props: IProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return props.serviceRatingArray ? (
        props.serviceRatingArray.length !== 0 ? (
            <List className={classes.root}>
                {Object.values(props.serviceRatingArray).map(
                    (serviceRating) => (
                        <>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AccountCircle />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        serviceRating.reviewer.firstName +
                                        " " +
                                        serviceRating.reviewer.lastName
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                // className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {convertDateToString(
                                                    serviceRating.reviewer
                                                        .createdAt
                                                )}
                                            </Typography>

                                            <div
                                                className={classes.titleBottom}
                                            >
                                                <div
                                                    className={
                                                        classes.starAndRateDiv
                                                    }
                                                >
                                                    {"On Time: "}
                                                    <div
                                                        className={
                                                            classes.starShowDiv
                                                        }
                                                    >
                                                        <StarShow
                                                            rate={
                                                                serviceRating.onTimeRate
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classes.titleBottom}
                                            >
                                                <div
                                                    className={
                                                        classes.starAndRateDiv
                                                    }
                                                >
                                                    {"Attitude: "}
                                                    <div
                                                        className={
                                                            classes.starShowDiv
                                                        }
                                                    >
                                                        <StarShow
                                                            rate={
                                                                serviceRating.attitudeRate
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classes.titleBottom}
                                            >
                                                <div
                                                    className={
                                                        classes.starAndRateDiv
                                                    }
                                                >
                                                    {"Quality: "}
                                                    <div
                                                        className={
                                                            classes.starShowDiv
                                                        }
                                                    >
                                                        <StarShow
                                                            rate={
                                                                serviceRating.qualityRate
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <br />

                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                {serviceRating.serviceContent}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider
                                variant="middle"
                                className={classes.divider}
                            />
                        </>
                    )
                )}
                <br />
                <br />
                {open && (
                    <ReviewDialog
                        serviceId={props.serviceId}
                        refreshList={props.refreshList}
                    />
                )}
                <br />
                <br />
                {/* <Button
                variant="contained"
                color="secondary"
                className={classes.checkButton}
                onClick={() => {
                    setOpen(!open);
                }}
            >
                Make a review
            </Button> */}
            </List>
        ) : (
            <p>No client reviewed this service yet</p>
        )
    ) : (
        <Skeleton variant="rect" height={500} />
    );
};

export default ReviewList;
