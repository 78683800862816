export enum Entity {
    INDIVIDUAL = "INDIVIDUAL",
    COMPANY = "COMPANY",
}

export const entity = {
    [Entity.INDIVIDUAL]: {
        id: 1,
        name: "I am an individual",
    },
    [Entity.COMPANY]: {
        name: "I am from a company",
        id: 2,
    },
};

export enum Language {
    ENG = "ENGLISH",
    FR = "FRENCH",
    CN = "CHINESE",
    KO = "KOREAN",
    GE = "GERMANY",
    JP = "JAPANESE",
    CANT = "CANTONESE",
}

export const language = {
    [Language.ENG]: {
        id: 1,
        name: "English",
    },
    [Language.FR]: {
        id: 2,
        name: "French",
    },
    [Language.CN]: {
        id: 3,
        name: "Chinese-Mardrain",
    },
    [Language.GE]: {
        id: 4,
        name: "Germany",
    },
    [Language.KO]: {
        id: 5,
        name: "Korean",
    },
    [Language.JP]: {
        id: 6,
        name: "Japanese",
    },
    [Language.CANT]: {
        id: 7,
        name: "Chinese-Cantonese",
    },
};

export const languages = [
    "English",
    "French",
    "Chinese-Mandarin",
    "Chinese-Cantonese",
    "Germany",
    "Korean",
    "Japanese",
];
