import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppBarTop from "../../components/AppBarTop/AppBarTop";
import { useStyles } from "./ServiceFeedback.style";
import { GetDetails, SubmitFeedback } from "./handler";
import { CircularProgress, Slider, TextField, Button } from "@material-ui/core";
export const ServiceFeedback = () => {
    const classes = useStyles();
    const { serviceId } = useParams<{ serviceId: string }>();
    const [serviceTitle, setServiceTitle] = useState("");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(true);
    const [clientAgrees, setClientAgrees] = useState(false);
    const [timeRating, setTimeRating] = useState(3);
    const [qualityRating, setQualityRating] = useState(3);
    const [attitudeRating, setAttitudeRating] = useState(3);
    const [reviewMessage, setReviewMessage] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const marks = [
        {value: 1, label: "1"}, {value: 2, label: "2"}, {value: 3, label: "3"}, {value: 4, label: "4"}, {value: 5, label: "5"}
    ];
    useEffect(() => {
        GetDetails(serviceId)
            .then(res => {
                setServiceTitle(res.data.serviceTitle);
                const name = res.data.firstName + " " + res.data.lastName;
                setName(name);
                setLoading(false);
            })
    }, [])
    const handleSubmit = () => {
        setSubmitLoading(true);
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const dateString = day + "/" + month + "/" + year;
        const from = firstName + " " + lastName;
        const feedback = {
            serviceId, dateString, timeRating, qualityRating, attitudeRating, reviewMessage, from, clientId: sessionStorage.getItem("userId")
        }
        SubmitFeedback(feedback)
            .then(res => {
                console.log(res);
                if (res.data.success) {
                    setSubmitLoading(false);
                    setSuccess(true);
                }
            })
    }
    return (
        <>
            <AppBarTop />
            {loading ? <div style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div> :
                <div className={classes.container}>
                    <p className={classes.heading}>Provide feedback for {serviceTitle} by {name}</p>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Please enter your first name</p>
                        <TextField className={classes.formInput} onChange={e => setFirstName(e.target.value)} color="secondary" variant="outlined" placeholder="Jon" />
                    </div>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Please enter your last name</p>
                        <TextField className={classes.formInput} onChange={e => setLastName(e.target.value)} color="secondary" variant="outlined" placeholder="Snow" />
                    </div>
                    <p className={classes.formLabel}>Please answer the following questions on a scale of 1-5 (1 being the worst)</p>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>How would you rate the service on time?</p>
                        <p className={classes.sub}>Was the service delivered on time? How punctual was the service provider?</p>
                        <Slider valueLabelDisplay={"on"} defaultValue={3} min={1} max={5} className={classes.slider} marks={marks} step={1} value={timeRating} onChange={(e, num: any) => setTimeRating(num)} />
                    </div>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>How would you rate the quality of the service?</p>
                        <p className={classes.sub}>Was the quality of the service as you expected? Did the service provider deliver everything that was promised?</p>
                        <Slider valueLabelDisplay={"on"} defaultValue={3} min={1} max={5} className={classes.slider} marks={marks} value={qualityRating} onChange={(e, num: any) => setQualityRating(num)} />
                    </div>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>How would you rate the attitude of the service provider?</p>
                        <p className={classes.sub}>Was the service provider professional in delivering the service? Was their behaviour acceptable?</p>
                        <Slider valueLabelDisplay={"on"} defaultValue={3} min={1} max={5} className={classes.slider} value={attitudeRating} marks={marks} onChange={(e, num: any) => setAttitudeRating(num)} />
                    </div>
                    <div className={classes.formContainer}>
                        <p className={classes.formLabel}>Please leave a review for the service</p>
                        <p className={classes.sub}>To make your review as helpful as possible, be sure to comment on the specifics of the service, the quality that you expected and received, and how the provider can improve their service in the future.</p>
                        <TextField onChange={(e) => setReviewMessage(e.target.value)} value={reviewMessage} color="secondary" variant="outlined" rows={4} multiline className={classes.formInput} placeholder="This service has certainly been very useful for me, and has helped in improving my grades substantially. The tutor was extremely professional and easy to talk to." />
                    </div>
                    <div className={classes.noticeContainer}>
                        <div className={classes.checkboxContainer}>
                            <input type="checkbox" className={classes.checkbox} checked={clientAgrees} onClick={() => setClientAgrees(!clientAgrees)} />
                            <p className={classes.sub}>By clicking submit, I agree to share my review with others and with the service provider.</p>
                        </div>
                    </div>
                    {!submitLoading && !success && <Button onClick={handleSubmit} disabled={clientAgrees ? false : true} variant="contained" color="primary" size="large" style={{ width: '200px', margin: '0 auto', fontSize: '1.3rem' }}>SUBMIT</Button>}
                    {submitLoading && <CircularProgress style={{ margin: '0 auto', display: 'block' }}/>}
                    {success && <p style={{ color: 'green', fontSize: '1.2rem', textAlign: 'center' }}>Your review has been submitted successfully. Thank you.</p>}
                </div>
            }
        </>
    )
}