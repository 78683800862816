import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { useHistory } from "react-router-dom";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { getToken, logout } from "../../util/cookie";
import { getAuth } from "../../util/auth";
import { IUser } from "../../util/users";
import { Endpoint } from "../../util/endpoints";
import SearchIcon from "@material-ui/icons/Search";
import UserIcon from "@material-ui/icons/AccountCircle";
import InputBase from "@material-ui/core/InputBase";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { common } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
    bar: {
        backgroundColor: "#ff9800",
    },
    container: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    cardMedia: {
        paddingTop: "56.25%", // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    newsSection: {
        // Other styles
        display: "flex",
        flexDirection: "row",
    },
    registerButton: {
        position: "relative",
        color: "white",
        marginLeft: "20px",
    },
    menuButton: {
        position: "absolute",
        right: 0,
        color: "white",
    },
    logoButton: {
        fontFamily: "Myriad Pro Regular",
        color: "white",
        minWidth: "200px",
    },
    becomeHostButton: {
        position: "absolute",
        top: "38px",
        right: "0px",
    },
    navigationButton: {
        position: "relative",
        marginLeft: "50px",
        color: "white",
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            // backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(1),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    contacttext: {
        position: "relative",
        marginLeft: "30px",
        color: "white",
        fontWeight: "normal",
    },
    inputRoot: {
        color: "white",
        // backgroundColor: "white",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
        // backgroundColor: "white",
    },
    logo: {
        maxWidth: "15%",
    },
}));

const AppBarTop = () => {
    const styles = useStyles();
    const history = useHistory();
    const [loginState, setLoginState] = useState(false);
    const [user, setUser] = useState<IUser>();
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const handleClickLogo = () => {
        return history.push(`/`);
    };
    const handleSignIn = () => {
        return history.push("/Login");
    };
    const handleRegister = () => {
        return history.push("/Register");
    };
    const handleCloseMenu = () => {
        setAnchor(null);
    };
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget);
    };
    const handleProfile = () => {
        return history.push(`/User`);
    };

    useEffect(() => {
        const getUser = async () => {
            try {
                const token: string = getToken();
                if (token) {
                    setLoginState(true);
                }
                const theUser: IUser = (await getAuth(
                    Endpoint.PROFILE,
                    token
                )) as IUser;
                setUser(theUser);
            } catch (e) {
                setLoadingError(true);
            }
        };
        getUser();
    }, []);

    return (
        <AppBar position="relative" className={styles.bar}>
            <Toolbar>
                <Button onClick={handleClickLogo} className={styles.logoButton}>
                    <Logo />
                </Button>
                {/* <Button
                    onClick={() => handleNavigation("/PostList")}
                    className={styles.navigationButton}
                >
                    PostList
                </Button>
                <Button
                    onClick={() => handleNavigation("/SuggestionCards")}
                    className={styles.navigationButton}
                >
                    ServiceList
                </Button> */}
                {/* <Button className={styles.contacttext}>
                    Tel: +8621-64326032
                </Button> */}
                <div className={styles.search}>
                    <div className={styles.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Start your search…"
                        classes={{
                            root: styles.inputRoot,
                            input: styles.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                    />
                </div>
                {user && loginState ? (
                    <div className={styles.menuButton}>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                        >
                            <MenuIcon style={{ color: common.white }} />
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchor}
                            keepMounted
                            open={Boolean(anchor)}
                            onClose={handleCloseMenu}
                        >
                            {sessionStorage.getItem("accountType")==="Pros" && <> 
                            <MenuItem onClick={() => history.push(`/pros/dashboard/${sessionStorage.getItem("name")}`)}>Dashboard</MenuItem>
                            <MenuItem onClick={() => history.push(`/contact/provider/${sessionStorage.getItem("name")}`)}>Messages</MenuItem>
                            </>}
                            {sessionStorage.getItem("accountType")==="Client" && <>
                            <MenuItem onClick={() => history.push(`/client/profile/${sessionStorage.getItem("userId")}`)}>My Public Profile</MenuItem>
                            <MenuItem onClick={() => history.push(`/client/orders/${sessionStorage.getItem("name")}`)}>My Orders</MenuItem>
                            </>
                            }
                            <MenuItem onClick={handleProfile}>
                                My Profile
                            </MenuItem>
                            <MenuItem onClick={handleCloseMenu}>
                                My account
                            </MenuItem>
                            <MenuItem onClick={logout}>Logout</MenuItem>
                            
                        </Menu>
                        <Button onClick={handleProfile}>
                            <UserIcon style={{ color: common.white }} />
                        </Button>
                    </div>
                ) : (
                    <div className={styles.menuButton}>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                        >
                            <MenuIcon style={{ color: common.white }} />
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchor}
                            keepMounted
                            open={Boolean(anchor)}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={handleSignIn}>Login</MenuItem>
                            <MenuItem onClick={handleRegister}>
                                Register
                            </MenuItem>
                        </Menu>
                        <Button onClick={handleSignIn}>
                            <UserIcon style={{ color: common.white }} />
                        </Button>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default AppBarTop;

function setLoadingError(arg0: boolean) {
    throw new Error("Function not implemented.");
}
