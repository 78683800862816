import {
    Button,
    Card,
    FormControl,
    FormLabel,
    InputAdornment,
    MenuItem,
    Radio,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { useEffect, useState } from "react";
import {
    fieldLabels,
    emptyValidationSchema,
    initialValues,
    PersonalValidationSchema,
    AddressValidationSchema,
    HomeValidationSchema,
    FormField,
    ContactValidationSchema,
} from "./CleanSurveyFormFields";
import { handleSubmit } from "./formHandler";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { useHistory, useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { equipments } from "../../util/survey";
import { useStyles } from "./CleanSurvey.style";
import { ReactComponent as Clean } from "../../images/buttonIcon/clean.svg";
import { getAuth } from "../../util/auth";
import { Endpoint } from "../../util/endpoints";
import { IServiceDetail } from "../ServiceDetail/ServiceDetail";
import { getToken } from "../../util/cookie";
interface IFormProps {
    formikProps: FormikProps<any>;
}

const surveyTypes: FormField[] = [
    FormField.homeSituation,
    FormField.cleaningTime,
    FormField.cleaningFocus,
    FormField.currentAddress,
    FormField.personal,
    FormField.contactInfo,
];

export const countObjectKeys = (obj: any): number => {
    let count = 0;
    for (const key in obj) {
        ++count;
    }
    return count;
};

interface ISurvey {
    label: string;
    Form: (props: IFormProps) => JSX.Element;
    validationSchema: () => any;
}
const TimeForm = (props: IFormProps) => {
    const styles = useStyles();

    return (
        <div>
            <FormControl fullWidth variant="filled">
                <FormLabel>When would you want the service?</FormLabel>
                <br />
                <Typography className={styles.stepperLabel}>
                    We would like to suggest you to select the duration of the
                    service as{" "}
                    {Math.floor(props.formikProps.values[FormField.area] / 200)}{" "}
                    h
                </Typography>
                <Typography className={styles.stepperLabel}>
                    Please select{" "}
                    {Math.floor(props.formikProps.values[FormField.area] / 200)}{" "}
                    continuous checkbox section
                </Typography>
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble9]}
                    name={FormField.timeRange9}
                    Label={{ label: fieldLabels[FormField.timeRange9] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble10]}
                    name={FormField.timeRange10}
                    Label={{ label: fieldLabels[FormField.timeRange10] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble11]}
                    name={FormField.timeRange11}
                    Label={{ label: fieldLabels[FormField.timeRange11] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble12]}
                    name={FormField.timeRange12}
                    Label={{ label: fieldLabels[FormField.timeRange12] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble13]}
                    name={FormField.timeRange13}
                    Label={{ label: fieldLabels[FormField.timeRange13] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble14]}
                    name={FormField.timeRange14}
                    Label={{ label: fieldLabels[FormField.timeRange14] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble15]}
                    name={FormField.timeRange15}
                    Label={{ label: fieldLabels[FormField.timeRange15] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble16]}
                    name={FormField.timeRange16}
                    Label={{ label: fieldLabels[FormField.timeRange16] }}
                />
                <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    disabled={initialValues[FormField.timeRangeAble17]}
                    name={FormField.timeRange17}
                    Label={{ label: fieldLabels[FormField.timeRange17] }}
                />
                <br />
                {countObjectKeys(props.formikProps.errors) !== 0 ||
                countObjectKeys(props.formikProps.touched) === 0 ? (
                    <Alert severity="error">
                        Please select the consecutive time you prefer to get
                        service
                    </Alert>
                ) : null}
            </FormControl>
        </div>
    );
};

const HomeForm = (props: IFormProps) => {
    const styles = useStyles();

    return (
        <div>
            <FormLabel>What do you expect our housekeeper bring?</FormLabel>
            <br />
            <FormControl fullWidth variant="filled">
                <Field
                    component={TextField}
                    select
                    required
                    variant="filled"
                    label={fieldLabels[FormField.equipment]}
                    name={FormField.equipment}
                >
                    {Object.entries(equipments).map(([value, { name }]) => (
                        <MenuItem key={value} value={value}>
                            {name}
                        </MenuItem>
                    ))}
                </Field>
            </FormControl>
            <br />
            <FormLabel>How many bedrooms?</FormLabel>
            <br />

            <Field
                className={styles.hipWidth}
                component={TextField}
                type="number"
                name={FormField.numberBedRoom}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            bedrooms<sup></sup>
                        </InputAdornment>
                    ),
                }}
            />
            <br />
            <br />
            <FormLabel>How many washrooms?</FormLabel>
            <br />
            <Field
                className={styles.hipWidth}
                component={TextField}
                type="number"
                name={FormField.numberWashRoom}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            washrooms<sup></sup>
                        </InputAdornment>
                    ),
                }}
            />
            <br />
            <br />

            <FormLabel>
                What is number of the area you would like to clean up?
            </FormLabel>
            <br />
            <Field
                className={styles.hipWidth}
                component={TextField}
                type="number"
                name={FormField.area}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            feet<sup>2</sup>
                        </InputAdornment>
                    ),
                }}
            />
            <br />
            <br />
        </div>
    );
};

const FocusForm = () => {
    return (
        <FormControl fullWidth variant="filled">
            <FormLabel>
                What cleaning tasks do you expect the housekeeper to focus on?
            </FormLabel>

            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name={FormField.focusBathCleaning}
                Label={{ label: fieldLabels[FormField.focusBathCleaning] }}
            />
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name={FormField.focusKitchenCleaning}
                Label={{ label: fieldLabels[FormField.focusKitchenCleaning] }}
            />
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name={FormField.focusRoomCleaning}
                Label={{ label: fieldLabels[FormField.focusRoomCleaning] }}
            />
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name={FormField.focusWindowWashing}
                Label={{ label: fieldLabels[FormField.focusWindowWashing] }}
            />
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name={FormField.focusCarpetShampooing}
                Label={{ label: fieldLabels[FormField.focusCarpetShampooing] }}
            />
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name={FormField.focusFurnitureTreatment}
                Label={{
                    label: fieldLabels[FormField.focusFurnitureTreatment],
                }}
            />
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name={FormField.focusLaunary}
                Label={{ label: fieldLabels[FormField.focusLaunary] }}
            />
            <br />
        </FormControl>
    );
};

const AddressForm = (props: IFormProps) => {
    const styles = useStyles();

    return (
        <div className={styles.fieldIndent}>
            <div className={styles.fieldIndent}>
                <FormLabel>Where do you live?</FormLabel>
                <FormControl fullWidth variant="filled">
                    <br />
                    <Field
                        component={TextField}
                        multiline
                        variant="filled"
                        label={fieldLabels[FormField.address]}
                        name={FormField.address}
                        fullWidth
                    />
                    <br />
                    <Field
                        component={TextField}
                        multiline
                        variant="filled"
                        label={fieldLabels[FormField.zipcode]}
                        name={FormField.zipcode}
                        fullWidth
                    />
                </FormControl>
                <br />
            </div>
        </div>
    );
};

const PersonalForm = (props: IFormProps) => {
    const styles = useStyles();

    return (
        <div className={styles.fieldIndent}>
            <div className={styles.fieldIndent}>
                <FormLabel>What is your name?</FormLabel>
                <FormControl fullWidth variant="filled">
                    <br />
                    <Field
                        component={TextField}
                        multiline
                        variant="filled"
                        label={fieldLabels[FormField.firstName]}
                        name={FormField.firstName}
                        fullWidth
                    />
                    <br />
                    <Field
                        component={TextField}
                        multiline
                        variant="filled"
                        label={fieldLabels[FormField.lastName]}
                        name={FormField.lastName}
                        fullWidth
                    />
                    <br />
                </FormControl>
                <br />
            </div>
        </div>
    );
};

const ContactForm = (props: IFormProps) => {
    const styles = useStyles();

    return (
        <div className={styles.fieldIndent}>
            <div className={styles.fieldIndent}>
                <FormLabel>What is your phone number?</FormLabel>
                <FormControl fullWidth variant="filled">
                    <br />
                    <Field
                        component={TextField}
                        name={FormField.phoneNumber}
                        variant="filled"
                        label={fieldLabels[FormField.phoneNumber]}
                        fullWidth
                    />
                    <br />
                </FormControl>
                <br />
            </div>
        </div>
    );
};

const NewSurvey = () => {
    const styles = useStyles();
    const history = useHistory();
    const [step, setStep] = useState<number>(0);
    const [submissionError, setSubmissionError] = useState(false);
    const { serviceId } = useParams<{ serviceId: string }>();
    const queryParams = new URLSearchParams(window.location.search);
    const token: string = getToken();
    const pickedDate = queryParams.get("Date");
    const [timeList, setTimeList] = useState([]);

    const surveySteps: ISurvey[] = [
        {
            label: "Basic Home Situation",
            Form: (formikProps) => HomeForm(formikProps),
            validationSchema: HomeValidationSchema,
        },
        {
            label: "Cleaning Time",
            Form: (formikProps) => TimeForm(formikProps),
            validationSchema: emptyValidationSchema,
        },
        {
            label: "Cleaning Focus",
            Form: () => FocusForm(),
            validationSchema: emptyValidationSchema,
        },
        {
            label: "Address",
            Form: (formikProps) => AddressForm(formikProps),
            validationSchema: AddressValidationSchema,
        },
        {
            label: "Personal information",
            Form: (formikProps) => PersonalForm(formikProps),
            validationSchema: PersonalValidationSchema,
        },
        {
            label: "Contact information",
            Form: (formikProps) => ContactForm(formikProps),
            validationSchema: ContactValidationSchema,
        },
    ];

    const getServiceDetail = async () => {
        const theOrders = await getAuth(
            Endpoint.SERVICE_ORDER + serviceId,
            token
        );

        var tempSchema = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
        ];
        if (theOrders !== undefined && theOrders.length !== 0) {
            const theSchedule = theOrders.find(
                (order: { cleaningDate: any }) =>
                    JSON.stringify(order.cleaningDate).substring(1, 11) ===
                    pickedDate
            );
            console.log(theSchedule);
            if (
                theSchedule !== undefined &&
                theSchedule.timeRange !== undefined
            ) {
                for (var i = 0; i < theSchedule.timeRange.length; i++) {
                    if (theSchedule.timeRange[i] !== null) {
                        tempSchema[i] = true;
                    }
                }
            }
        }

        initialValues.time_range_able_9 = tempSchema[0];
        initialValues.time_range_able_10 = tempSchema[1];
        initialValues.time_range_able_11 = tempSchema[2];
        initialValues.time_range_able_12 = tempSchema[3];
        initialValues.time_range_able_13 = tempSchema[4];
        initialValues.time_range_able_14 = tempSchema[5];
        initialValues.time_range_able_15 = tempSchema[6];
        initialValues.time_range_able_16 = tempSchema[7];
        initialValues.time_range_able_17 = tempSchema[8];
        setTimeList(tempSchema);

        // @ts-ignore
        // console.log(new Date(serviceObj.service.orders[0].cleaningDate));
        // console.log(date);
        // if (timeResult !== undefined ) {
        //     var timetemp = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        //     for (var i=0; i<9; i++) {
        //         if (timeResult.cleaningDate !== null) {
        //             timetemp[i] = 1;
        //         }
        //         console.log(timetemp);
        //     }
        //     setTimeList(timetemp);
        // }
    };

    useEffect(() => {
        getServiceDetail();
    }, []);

    const isTimeStep = step === 1;
    const isFinalStep = step + 1 === surveyTypes.length && step !== 0;
    const prevStep = () => setStep(step - 1);
    const nextStep = async (values: any, helpers: FormikHelpers<any>) => {
        if (isFinalStep) {
            handleSubmit(
                values,
                helpers,
                setSubmissionError,
                history.location.search.slice(6)
            );
        } else if (isTimeStep) {
            var count = 0;
            var max = -1;
            var min = -1;
            helpers.setFieldValue(`${[FormField.timeRangeAble9]}`, true);
            console.log(values[FormField.timeRangeAble9]);
            const timeRangePicked = [
                values[FormField.timeRange9],
                values[FormField.timeRange10],
                values[FormField.timeRange11],
                values[FormField.timeRange12],
                values[FormField.timeRange13],
                values[FormField.timeRange14],
                values[FormField.timeRange15],
                values[FormField.timeRange16],
                values[FormField.timeRange17],
            ];

            for (let i = 0; i < timeRangePicked.length; i++) {
                if (timeRangePicked[i]) {
                    if (count === 0) {
                        min = i;
                    }
                    count++;
                    max = i;
                }
            }

            if (max - min + 1 > count) {
                setStep(step);
                helpers.setTouched({});
            } else {
                setStep(step + 1);
            }
            helpers.setSubmitting(false);
        } else {
            if (step === 0) {
                helpers.setFieldValue(`${[FormField.serviceId]}`, serviceId);
            }
            setStep(step + 1);
            helpers.setSubmitting(false);
            helpers.setTouched({});
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={surveySteps[step].validationSchema}
            onSubmit={nextStep}
            enableReinitialize
        >
            {(formikProps) => (
                <Form>
                    {submissionError && (
                        <Alert
                            onClose={() => setSubmissionError(false)}
                            severity="error"
                        >
                            Something went wrong submitting the survey. Please
                            try again.
                        </Alert>
                    )}

                    <div className={styles.container}>
                        <Button onClick={history.goBack}>
                            <Logo />
                        </Button>
                        <div className={styles.cardBoard}>
                            <Card className={styles.card}>
                                <div className={styles.headIcon}>
                                    <Clean />
                                </div>
                                <Stepper
                                    activeStep={step}
                                    orientation="vertical"
                                >
                                    {surveySteps.map((surveyStep, index) => (
                                        <Step key={index}>
                                            <StepLabel
                                                className={styles.stepperLabel}
                                            >
                                                {surveyStep.label}
                                            </StepLabel>
                                            <StepContent>
                                                <surveyStep.Form
                                                    formikProps={formikProps}
                                                />
                                                <br />
                                                {step !== 0 && (
                                                    <Button
                                                        style={{
                                                            marginRight: "5px",
                                                        }}
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={prevStep}
                                                    >
                                                        Prev Step
                                                    </Button>
                                                )}
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {isFinalStep &&
                                                    index === step
                                                        ? "Submit"
                                                        : "Next Step"}
                                                </Button>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Card>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default NewSurvey;
