import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    {
        container: {
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        central: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        logo: {
            display: "block",
            width: "100%",
        },
        btn: {
            marginRight: 20,
            fontWeight: "bold",
            color: "orange",
            backgroundColor: "white",
        },
        logoutBtn: {
            marginRight: 20,
            fontWeight: "bold",
            color: "white",
            backgroundColor: "orange",
            borderRadius: "30px",
            //boxShadow: "0",
        },
        DescriptionHeight: {
            "& div": {
                color: "black",
                minHeight: "100px",
                minWidth: "500px",
            },
            "& input:disabled": {
                color: "black",
                minHeight: "100px",
                minWidth: "500px",
            },
        },
        iconBtn: {
            marginRight: 20,
            color: "black",
        },
        UpdateBtn: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            color: "orange",
            backgroundColor: "white",
        },
        topLeftBtn: {
            position: "absolute",
            top: "8px",
            left: "0px",
        },
        topRightBtn1: {
            position: "absolute",
            top: "8px",
            right: "0px",
        },
        topRightBtn2: {
            position: "absolute",
            top: "8px",
            right: "60px",
        },
        card: {
            borderRadius: 12,
            maxWidth: 900,
            textAlign: "center",
            margin: "auto",
            float: "none",
            marginBottom: 10,
        },
        header: {
            textAlign: "center",
            spacing: 10,
        },
        action: {
            display: "flex",
            justifyContent: "space-around",
        },
        riskCardContainer: {
            flexGrow: 1,
            marginLeft: "10px",
            marginRight: "10px",
            width: "100%",
        },
        disabledTextField: {
            "& div": {
                color: "black",
            },
            "& input:disabled": {
                color: "black",
            },
        },
        cardCategoryWhite: {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        cardTitleWhite: {
            color: "#FFFFFF",
            marginTop: "0px",
            minHeight: "auto",
            fontSize: "24px",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            marginBottom: "3px",
            textDecoration: "none",
        },
    },

    { index: 1 }
);
