import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Container, Grid, GridSpacing } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export const useStyles = makeStyles((theme) => ({
    outSpace: {
        // backgroundColor: theme.palette.background.paper,
    },
    banner: {
        marginBottom: "20px",
        margin: "auto",
        justifyContent: "space-around",
        fontFamily: "Microsoft yahei",
        flexGrow: 1,
        maxWidth: 700,
    },
    joinhint: {
        fontSize: "18px",
        marginTop: "20px",
        marginBottom: "20px",
        textAlign: "center",
    },
    want: {
        color: "#f67b14",
        fontSize: "20px",
    },
    wanthint: {
        color: "#f8f6f10",
        fontSize: "14px",
    },
    block: {
        float: "left",
        marginTop: "20px",
        // marginBottom: "20px",
        justifyContent: "center",
        listStyle: "none",
        border: "1px #f67b14 solid",
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },

    btn: {
        backgroundColor: "#f67b14",
        padding: "5px 10px",

        marginLeft: "0px",
        color: "white",
        marginTop: "10px",
        // marginBottom: "10px",
        borderRadius: "5px",
    },
}));

const JoinForm = () => {
    const styles = useStyles();
    const history = useHistory();

    return (
        <Container className={styles.outSpace}>
            <Container fixed>
                <Typography className={styles.joinhint}>
                    Choose to fill in the information according to your needs,
                    and we will reply to you as soon as we receive the
                    information.
                </Typography>
            </Container>

            <Grid container className={styles.banner} spacing={2}>
                <Grid item className={styles.block} xs={5}>
                    <div className={styles.want}>I need service</div>
                    <div className={styles.wanthint}>
                        Submit your request message to us
                    </div>
                    {/* <button className={classes.btn}>Post New</button> */}
                    <button
                        className={styles.btn}
                        onClick={() => {
                            history.push("/PostInfo");
                        }}
                    >
                        Find a caregiver
                    </button>
                </Grid>

                <Grid item className={styles.block} xs={5}>
                    <div className={styles.want}>I want to join</div>
                    <div className={styles.wanthint}>
                        Fill in your details and provide us with
                    </div>

                    <button
                        className={styles.btn}
                        onClick={() => {
                            history.push("/Pros/Landboard");
                        }}
                    >
                        Find a job
                    </button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default JoinForm;
