import AppBarTop from "../../components/AppBarTop/AppBarTop";
import { useHistory, useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useStyles } from "./ProviderDashboard.style";
import UserIcon from "../../images/userIcon.png";
import { GetProviderDetails, SubmitOrderDecision } from "./handler";
import { Button, CircularProgress } from "@material-ui/core";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GetOrders } from "./handler";
import { MdDone } from "react-icons/md";
import { VscError } from "react-icons/vsc";

export const ProviderDashboard = () => {
    const { proName } = useParams<{ proName: string }>();
    const userId = sessionStorage.getItem("userId");
    const [loading, setLoading] = useState(true);
    const classes = useStyles();
    const history = useHistory();
    const [data, setData] = useState<any>();
    const [services, setServices] = useState([]);
    const [flag, setFlag] = useState(false);
    useEffect(() => {
        GetProviderDetails(userId)
            .then(res => {
                console.log(res)
                setData(res.data);
                setLoading(false);
            })
        GetOrders(proName)
            .then(res => {
                console.log(res)
                console.log(proName);
                setServices(res.data);
            })
    }, [flag])

    const SubmitDecision = (orderId: string, serviceId: string, decision: string) => {
        SubmitOrderDecision({ orderId, serviceId, decision })
            .then(res => {
                console.log(res.data);
                setFlag(!flag);
            })
    }

    return (
        <>
            <AppBarTop />
            <div className={classes.container}>
                {loading ? <div style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div> :
                    <>
                        <div className={classes.left}>
                            <img src={UserIcon} className={classes.profileImage} />
                            <p className={classes.subHeading} style={{ fontWeight: "bold" }}><Link to={`/profile/provider/${data.first_name}`}>{data.first_name} {data.last_name}</Link></p>
                            <p className={classes.sub}>{data.companyName}</p>
                            <p className={classes.sub}>{data.servicesOffered[0].address}</p>
                            <p className={classes.sub}>{data.servicesOffered[0].phoneNumber}</p>
                            <div className={classes.button}>
                                <p style={{ margin: "0px 0px" }} onClick={() => history.push(`/contact/${data.first_name}`)}>Edit Details</p>
                            </div>
                            <hr className={classes.divider} />
                            <p className={classes.subHeading}>Additional Details</p>
                            <p className={classes.sub}>Experience: {data.servicesOffered[0].numberYearService} years</p>
                            <p className={classes.sub}>Qualification: {data.servicesOffered[0].qualification}</p>
                            {/* <p className={classes.sub}>Service Type: {data.serviceType}</p> */}
                            <hr className={classes.divider} />
                        </div>
                        <div className={classes.center}>
                            <h1 className={classes.heading}>
                                Your Services
                            </h1>
                            <p className={`${classes.alignLeft} ${classes.sub}`}>Total: {data.servicesOffered.length} services</p>
                            {services.map((service: any, index: any) => {
                                return (
                                    <div className={classes.serviceWrapper} key={index}>
                                        <h3 className={classes.subHeading}>{index + 1}. {service.serviceTitle}</h3>
                                        <p className={`${classes.alignLeft} ${classes.sub}`}>{service.serviceDescription}</p>
                                        <p className={`${classes.alignLeft} ${classes.sub}`}><b>Rate:</b> {service.servicePrice} CAD / hour</p>
                                        <p className={`${classes.alignLeft} ${classes.sub}`}><b>Mode of delivery:</b> {service.serviceMode}</p><br />
                                        <h3 className={classes.subHeading}>Orders for this service</h3>
                                        <p className={`${classes.alignLeft} ${classes.sub}`}>{service.orders.length === 0 ? "No orders yet" : `Total: ${service.orders.length} order(s)`}</p>
                                        {service.orders.map((order: any, index: any) => {
                                            return (
                                                <div className={classes.orderWrapper} key={index}>
                                                    <h3 className={classes.orderHeading}>Client Details</h3>
                                                    <p className={classes.orderText}>Name: {order.orderedBy}</p>
                                                    <p className={classes.orderText}>Phone Number: {order.clientPhone}</p>
                                                    <p className={classes.orderText}>E-mail: {order.clientEmail}</p>
                                                    <p className={classes.orderText}>Address: {order.clientAddress}</p>
                                                    <h3 className={classes.orderHeading}>Order Details</h3>
                                                    <p className={classes.orderText}>Preferred Date: {order.selectedDate}</p>
                                                    <p className={classes.orderText}>Preferred Time Slot(s): {order.preferredTime.map((time:any) => {
                                                        return <span>{time} </span>
                                                    })}</p>
                                                    <p className={classes.orderText}>Preferred Mode of Delivery: {order.preferredMode}</p>
                                                    <p className={classes.orderText}>Sample Class Requested: {order.sampleClassRequested}</p>
                                                    <p className={classes.orderText}>Order ID: {order.orderId}</p>
                                                    <p className={classes.orderText}>Order Status: {order.orderAccepted}</p>
                                                    {order.orderAccepted !== "Accepted" && order.orderAccepted !== "Declined" && <div className={classes.decisionWrapper}>
                                                        <p className={classes.orderText}>Please accept or reject this order</p>
                                                        <div className={classes.buttonWrapper}>
                                                            <div className={classes.acceptButton} onClick={() => SubmitDecision(order.orderId, service._id, "Accepted")}>
                                                                <MdDone />
                                                                <p>Accept</p>
                                                            </div>
                                                            <div className={classes.rejectButton} onClick={() => SubmitDecision(order.orderId, service._id, "Declined")}>
                                                                <VscError />
                                                                <p>Reject</p>
                                                            </div>
                                                        </div>

                                                    </div>}
                                                    {order.orderAccepted === "Accepted" &&
                                                        <Button onClick={() => history.push(`/provider/orders/prePaymentConfirmation/${order.clientId}/${order.orderId}`)} variant="contained" size="large" className={classes.paymentButton}>Check payment status</Button>
                                                    }
                                                </div>
                                            )
                                        })}

                                        <hr className={classes.divider} />
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div className={classes.right}>
                            <h3 className={classes.heading} style={{ textAlign: 'center', marginTop: "20px", fontSize: '2rem' }}>Utilities</h3>
                            <div className={classes.similarService} onClick={() => history.push("/")}>
                                <p className={classes.subHeading}>Add a New Service</p>
                                <p className={`${classes.sub} ${classes.alignLeft}`}>Add more services to improve your profile</p>
                            </div>
                            <div className={classes.similarService} onClick={() => history.push(`/contact/prosChat/${sessionStorage.getItem("name")}`)}>
                                <p className={classes.subHeading}>Messages</p>
                                <p className={`${classes.sub} ${classes.alignLeft}`}>Read all the latest messages in your inbox</p>
                            </div>
                            <div className={classes.similarService} onClick={() => history.push(`/profile/provider/${sessionStorage.getItem("name")}`)}>
                                <p className={classes.subHeading}>View your Public Profile</p>
                                <p className={`${classes.sub} ${classes.alignLeft}`}>Check out what your profile looks like to clients and other providers</p>
                            </div>
                            <div className={classes.similarService} onClick={() => history.push(`/contact/prosChat/${sessionStorage.getItem("name")}`)}>
                                <p className={classes.subHeading}>Feedback</p>
                                <p className={`${classes.sub} ${classes.alignLeft}`}>Take a look at the feedback you have received</p>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}