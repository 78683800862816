import Cleaning from "./dashboard-images/cleaning.png";
import Moving from "./dashboard-images/House-moving.jpeg";
import Repair from "./dashboard-images/Repair.jpeg";
import Cooking from "./dashboard-images/Cooking.jpeg";
import Childcare from "./dashboard-images/Childcare.jpeg";
import Landscaping from "./dashboard-images/Landscaping.jpeg";
import { ServiceTypeList } from "../ProEditPostInfo/ProEditPostInfoFields";

interface ICard {
    serviceImageSrc: string;
    serviceType: string;
    servicePrice: string;
    serviceDescription: string;
}

export const cards: any[] = [
    // 1
    {
        serviceImageSrc: Cleaning,
        serviceType: ServiceTypeList.HouseholdCleaning,
        servicePrice: "Start From $20/hr",
        serviceDescription:
            "Housekeeping cleaning service, professional carpet washing, range hood, refrigerator...",
    },
    // 2
    {
        serviceImageSrc: Moving,
        serviceType: ServiceTypeList.HouseMoving,
        servicePrice: "Start From $35/hr",
        serviceDescription:
            "Move a house, relocate buildings or do the heavy lifting of...",
    },
    // 3
    {
        serviceImageSrc: Repair,
        serviceType: ServiceTypeList.RepairServices,
        servicePrice: "Start From $30/hr",
        serviceDescription:
            "Plumbing & Drain Service, Electrical, Other/General...",
    },
    // 4
    {
        serviceImageSrc: Cooking,
        serviceType: ServiceTypeList.Cooking,
        servicePrice: "Start From $80/hr",
        serviceDescription: "Cooking and cleaning, shopping for vegetables...",
    },
    // 5
    {
        serviceImageSrc: Childcare,
        serviceType: ServiceTypeList.Childcare,
        servicePrice: "Start From $50/hr",
        serviceDescription:
            "The patient aunt helps to watch the baby & drive children to and from school...",
    },
    // 6
    {
        serviceImageSrc: Landscaping,
        serviceType: ServiceTypeList.LandscapingServices,
        servicePrice: "Start From $40/hr",
        serviceDescription:
            "Consistent, Reliable, High-quality landscape professionals can help with all of your landscaping needs...",
    },
];

export const images = [
    {
        label: "",
        imgPath: Cleaning,
    },
    {
        label: "",
        imgPath: Moving,
    },
    {
        label: "",
        imgPath: Repair,
    },
    {
        label: "",
        imgPath: Cooking,
    },
    {
        label: "",
        imgPath: Childcare,
    },
    {
        label: "",
        imgPath: Landscaping,
    },
];
