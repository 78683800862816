import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {useStyles} from "./Pay.style";
import AppBarTopNoUser from "../../components/AppBarTop/AppBarTopNoUser";
import {useHistory, useParams} from "react-router-dom";

const Pay = () => {
    const styles = useStyles();
    const history = useHistory();
    const { orderId } = useParams<{ orderId: string }>();
    // const price
    console.log(orderId);
    const initialOptions = {
        "client-id": "test",
        currency: "CAD",
        intent: "capture",
        // "data-client-token": "abc123xyz==", // TODO: Change this later
    }

    return(
        <div>
            <AppBarTopNoUser/>

            <div className={styles.container}>
                <p className={styles.purchaseTitle}>Purchase Service</p>
            </div>

            <div className={styles.container}>
                <p className={styles.purchaseAmount}>Amount Due: CAD 2</p>
            </div>

            <div className={styles.container}>
                <PayPalScriptProvider options= {initialOptions}>
                    <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            value: "2.00",
                                        },
                                    },
                                ],
                            })
                        }}
                    />
                </PayPalScriptProvider>
            </div>
        </div>
    )
}

export default Pay
