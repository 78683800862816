import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        height: "calc(100vh - 140px)",
        overflowX: "hidden",
        display: 'flex',
        fontFamily: "Lato"
    },
    wrapper: {
        width: "100%",
        margin: "0 auto",
        minHeight: "300px",
        padding: '20px 20px'
    },
    heading: {
        fontSize: "2.5rem",
        color: "#1e2882"
    },
    subHeading: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        marginTop: '5px',
        marginBottom: "0px",
        color: "#1e2882"
    },
    sub: {
        fontSize: "1.3rem",
        textAlign: "center",
        marginTop: '5px',
        marginBottom: "0px",
        color: '#6e6e6e'
    },
    left: {
        width: "20%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: "10px 10px",
        minHeight: '100px',
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    center: {
        width: "60%",
        display: 'flex',
        minHeight: "100px",
        flexDirection: 'column',
        padding: "20px 20px",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    right: {
        width: "20%",
        height: "100%",
        minHeight: "100px",
        display: 'flex',
        flexDirection: 'column',
    },
    profileImage: {
        width: "150px",
        borderRadius: "50%",
        border: "3px solid #e0e0e0",
    },
    divider: {
        width: "100%",
        border: "0.2px solid #e0e0e0",
    },
    button: {
        width: "80%",
        marginTop: "10px",
        padding: "10px 10px",
        borderRadius: "30px",
        backgroundColor: "#ed9f21",
        color: "#fff",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        fontSize: '1.2rem',
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        transition: "0.2s all ease",
        '&:hover': {
            backgroundColor: "#d9901c",
            color: "#fff",
            cursor: "pointer"
        }
    },
    icon: {
        marginRight: "20px"
    },
    alignLeft: {
        textAlign: "left"
    },
    slotContainer: {
        display: "flex",
        flexDirection: "column",
        width: '70%',
        margin: '10px auto'
    },
    slot: {
        borderRadius: '10px',
        border: '1px solid #e0e0e0',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "5px 0px",
        backgroundColor: "#ed9f21",
        color: "#fff",
        fontSize: '1.2rem'
    },
    purchaseButton: {
        width: "30%",
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: "#e81752",
        padding: '10px 0px',
        borderRadius: "30px",
        fontSize: '1.2rem',
        color: "#fff",
        margin: "0 auto",
        cursor: 'pointer',
        border: '3px solid transparent',
        transition: "0.2s all ease",
        '&:hover': {
            backgroundColor: "#a8133d"
        }
    },
    similarService: {
        width: "100%",
        padding: "5px 5px",
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#f0f0f0",
        marginTop: "10px",
        cursor: 'pointer',
        transition: "0.2s all ease",
        "&:hover": {
            backgroundColor: "#e0e0e0"
        }
    },
    serviceWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: "20px"
    },
    orderWrapper: {
        backgroundColor: "#f0f0f0",
        width: "100%",
        padding: "10px 10px",
        margin: "10px auto",
        display: "flex",
        flexDirection: "column",
    },
    orderHeading: {
        fontSize: "1.4rem",
        margin: "5px 0px",
        color: "#1e2882",
        textDecoration: "underline"
    },
    orderText: {
        fontSize: "1.3rem",
        margin: "2px 0px",
        color: "#3b3b3b"
    },
    decisionWrapper: {
        marginTop: "5px",
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonWrapper: {
        display: 'flex',
        marginTop: "10px"
    },
    acceptButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "0px 15px",
        backgroundColor: "#06c458",
        color: "#fff",
        fontSize: '1.2rem',
        borderRadius: "5px",
        marginRight: "10px",
        cursor: 'pointer',
        transition: "0.2s all ease",
        '&:hover': {
            backgroundColor: "#05b357"
        }
    },
    rejectButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "0px 15px",
        backgroundColor: "#a8030c",
        color: "#fff",
        fontSize: '1.2rem',
        borderRadius: "5px",
        marginRight: "10px",
        cursor: 'pointer',
        transition: "0.2s all ease",
        "&:hover": {
            backgroundColor: "#8c0a0a"
        }
    },
    starWrapper: {
        display: "flex",
        alignItems: "center",
    },
    starContainer: {
        display: "flex",
        marginLeft: "10px",
    },
    star: {
        color: "#ed9f21",
        fontSize: "1.3rem",
        marginTop: "5px"
    },
    overallRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    review: {
        width: "100%",
        padding: '5px 5px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#f0f0f0",
        alignItems: 'flex-start',
        marginTop: "10px",
    },
    reviewMessage: {
        width: "100%",
        color: "#6e6e6e",
        fontSize: "1.2rem",
        marginTop: "5px",
        marginRight: "0px",
        marginBottom: "0px"
    },
    
}))