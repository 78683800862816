import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: "center",
    },
    body: {
        fontFamily: "Josefin Sans",
    },
    house: {
        width: "100%",
        height: "85vh",
        margin: "auto",
    },

    timeImage: {
        width: "70vh",
        height: "45vh",
        margin: "40px",
    },
    header: {
        backgroundColor: "white",
        /* min-height: 100vh; */
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "calc(10px + 2vmin)",
        color: "black",
    },
    logo: {
        display: "block",
        width: "100%",
    },
    topLeft: {
        position: "absolute",
        top: "8px",
        left: "16px",
    },

    logoService: {
        width: "70%",
        height: "40vh",
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardMedia: {
        paddingTop: "56.25%",
    },
    cardContent: {
        flexGrow: 1,
    },
    buttonInCard: {
        margin: 25,
        borderRadius: 20,
        width: "200px",
        height: "200px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    centerButton: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    buttonGroup: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    learnMoreButton: {
        color: "black",
        backgroundColor: "#cee0f3",
    },
    label: {
        // Aligns the content of the button vertically.
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 50,
    },
    timeImageAndTxt: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    newsSection: {
        // Other styles
        display: "flex",
        flexDirection: "row",
    },
    categoryDiv: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    joinPart: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));
