import { API_URL } from "./endpoints";

export const getAuth = async (endpoint: string, token: string) => {
    const init: RequestInit = {
        method: "GET",
        headers: {
            authorization: token,
            Accept: "application/json",
        },
    };

    try {
        const response = await fetch(API_URL + endpoint, init);
        if (!response.ok) {
            throw new Error(
                `Request token failure: request failed with HTTP status ${response.status}.`
            );
        }
        const json = await response.json();
        return json;
    } catch (e) {
        console.error(e);
    }
};

export const postAuth = async (endpoint: string, token: string) => {
    const init: RequestInit = {
        method: "POST",
        headers: {
            authorization: token,
            Accept: "application/json",
        },
    };

    try {
        const response = await fetch(API_URL + endpoint, init);
        if (!response.ok) {
            throw new Error(
                `Request token failure: request failed with HTTP status ${response.status}.`
            );
        }
        const json = await response.json();
        return json;
    } catch (e) {
        console.error(e);
    }
};

export const postAuthBody = async (
    endpoint: string,
    token: string,
    reqBody: any
) => {
    const init: RequestInit = {
        method: "POST",
        headers: {
            authorization: token,
            Accept: "application/json",
        },
        body: reqBody,
    };

    try {
        const response = await fetch(API_URL + endpoint, init);
        if (!response.ok) {
            throw new Error(
                `Request token failure: request failed with HTTP status ${response.status}.`
            );
        }
        const json = await response.json();
        return json;
    } catch (e) {
        console.error(e);
    }
};
