import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from "./LandboardWhyUs.styles";

const LandboardWhyUs = (props: any) => {
    const styles = useStyles();
    return (
        <div className={styles.whyUs}>
            
            <h2 className={styles.whyUsTitle}>
                Why do so many of families trust Van Service?
            </h2>

            <div className={styles.whyUsItems}>

                <div className={styles.whyUsItem}>
                    <div className={styles.whyUsBullet}>
                        <img src={"https://useprd-cdn-w.care.com/assets/img/welcomePage/check-icon-3e820387c8b6fadf65713fed7513894a.svg"}
                           width="13" height="10" aspect-ratio="auto 13 / 10" />
                    </div>
                    <div className={styles.whyUsText}>
                        
                        More than one million service providers worldwide are already signed up
                
                    </div>
                </div>

                <div className={styles.whyUsItem}>
                    <div className={styles.whyUsBullet}>
                        <img src={"https://useprd-cdn-w.care.com/assets/img/welcomePage/check-icon-3e820387c8b6fadf65713fed7513894a.svg"}
                           width="13" height="10" aspect-ratio="auto 13 / 10"/>
                    </div>
                    <div className={styles.whyUsText}>
                        
                        From babysitters to dog walkers: just one account for all your care needs
                
                    </div>
                </div>

                <div className={styles.whyUsItem}>
                    <div className={styles.whyUsBullet}>
                        <img src={"https://useprd-cdn-w.care.com/assets/img/welcomePage/check-icon-3e820387c8b6fadf65713fed7513894a.svg"}
                           width="13" height="10" aspect-ratio="auto 13 / 10"/>
                    </div>
                    <div className={styles.whyUsText}>

                        Whatever your requirements, find someone who meets them
                
                    </div>
                </div>

            </div>
        
        </div>
    );
};

export default LandboardWhyUs;
