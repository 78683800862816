import { FormikHelpers } from "formik";
import history from "../../util/history";
import { apiFetch, Endpoint } from "../../util/endpoints";
import { IUser } from "../../util/users";
import { TNewUserValues } from "./fields";
import axios from "axios";

export const addUser = async (userInfo: string) => {
    console.log(userInfo);
    const init: RequestInit = {
        method: "POST",
        body: userInfo,
    };
    // return await apiFetch(Endpoint.REGISTER, "", init)
    //     .then((res) => {
    //         return res.json();
    //     })
    //     .then((res) => {
    //         return res as IUser;
    //     });
    return axios.post("https://helpercloud.herokuapp.com/api/users/register", JSON.parse(userInfo))
    .then(res => {
        return res.data;
    })
};

export const handleNewSubmit = async (
    values: TNewUserValues,
    helpers: FormikHelpers<TNewUserValues>
) => {
    const newUser = JSON.stringify({
        username: values.username,
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
        phoneNumber: values.phoneNumber,
        email: values.email,
        user_role: values.role,
        address: values.address,
        gender: values.gender,
        province: values.province,
    });
    try {
        const user = await addUser(newUser);
        if (user) {
            alert(`Please check your email to finish the register!!`);
            history.goBack();
        }
    } catch (e) {
        alert(
            `Either a user with that email already exists or that password is too weak. Please try again.`
        );
        helpers.setSubmitting(false);
    }
};
