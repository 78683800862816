import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { ReactComponent as Logo } from "../../images/logo.svg";

export const useStyles = makeStyles((theme) => ({
    bar: {
        backgroundColor: "#ff9800",
    },

    logoButton: {
        fontFamily: "Myriad Pro Regular",
        color: "white",
        minWidth: "200px",
    },
}));

const AppBarTopNoUser = () => {
    const styles = useStyles();
    const history = useHistory();
    const handleClickLogo = () => {
        return history.push(`/`);
    };

    return (
        <AppBar position="relative" className={styles.bar}>
            <Toolbar>
                <Button onClick={handleClickLogo} className={styles.logoButton}>
                    <Logo />
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default AppBarTopNoUser;
