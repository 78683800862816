import * as Yup from "yup";
import { Validation } from "../../util/validations";

export enum ReserveField {
    orderId = "orderId",
    first_name = "first_name",
    last_name = "last_name",
    address = "address",
    phoneNumber = "phoneNumber",
    zipcode = "zipcode",
    cleaningDate = "cleaningDate",
    timeLine = "timeLine",
}

export const fieldLabels = {
    [ReserveField.first_name]: "First Name",
    [ReserveField.last_name]: "Last Name",
    [ReserveField.address]: "Address",
    [ReserveField.zipcode]: "Zipcode",
    [ReserveField.phoneNumber]: "Phone Number",
    [ReserveField.cleaningDate]: "Cleaning Date",
    [ReserveField.timeLine]: "Time Line",
    // [ReserveField.timeLine]: [
    //     "Time9",
    //     "Time10",
    //     "Time11",
    //     "Time12",
    //     "Time13",
    //     "Time14",
    //     "Time15",
    //     "Time16",
    //     "Time17",
    // ],
};

export const initialValues = {
    [ReserveField.first_name]: "",
    [ReserveField.last_name]: "",
    [ReserveField.address]: "",
    [ReserveField.zipcode]: "",
    [ReserveField.phoneNumber]: "",
    [ReserveField.cleaningDate]: "",
    // @ts-ignore
    [ReserveField.timeLine]: [],
};

export type TNewUserValues = typeof initialValues;

const infoValidationShape = {
    [ReserveField.first_name]: Yup.string()
        .label(fieldLabels[ReserveField.first_name])
        .required()
        .max(50),
    [ReserveField.last_name]: Yup.string()
        .label(fieldLabels[ReserveField.last_name])
        .required()
        .max(50),

    [ReserveField.phoneNumber]: Yup.string()
        .matches(Validation.phoneRegExp, "Invalid phone number")
        .label(fieldLabels[ReserveField.phoneNumber])
        .max(50),
    [ReserveField.address]: Yup.string()
        .label(fieldLabels[ReserveField.address])
        .max(50),
    [ReserveField.zipcode]: Yup.string()
        .matches(Validation.zipcodeRegExp, "Invalid zip code")
        .label(fieldLabels[ReserveField.zipcode])
        .max(50),
    [ReserveField.timeLine]: Yup.array()
        // @ts-ignore
        .label(fieldLabels[ReserveField.timeLine])
        .required()
        .min(1, "Please select at least one timeline")
        .test("arraytest", "Invalid TimeLine", (value) => {
            let copy: number[] = [];
            // let preNum = 0;
            // let nextNum = 0;
            if (value.length > 1) {
                value.forEach((v) => {
                    copy.push(parseInt(v));
                });
                // copy = value.concat();
                copy.sort((a, b) => {
                    return a - b;
                });
                for (let i = 0; i < value.length - 1; i++) {
                    if (copy[i + 1] !== copy[i] + 1) {
                        return false;
                    }
                }
            }
            return true;
        }),
};

export const editValidationSchema = Yup.object().shape(infoValidationShape);
