import AppBarTop from "../../components/AppBarTop/AppBarTop";
import { useEffect, useState } from "react";
import { GetClientOrders } from "./handler";
import { useStyles } from "./ClientViewOrders.style"
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export const ClientViewOrders = () => {
    const userId = sessionStorage.getItem("userId");
    const classes = useStyles();
    const [orders, setOrders] = useState([]);
    const history = useHistory();
    useEffect(() => {
        GetClientOrders(userId)
            .then(res => {
                console.log(res.data);
                setOrders(res.data);
            })
    }, [])
    return (
        <>
            <AppBarTop />
            <div className={classes.container}>
                <h1 className={classes.heading}>Your Orders</h1>

                {orders.map(order => {
                    return (
                        <div className={classes.orderWrapper} key={order.orderId}>
                            <h2 className={classes.subHeading}>{order.serviceTitle}</h2>
                            <h3 className={classes.orderHeading}>Provider Details</h3>
                            <p className={classes.orderText}>Name: {order.serviceProvider}</p>
                            <p className={classes.orderText}>Phone Number: {order.providerPhone}</p>

                            <h3 className={classes.orderHeading}>Order Details</h3>
                            <p className={classes.orderText}>Preferred Time Slot: {order.preferredTime}</p>
                            <p className={classes.orderText}>Preferred Mode of Delivery: {order.preferredMode}</p>
                            <p className={classes.orderText}>Sample Class Requested: {order.sampleClassRequested}</p>
                            <p className={classes.orderText}>Order ID: {order.orderId}</p>
                            <p className={classes.orderText}>Order Status: {order.orderAccepted}</p>
                            <p className={classes.orderText}>Payment: {order.paymentDone}</p>
                            <div className={classes.buttonWrapper}>
                                <Button onClick={() => history.push(`/ServiceDetailTutoring/${order.serviceId}`)} variant="contained" color="secondary" size="large" style={{ width: "200px", marginTop: "10px" }}>View more details</Button>
                                <Button onClick={() => history.push(`/client/orders/prePaymentConfirmation/${order.orderId}`)} variant="contained" size="large" className={classes.paymentButton}>Pay now</Button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}