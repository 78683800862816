import { useStyles } from "./ServiceListNew.style";
import AppBarTop from "../../components/AppBarTop/AppBarTop";

import ServiceCategory from "../../components/ServiceCategory/ServiceCategory";
import { useEffect, useRef, useState } from "react";
import { IService } from "../../util/service";
import { getToken } from "../../util/cookie";
import { Endpoint } from "../../util/endpoints";
import { getAuth } from "../../util/auth";
import { useHistory, useLocation } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { IUser } from "../../util/users";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Childcare from "../Landboard/dashboard-images/Childcare.jpeg";
import { StickyContainer, Sticky } from "react-sticky";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ChildFriendlyIcon from "@material-ui/icons/ChildFriendly";
import SchoolIcon from "@material-ui/icons/School";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import WeekendIcon from "@material-ui/icons/Weekend";
import AccessibleIcon from "@material-ui/icons/Accessible";
import PetsIcon from "@material-ui/icons/Pets";
import AirlineSeatIndividualSuiteIcon from "@material-ui/icons/AirlineSeatIndividualSuite";
import WcIcon from "@material-ui/icons/Wc";
import OpacityIcon from "@material-ui/icons/Opacity";
import Banner from "../../components/Banner/Banner";


interface IProps {
    serviceId: string;
    serviceOwner: string;
    serviceTitle: string;
    servicePrice?: number;
    serviceType: string;
    info: string;
    phoneNum: string;
    language: string;
    location: string;
    details: string;
    rate?: number;
    user: IUser;
    feedbackRate: number;
    serviceRate: number;
    feedbackCounter: number;
    numberOfClientLike: number;
}

const ServiceListNew = () => {
    const [open, setOpen] = useState(true);
    const styles = useStyles();

    const onClose = () => {
        setOpen(false);
        setLoadingError(false);
    };

    const history = useHistory();

    const handleNotLogin = () => {
        return history.push("/Login");
    };

    const location = useLocation();
    const [service, setService] = useState<IProps[]>();
    // const [feedbackAvgRateArray, setFeedbackAvgRateArray] =
    //     useState<number[]>();
    // const [user, setUser] = useState<IUser>();
    const [loadingError, setLoadingError] = useState(false);
    const fillFeedbackAvgRate = async (theService: any, token: string) => {
        let user_ = [];
        theService.forEach(async (service_: any) => {
            const apiUserString = Endpoint.CLIENT + "id/" + service_.owner;
            const user = await getAuth(apiUserString, token);
            user_.push(user.feedbackAvgRate);
            // setFeedbackAvgRateArray(user_);
        });
    };
    const pageTopRef = useRef(null);
    const sectionEventInfoRef = useRef(null);
    const handleClickNavItem = (ref: {
        current: { scrollIntoView: (arg0: { behavior: string }) => void };
    }) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    useEffect(() => {
        const apiServiceString =
            location.search === ""
                ? Endpoint.SERVICE
                : Endpoint.TYPE_SERVICE + location.search.replace("?", "");
        const getService = async () => {
            try {
                const token: string = getToken();

                // Handle case where user not login
                if (!token) {
                    throw Error();
                }

                const theService = await getAuth(apiServiceString, token);
                const theUser: IUser = (await getAuth(
                    Endpoint.PROFILE,
                    token
                )) as IUser;
                // setUser(theUser);
                fillFeedbackAvgRate(theService, token);
                const service = theService.map((data: IService) => {
                    return {
                        serviceId: data._id,
                        serviceOwner: data.owner,
                        servicePostedDate: data.createdAt,
                        serviceTitle: data.serviceTitle,
                        serviceType: data.serviceType,
                        servicePrice: data.servicePrice,
                        info: data.serviceDescription,
                        language: data.serviceLanguage,
                        feedbackRate: data.serviceFeedbackAvgRate.toFixed(1),
                        feedbackCounter: data.serviceFeedbackCounter,
                        numberOfClientLike: data.numberOfClientLike,
                    };
                });
                setService(service);
            } catch (e) {
                setLoadingError(true);
            }
        };
        getService();
    }, []);

    return loadingError ? (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitle>
                <b>Please login or register first</b>
            </DialogTitle>
            <DialogContent>
                <b>
                    Get personalized recommendations after you login into van
                    service
                </b>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleNotLogin();
                    }}
                    color="primary"
                    variant="outlined"
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    ) : service ? (
        <div>
            <AppBarTop />
            <div className={styles.banner}>
                <Banner />
            </div>
            <div className={styles.center}>
                <Button onClick={handleClickNavItem.bind(null, pageTopRef)}>
                    <Typography variant="h3">See ALL Service</Typography>
                </Button>
            </div>

            {/* <button onClick={openEWorks}>精选作品</button> */}
            <ServiceCategory />
            <div ref={pageTopRef}></div>
            <div className={styles.listDivStyle}>
                <StickyContainer className={styles.stickyMenu}>
                    <Sticky>
                        {({ style }) => (
                            <p style={style}>
                                <Paper className={styles.menu}>
                                    <MenuList>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <ChildFriendlyIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">
                                                Child Care
                                            </Typography>
                                        </MenuItem>

                                        <MenuItem>
                                            <ListItemIcon>
                                                <SchoolIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">
                                                School Support
                                            </Typography>
                                        </MenuItem>

                                        <MenuItem>
                                            <ListItemIcon>
                                                <SupervisedUserCircleIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography
                                                variant="inherit"
                                                noWrap
                                            >
                                                Senior Care
                                            </Typography>
                                        </MenuItem>

                                        <MenuItem>
                                            <ListItemIcon>
                                                <WeekendIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography
                                                variant="inherit"
                                                noWrap
                                            >
                                                House cleaning
                                            </Typography>
                                        </MenuItem>

                                        <MenuItem>
                                            <ListItemIcon>
                                                <AccessibleIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography
                                                variant="inherit"
                                                noWrap
                                            >
                                                Special Need
                                            </Typography>
                                        </MenuItem>

                                        <MenuItem>
                                            <ListItemIcon>
                                                <PetsIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography
                                                variant="inherit"
                                                noWrap
                                            >
                                                Pet Care
                                            </Typography>
                                        </MenuItem>

                                        <MenuItem>
                                            <ListItemIcon>
                                                <AirlineSeatIndividualSuiteIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography
                                                variant="inherit"
                                                noWrap
                                            >
                                                Bed Assembly
                                            </Typography>
                                        </MenuItem>

                                        <MenuItem>
                                            <ListItemIcon>
                                                <WcIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography
                                                variant="inherit"
                                                noWrap
                                            >
                                                Toilet Trouble
                                            </Typography>
                                        </MenuItem>

                                        <MenuItem>
                                            <ListItemIcon>
                                                <OpacityIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography
                                                variant="inherit"
                                                noWrap
                                            >
                                                Plumbing Service
                                            </Typography>
                                        </MenuItem>
                                    </MenuList>
                                </Paper>
                                {/* <ServiceListBar /> */}
                            </p>
                        )}
                    </Sticky>
                </StickyContainer>

                <Container className={styles.cardGrid} maxWidth="md">
                    <Grid container spacing={4}>
                        {service.map((item) => (
                            <Grid
                                item
                                key={item.serviceId}
                                xs={12}
                                sm={6}
                                md={4}
                            >
                                <Card className={styles.card}>
                                    <CardMedia
                                        className={styles.cardMedia}
                                        image={Childcare}
                                    />
                                    <CardContent className={styles.cardContent}>
                                        <Typography
                                            variant="h5"
                                            className={styles.boldFont}
                                        >
                                            {item.serviceTitle}
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                        >
                                            Service Type:{item.serviceType}
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                        >
                                            Service Price:{item.servicePrice}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={() =>
                                                history.push(
                                                    `/ServiceDetail/${item.serviceId}`
                                                )
                                            }
                                        >
                                            View
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
        </div>
    ) : (
        <Skeleton variant="rect" height={500} />
    );
};

export default ServiceListNew;
