import * as Yup from "yup";
import { Validation } from "../../util/validations";

export enum FormField {
    userId = "client_id",
    timeSchedule = "time_schedule",
    entity = "entity",
    serviceTitle = "serviceTitle",
    serviceType = "serviceType",
    language = "language",
    serviceDescription = "serviceDescription",
    price = "price",
    numberHourWorking = "numberHourWorking",
    timeRange = "rate_level",
    homeSituation = "home_situation",
    numberBedRoom = "num_bedroom",
    numberYearService = "num_washroom",
    startDate = "startDate",
    endDate = "endDate",

    cleaningFocus = "cleaning_focus",
    isSmoker = "isSmoker",
    haveCar = "haveCar",
    petAvail = "petAvail",
    focusCarpetShampooing = "focus_carpet_shampooing",
    focusFurnitureTreatment = "focus_furniture_treatment",
    focusLaunary = "focus_lanuary",
    cleaningTime = "cleaning_time",

    timeRange9 = "time_range_9",
    timeRange10 = "time_range_10",
    timeRange11 = "time_range_11",
    timeRange12 = "time_range_12",
    timeRange13 = "time_range_13",
    timeRange14 = "time_range_14",
    timeRange15 = "time_range_15",
    timeRange16 = "time_range_16",
    timeRange17 = "time_range_17",

    timeRangeAble9 = "time_range_able_9",
    timeRangeAble10 = "time_range_able_10",
    timeRangeAble11 = "time_range_able_11",
    timeRangeAble12 = "time_range_able_12",
    timeRangeAble13 = "time_range_able_13",
    timeRangeAble14 = "time_range_able_14",
    timeRangeAble15 = "time_range_able_15",
    timeRangeAble16 = "time_range_able_16",
    timeRangeAble17 = "time_range_able_17",
    timeList = "time_list",

    keeperBring = "keeper_bring",
    currentAddress = "current_address",
    address = "address",
    zipcode = "zipcode",

    companyName = "company_name",
    personal = "personal",
    firstName = "first_name",
    lastName = "last_name",

    contactInfo = "contactInfo",
    phoneNumber = "phone_number",
    area = "area",
    identity = "identity",
    supplies = "supplies",
}

export const fieldLabels = {
    [FormField.userId]: "Client",
    [FormField.timeSchedule]: "Time Schedule",
    [FormField.timeRange]: "Time Range",
    [FormField.entity]: "Entity",
    [FormField.serviceTitle]: "Service Title",
    [FormField.serviceType]: "Service Type",
    [FormField.language]: "Language",
    [FormField.serviceDescription]: "Service Description",

    [FormField.homeSituation]: "Basic Service situation",
    [FormField.numberYearService]: "Number of Year Service",
    [FormField.numberHourWorking]: "Number of Hour working",

    // [FormField.numberBedRoom]: "Number of bedrooms",
    [FormField.supplies]: "Supplies",
    [FormField.identity]: "Are you an individual or from a company",

    [FormField.cleaningFocus]: "What part of cleaning would you focus on",
    [FormField.isSmoker]: "Non-smoker",
    [FormField.haveCar]: "Have a car",
    [FormField.petAvail]: "Comfortable with pets",

    [FormField.cleaningTime]: "When would you have the service?",
    [FormField.timeRange9]: "9:00-10:00",
    [FormField.timeRange10]: "10:00-11:00",
    [FormField.timeRange11]: "11:00-12:00",
    [FormField.timeRange12]: "12:00-13:00",
    [FormField.timeRange13]: "13:00-14:00",
    [FormField.timeRange14]: "14:00-15:00",
    [FormField.timeRange15]: "15:00-16:00",
    [FormField.timeRange16]: "16:00-17:00",
    [FormField.timeRange17]: "17:00-18:00",

    [FormField.timeList]: "timeList",
    [FormField.keeperBring]: "What should your housekeeper bring?",
    [FormField.currentAddress]: "What is your address",
    [FormField.address]: "Address",
    [FormField.personal]: "Personal Informnation",
    [FormField.zipcode]: "Zip Code",
    [FormField.companyName]: "Company Name",
    [FormField.firstName]: "First Name",
    [FormField.lastName]: "Last Name",
    [FormField.contactInfo]: "Contact Information",
    [FormField.phoneNumber]: "Phone Number",
    [FormField.area]: "Area",
};

export const initialValues = {
    [FormField.userId]: "",
    [FormField.numberBedRoom]: 1,
    [FormField.numberYearService]: 1,
    [FormField.price]: 30,
    [FormField.numberHourWorking]: 1,
    [FormField.area]: 200,
    [FormField.identity]: "",
    [FormField.serviceTitle]: "",
    [FormField.serviceType]: "",
    [FormField.serviceDescription]: "",
    [FormField.language]: [] as String[],

    [FormField.isSmoker]: false,
    [FormField.haveCar]: false,
    [FormField.petAvail]: false,

    [FormField.startDate]: new Date(),
    // [FormField.endDate]: new Date(),

    [FormField.timeRange9]: true,
    [FormField.timeRange10]: true,
    [FormField.timeRange11]: true,
    [FormField.timeRange12]: true,
    [FormField.timeRange13]: true,
    [FormField.timeRange14]: true,
    [FormField.timeRange15]: true,
    [FormField.timeRange16]: true,
    [FormField.timeRange17]: true,

    [FormField.timeRangeAble9]: false,
    [FormField.timeRangeAble10]: false,
    [FormField.timeRangeAble11]: false,
    [FormField.timeRangeAble12]: false,
    [FormField.timeRangeAble13]: false,
    [FormField.timeRangeAble14]: false,
    [FormField.timeRangeAble15]: false,
    [FormField.timeRangeAble16]: false,
    [FormField.timeRangeAble17]: false,

    [FormField.timeList]: [0, 0, 0, 0, 0, 0, 0, 0, 0],

    [FormField.address]: "",
    [FormField.zipcode]: "",

    [FormField.companyName]: "",
    [FormField.contactInfo]: "",
    [FormField.firstName]: "",
    [FormField.lastName]: "",
    [FormField.phoneNumber]: "",
};

export const emptyValidationSchema = () => Yup.object().shape({});

export const TimeValidationSchema = () =>
    Yup.object().shape({
        [FormField.timeRange]: Yup.string().label(
            fieldLabels[FormField.timeRange]
        ),
    });

export const TimeValidationSchemaNew = () =>
    Yup.object().shape({
        [FormField.timeRange]: Yup.array()
            .label(fieldLabels[FormField.timeRange])
            .required(),
    });

export const HomeValidationSchema = () =>
    Yup.object().shape({
        // [FormField.numberBedRoom]: Yup.number()
        //     .label(fieldLabels[FormField.numberBedRoom])
        //     .max(10)
        //     .positive()
        //     .required(),
        [FormField.numberYearService]: Yup.number()
            .label(fieldLabels[FormField.numberYearService])
            .max(10)
            .positive()
            .required(),
        // [FormField.area]: Yup.number()
        //     .label(fieldLabels[FormField.area])
        //     .positive()
        //     .required(),
    });

export const AddressValidationSchema = () =>
    Yup.object().shape({
        [FormField.address]: Yup.string()
            .label(fieldLabels[FormField.address])
            .max(50)
            .required(),

        [FormField.zipcode]: Yup.string()
            .label(fieldLabels[FormField.zipcode])
            .matches(Validation.zipcodeRegExp, "Zip code is not valid."),
    });

export const PersonalValidationSchema = () =>
    Yup.object().shape({
        [FormField.firstName]: Yup.string()
            .label(fieldLabels[FormField.firstName])
            .max(50)
            .required(),
        [FormField.lastName]: Yup.string()
            .label(fieldLabels[FormField.lastName])
            .max(50)
            .required(),
    });

export const ContactValidationSchema = () =>
    Yup.object().shape({
        [FormField.phoneNumber]: Yup.string()
            .label(fieldLabels[FormField.phoneNumber])
            .max(50)
            .matches(Validation.phoneRegExp, "Phone number is not valid."),
    });

export const cleaningFocusValidationSchema = () => Yup.object().shape({});

export type TFormValues = typeof initialValues;
