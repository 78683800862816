import { Button, TextField, useTheme, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { setFlagsFromString } from 'v8';
import AppBarTop from '../../components/AppBarTop/AppBarTop';
import { getToken } from "../../util/cookie";
import { useStyles } from './ProsChat.style';
import io from 'socket.io-client';
// @ts-ignore 
import ScrollToBottom from 'react-scroll-to-bottom';


export const ProsChat = ({ socket }: any) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const location = useLocation();
    const token: string = getToken();
    const [firstName, setFirstName] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [message, setMessage] = React.useState('');
    const [conversations, setConversations] = React.useState([]);
    const [flag, setFlag] = React.useState(false);
    const [currentConversation, setCurrentConversation] = React.useState([]);
    const [currentConversationWith, setCurrentConversationWith] = React.useState('');
    const [counter, setCounter] = React.useState(0);
    const { proName } = useParams<{ proName: string }>();

    useEffect(() => {
        const socketId = currentConversationWith + "-" + sessionStorage.getItem("name");
        socket.emit("join_room", { room: socketId });
        socket.on("new_message", (data: any) => {
            console.log(data);
            setCurrentConversation(data.messages);
            setLoading(false);
        })
        axios.get(`https://helpercloud.herokuapp.com/api/contact/getProviderConversations/${sessionStorage.getItem("name")}`)
            .then(res => {
                setConversations(res.data);
                console.log(currentConversation);
                conversations.forEach(conversation => {
                    if (conversation.with === currentConversationWith) {
                        setCurrentConversation(conversation.messages);
                        return;
                    }
                })
                setLoading(false);
            })

    }, [proName, flag, currentConversationWith, counter, socket])

    const submitReply = async () => {

        console.log("SENDING MESSAGE THROUGH SOCKET")
        const socketId = currentConversationWith + "-" + sessionStorage.getItem("name");
        await socket.emit("send_message", { id: socketId, to: currentConversationWith, by: sessionStorage.getItem("name"), message: message })
        setMessage('');
        const date = new Date();
        const time = date.getHours() + ":" + date.getMinutes();
        setCurrentConversation([...currentConversation, { by: sessionStorage.getItem("name"), message: message, time: time }]);
        setLoading(false);

    }

    const handleChatSelection: any = (convoWith: any) => {
        setCurrentConversationWith(convoWith);
    }

    const handleSubmitThroughEnter: any = (e: any) => {
        if (e.key === 'Enter') {
            submitReply();
        }
    }

    return (
        <>
            <AppBarTop />
            <div className={classes.container}>
                <div className={classes.left}>
                    <p style={{ fontSize: '2rem', marginLeft: '10px', fontWeight: 'bold' }}>Conversations</p>

                    {conversations.map(conversation => {
                        return (

                            <div className={classes.infoWrapper} onClick={() => handleChatSelection(conversation.with)}>
                                <h3 style={{ margin: "0px 0px" }}>{conversation.with}</h3>
                                {conversation.messages[0] ? <p className={classes.peekMessage}>{conversation.messages[conversation.messages.length - 1].message}</p> : <p className={classes.peekMessage}>No messages yet</p>}
                            </div>
                        )
                    })
                    }
                </div>
                {loading ? <div style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></div> :
                    <div className={classes.middle}>


                        <>
                            {currentConversation.length === 0 ?
                                <div style={{ width: "100%", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{ fontSize: '2rem', marginLeft: '10px', fontWeight: 'bold' }}>No conversation yet</p>
                                </div> :
                                <>
                                    <p className={classes.heading}>Conversation with {currentConversationWith}</p>

                                    <ScrollToBottom className={classes.conversationWrapper}>
                                        {currentConversation.map(({ id, by, message, time }) => {
                                            return by === currentConversationWith ? (
                                                <div style={{ alignSelf: 'flex-start', }} className={classes.chatMessage} >
                                                    <p className={classes.chatHeader} style={{ color: "orange" }}>{by} <span style={{ fontWeight: 'normal', color: "#000" }}>{time}</span></p>
                                                    <p className={classes.chatMsg}>{message}</p>
                                                </div>
                                            )
                                                :
                                                (<div style={{ alignSelf: 'flex-end' }} className={classes.chatMessage}>
                                                    <p className={classes.chatHeader}>You <span style={{ fontWeight: 'normal' }}>{time}</span></p>
                                                    <p className={classes.chatMsg}>{message}</p>
                                                </div>)
                                        })
                                        }


                                    </ScrollToBottom>

                                </>
                            }
                        </>





                        <div className={classes.inputBoxWrapper}>
                            <TextField onKeyPress={handleSubmitThroughEnter} value={message} type="text" onChange={e => setMessage(e.target.value)} variant="outlined" placeholder="Your message here..." rows={3} multiline className={classes.inputBox} />
                            <Button variant="outlined" color="secondary" size="large" onClick={submitReply} style={{ marginTop: '30px' }}>Send</Button>
                        </div>


                    </div>
                }
                {/* <div className={classes.right}>
                    <p style={{ fontSize: '2rem' }}>Service Provider Details</p>
                    {loading ? <CircularProgress /> : <div style={{border: '1px solid #ebebeb', padding: '5px 5px'}}>
                        <p className={classes.infoRight}>{firstName} {lastName}</p>
                        <p className={classes.infoRight}>Ph. no: {phoneNumber}</p>
                        <p className={classes.infoRight}>Address: {address}</p>
                        <p className={classes.infoRight}>Province: {province}</p>
                        <p className={classes.infoRight}>Email: {email}</p>
                        <hr className={classes.divider}/>
                        <p style={{ fontSize: '1.2rem' }}>You can contact the service provider using this number: +1 234 56789</p>
                    </div>}
                </div> */}
            </div>
        </>
    )
}
