import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: "40%",
        background: "#f5f5f5",
        minHeight: "84vh",
        margin: "20px auto",
        padding: "30px 30px",
        position: 'relative',
        paddingBottom: "30px"
    },
    heading: {
        fontSize: "2.5rem",
        color: "#435eab",
        fontFamily: "Lato",
        margin: "0px 0px"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "10px auto",
        width: "90%"
    },
    column: {
        display: "flex",
        flexDirection: "column",
        margin: "10px 0px",
        maxWidth: "50%",
        flexWrap: "wrap",
    },
    subHeading: {
        fontSize: "1.8rem",
        color: "#435eab",
        margin: "0px 0px",
        fontFamily: "Lato",
        fontWeight: "bold"
    },
    p: {
        fontSize: "1.5rem",
        color: "#435eab",
        margin: "4px 0px",
        fontFamily: "Lato",
    },
    divider: {
        width: "90%",
        height: "1px",
        backgroundColor: "#e0e0e0",
        borderColor: "#e0e0e0",
        margin: "10px auto"
    },
    blue: {
        color: "#435eab"
    },
    smallDivider: {
        width: "90%",
        borderWidth: "0.2px",
        backgroundColor: "#f0f0f0",
        borderColor: "#f0f0f0",
        marginTop: "-7px"
    },
    star: {
        color: "#828282",
        fontSize: "1rem",
        position: 'absolute',
        bottom: "0px",
        left: '20px'
    },
    paypal: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        margin: "10px 0px",
        justifyContent: "center",
        alignItems: "center",
    }
}))