import { useStyles } from "./ServiceDetail.style";
import StarShow from "../../components/StarShow/StarShow";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { cards, images } from "../Landboard/fields";
import {
    Avatar,
    Box,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MobileStepper,
    Paper,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import DatePicker from "../../components/DatePicker/DatePicker";
import React, { useCallback, useEffect, useState } from "react";
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    MobileFriendly,
} from "@material-ui/icons";
import { getAuth } from "../../util/auth";
import { Endpoint } from "../../util/endpoints";
import { getToken } from "../../util/cookie";
import { IService } from "../../util/service";
import ReviewList from "../../components/ReviewListForm/ReviewListForm";
import { Link, useHistory, useParams } from "react-router-dom";
import { Alert, Autocomplete, Skeleton } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { editValidationSchema as serviceValidationSchema } from "./fields";
import { handleSubmit } from "./formHandler";
import AppBarTop from "../../components/AppBarTop/AppBarTop";
import { IUser } from "../../util/users";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import TimeLine from "../../components/ServiceDetailTimeLine/ServiceDetailTimeLine";

export interface IServiceDetail {
    service: IService;
    serviceOwner: IUser;
}

export interface IReviewer {
    username: string;
    firstName: string;
    lastName: string;
    createdAt: Date;
}

export interface IServiceRating {
    reviewer: IReviewer;
    serviceContent: string;
    onTimeRate: number;
    attitudeRate: number;
    qualityRate: number;
}

interface IServiceRatings {
    ServiceRatings: IServiceRating[];
    serviceCounter: number;
    serviceOTAvgRate: number;
    serviceAAvgRate: number;
    serviceQAvgRate: number;
}

export interface IRouteParams {
    userId: string;
}
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ServiceDetail = () => {
    const styles = useStyles();
    const token: string = getToken();
    const { serviceId } = useParams<{ serviceId: string }>();
    const [service, setService] = useState<IService>();
    const [loadingError, setLoadingError] = useState(false);
    const [serviceOwner, setServiceOwner] = useState<IUser>();
    const [isOrder, setIsOrder] = useState(false);
    const [serviceRatings, setServiceRatings] = useState<IServiceRating[]>();
    const [serviceCounter, setServiceCounter] = useState(0);
    const [serviceOTAvgRate, setServiceOTAvgRate] = useState(0);
    const [serviceAAvgRate, setServiceAAvgRate] = useState(0);
    const [serviceQAvgRate, setServiceQAvgRate] = useState(0);
    const [date, setDate] = useState(new Date());
    const [isSending, setIsSending] = useState(false);
    const [timeList, setTimeList] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [activeStep, setActiveStep] = useState(0);
    // const [orders, setOrders] = useState([]);
    const history = useHistory();
    const theme = useTheme();
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const getServiceDetail = async () => {
        const serviceObj: IServiceDetail = await getAuth(
            Endpoint.DETAIL_SERVICE + serviceId,
            token
        );
        setServiceOwner(serviceObj.serviceOwner);
        setService(serviceObj.service);
    };

    const getServiceRatingsList = async () => {
        const serviceRatingsObj: IServiceRatings = await getAuth(
            Endpoint.SERVICE_RATEING + serviceId,
            token
        );
        setServiceRatings(serviceRatingsObj.ServiceRatings);
        setServiceCounter(serviceRatingsObj.serviceCounter);
        setServiceOTAvgRate(serviceRatingsObj.serviceOTAvgRate);
        setServiceAAvgRate(serviceRatingsObj.serviceAAvgRate);
        setServiceQAvgRate(serviceRatingsObj.serviceQAvgRate);
    };
    const convertDateToString = (date: Date) => {
        return JSON.stringify(date).substring(1, 11);
    };

    const sendRequest = useCallback(async () => {
        // don't send again while we are sending
        if (isSending) return;
        // update state
        setIsSending(true);
        const tempSchema = await getAuth(
            Endpoint.ORDER_TIME + serviceId + "/" + date,
            token
        );

        setTimeList(tempSchema);
        setIsSending(false);
    }, [isSending]);

    const getCurrentTimeList = () => {
        sendRequest();
        setIsOrder(!isOrder);
    };

    useEffect(() => {
        getServiceDetail();
        getServiceRatingsList();
        sendRequest();
    }, []);

    return loadingError ? (
        <Alert severity="error">
            Something went wrong trying to load that user. Please go back and
            try again.
        </Alert>
    ) : service ? (
        <Formik
            initialValues={service}
            validationSchema={serviceValidationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <div>
                    <AppBarTop />
                    <div className={styles.mainDiv}>
                        <div className={styles.leftDiv}>
                            <div className={styles.titleDiv}>
                                <div className={styles.imageContainer}>
                                    <Box sx={{ maxWidth: "auto", flexGrow: 1 }}>
                                        <div className={styles.imageList}>
                                            <AutoPlaySwipeableViews
                                                axis={
                                                    theme.direction === "rtl"
                                                        ? "x-reverse"
                                                        : "x"
                                                }
                                                index={activeStep}
                                                onChangeIndex={handleStepChange}
                                                enableMouseEvents
                                            >
                                                {images.map((step, index) => (
                                                    <div key={step.label}>
                                                        {Math.abs(
                                                            activeStep - index
                                                        ) <= 2 ? (
                                                            <div
                                                                className={
                                                                    styles.imageSection
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        step.imgPath
                                                                    }
                                                                    alt={
                                                                        step.label
                                                                    }
                                                                    className={
                                                                        styles.imageSection
                                                                    }
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </AutoPlaySwipeableViews>
                                        </div>
                                        <MobileStepper
                                            steps={maxSteps}
                                            position="static"
                                            activeStep={activeStep}
                                            nextButton={
                                                <Button
                                                    size="small"
                                                    onClick={handleNext}
                                                    disabled={
                                                        activeStep ===
                                                        maxSteps - 1
                                                    }
                                                >
                                                    Next
                                                    {theme.direction ===
                                                        "rtl" ? (
                                                        <KeyboardArrowLeft />
                                                    ) : (
                                                        <KeyboardArrowRight />
                                                    )}
                                                </Button>
                                            }
                                            backButton={
                                                <Button
                                                    size="small"
                                                    onClick={handleBack}
                                                    disabled={activeStep === 0}
                                                >
                                                    {theme.direction ===
                                                        "rtl" ? (
                                                        <KeyboardArrowRight />
                                                    ) : (
                                                        <KeyboardArrowLeft />
                                                    )}
                                                    Back
                                                </Button>
                                            }
                                        />
                                    </Box>
                                </div>
                                <Divider
                                    variant="middle"
                                    className={styles.divider}
                                />
                                <div className={styles.titleName}>
                                    {service.serviceTitle}
                                </div>
                                <div className={styles.titleIntro}>
                                    <span></span>
                                </div>
                                <Typography
                                    className={styles.inlineTitle}
                                    variant="h6"
                                    align="left"
                                >
                                    Posted date:
                                </Typography>

                                <Typography
                                    component="span"
                                    variant="h6"
                                    className={styles.inline}
                                    color="textPrimary"
                                >
                                    {convertDateToString(service.createdAt)}
                                </Typography>
                                <Typography
                                    className={styles.inlineTitle}
                                    variant="h6"
                                    align="left"
                                >
                                    Review on the service:
                                </Typography>

                                <div className={styles.titleBottom}>
                                    <div className={styles.starAndRateDiv}>
                                        <Typography
                                            className={styles.inline}
                                            variant="h6"
                                            align="left"
                                        >
                                            {serviceCounter} Reviews:
                                        </Typography>

                                        <div className={styles.starShowDiv}>
                                            <StarShow
                                                rate={
                                                    service.serviceFeedbackAvgRate
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.titleBottom}>
                                    <div className={styles.starAndRateDiv}>
                                        <Typography
                                            className={styles.inline}
                                            variant="h6"
                                            align="left"
                                        >
                                            {"Service on time rating:  "}
                                        </Typography>

                                        <div className={styles.starShowDiv}>
                                            <StarShow rate={serviceOTAvgRate} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.titleBottom}>
                                    <div className={styles.starAndRateDiv}>
                                        <Typography
                                            className={styles.inline}
                                            variant="h6"
                                            align="left"
                                        >
                                            {"Service attitude rating: "}
                                        </Typography>

                                        <div className={styles.starShowDiv}>
                                            <StarShow rate={serviceAAvgRate} />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.titleBottom}>
                                    <div className={styles.starAndRateDiv}>
                                        <Typography
                                            className={styles.inline}
                                            variant="h6"
                                            align="left"
                                        >
                                            {"Service quality rating: "}
                                        </Typography>

                                        <div className={styles.starShowDiv}>
                                            <StarShow rate={serviceQAvgRate} />
                                        </div>
                                    </div>
                                </div>
                                <Divider
                                    variant="middle"
                                    className={styles.divider}
                                />
                                <Typography
                                    className={styles.inlineTitle}
                                    variant="h6"
                                    align="left"
                                >
                                    Service Contact Phone Number:
                                </Typography>

                                <Typography
                                    component="span"
                                    variant="h6"
                                    className={styles.inline}
                                    color="textPrimary"
                                >
                                    <MobileFriendly color="primary" />
                                    {service.phoneNumber}
                                </Typography>
                                {/* <Typography
                                    className={styles.inlineTitle}
                                    variant="h6"
                                    align="left"
                                >
                                    Service address:
                                </Typography>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    className={styles.inline}
                                    color="textPrimary"
                                >
                                    {service.serviceArea}
                                    {", "}
                                    {service.serviceCity}
                                    {", "}
                                    {service.serviceAddress}
                                    {", "}
                                    {service.servicePostcode}
                                </Typography> */}

                                <Divider
                                    variant="middle"
                                    className={styles.divider}
                                />
                                {service.identity === "COMPANY" ? (
                                    <>
                                        <Typography
                                            className={styles.inlineTitle}
                                            variant="h6"
                                            align="left"
                                        >
                                            Service Company Name:
                                        </Typography>
                                        <Typography
                                            className={styles.inline}
                                            variant="h6"
                                            align="left"
                                        >
                                            {service.companyName}
                                        </Typography>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {/* <Typography
                                    className={styles.inline}
                                    variant="h6"
                                    align="left"
                                >
                                    {service.firstName + " " + service.lastName}
                                </Typography> */}

                                <Typography
                                    className={styles.inlineTitle}
                                    variant="h6"
                                    align="left"
                                >
                                    Service Contact Name:
                                </Typography>
                                <Typography
                                    className={styles.inline}
                                    variant="h6"
                                    align="left"
                                >
                                    {service.firstName + " " + service.lastName}
                                </Typography>
                                <Typography
                                    className={styles.inlineTitle}
                                    variant="h6"
                                    align="left"
                                >
                                    Service Description:
                                </Typography>
                                <Typography
                                    className={styles.inline}
                                    variant="h6"
                                    align="left"
                                >
                                    {service.serviceDescription}
                                </Typography>
                            </div>

                            <div className={styles.contentDiv}>
                                <div className={styles.contentInsideDiv}>
                                    <Typography
                                        className={styles.price}
                                        variant="h5"
                                        align="left"
                                    >
                                        Service Reviews:
                                    </Typography>
                                    <ReviewList
                                        serviceId={serviceId}
                                        serviceRatingArray={serviceRatings}
                                        refreshList={getServiceRatingsList}
                                    />
                                </div>
                            </div>

                            <div className={styles.contentDiv}>
                                <div className={styles.contentInsideDiv}>
                                    <Typography
                                        className={styles.price}
                                        variant="h5"
                                        align="left"
                                    >
                                        Contact Service Provider
                                    </Typography>
                                    <Link style={{ textDecoration: 'none' }} to={{
                                        pathname: `/contact/${service.firstName}`,
                                        state: {
                                            serviceId: serviceId,
                                            serviceDescription: service.serviceDescription,
                                            firstName: service.firstName,
                                            lastName: service.lastName,
                                            phoneNumber: service.phoneNumber,
                                            serviceArea: service.serviceArea,
                                            serviceCity: service.serviceCity,
                                            serviceAddress: service.serviceAddress,
                                            servicePostcode: service.servicePostcode,
                                            companyName: service.companyName,
                                        }
                                    }}>
                                        <Button variant="contained" color="secondary" style={{ marginTop: "20px" }}>Contact here</Button>
                                    </Link>
                                </div>
                            </div>


                        </div>
                        <div className={styles.rightDiv}>
                            <div className={styles.rightInsideDiv}>
                                <Typography
                                    className={styles.price}
                                    variant="h5"
                                    align="center"
                                >
                                    Service Rate
                                </Typography>
                                <Typography
                                    className={styles.price}
                                    variant="h4"
                                    align="center"
                                >
                                    $ {service.servicePrice} / hour
                                </Typography>

                                <br />
                                <Form>
                                    <Grid
                                        container
                                        spacing={2}
                                        justify="center"
                                    >
                                        <Typography
                                            // className={styles.price}
                                            variant="body1"
                                            align="center"
                                        >
                                            Please Select Service Dates
                                        </Typography>

                                        <DatePicker
                                            value={date}
                                            updateDate={setDate}
                                            setTimeList={setTimeList}
                                            orders={service.orders}
                                            serviceId={serviceId}
                                        />
                                        <br />
                                    </Grid>
                                    <br />
                                    <div>
                                        <Grid
                                            container
                                            justify="center"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    type={
                                                        isOrder
                                                            ? "submit"
                                                            : "button"
                                                    }
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        if (isOrder) {
                                                            history.push(
                                                                `/Survey/${serviceId}?Date=${convertDateToString(
                                                                    date
                                                                )}`
                                                            );
                                                        } else {
                                                            getCurrentTimeList();
                                                            setIsOrder(
                                                                !isOrder
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {isOrder
                                                        ? "Order"
                                                        : "Check Availability"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <br></br>
                                    </div>
                                </Form>

                                <br />
                                {isOrder && (
                                    <div className={styles.TimeLineDiv}>
                                        <TimeLine timeList={timeList} />
                                    </div>
                                )}

                                <Divider
                                    variant="middle"
                                    className={styles.divider}
                                />
                                <br />
                                <Typography
                                    className={styles.price}
                                    variant="h5"
                                    align="center"
                                >
                                    Service Pro’s Name:{" "}
                                    {serviceOwner.first_name}{" "}
                                    {serviceOwner.last_name}
                                </Typography>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar src="/static/images/avatar/1.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="h6"
                                                    color="textPrimary"
                                                >
                                                    Join in{" "}
                                                    {new Date(
                                                        serviceOwner.createdAt
                                                    ).getFullYear()}
                                                </Typography>

                                                <br />
                                                <br />

                                                <MobileFriendly color="primary" />
                                                <Typography
                                                    component="span"
                                                    variant="h6"
                                                    className={styles.inline}
                                                    color="textPrimary"
                                                >
                                                    {serviceOwner.email}
                                                </Typography>

                                                <br />
                                                <br />
                                                <Typography
                                                    className={
                                                        styles.inlineTitle
                                                    }
                                                    variant="h6"
                                                    align="left"
                                                >
                                                    Review on the owner:
                                                </Typography>

                                                <div
                                                    className={
                                                        styles.titleBottom
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.starAndRateDiv
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.starShowDiv
                                                            }
                                                        >
                                                            <StarShow
                                                                rate={
                                                                    serviceOwner.feedbackAvgRate
                                                                }
                                                            />
                                                        </div>
                                                        {
                                                            serviceOwner.feedbackCounter
                                                        }{" "}
                                                        Reviews
                                                    </div>
                                                </div>
                                                <Typography
                                                    className={
                                                        styles.inlineTitle
                                                    }
                                                    variant="h6"
                                                    align="left"
                                                >
                                                    Self Description:
                                                </Typography>

                                                <br />

                                                <Typography
                                                    component="span"
                                                    variant="body1"
                                                    className={styles.inline}
                                                    color="textPrimary"
                                                >
                                                    {
                                                        serviceOwner.self_description
                                                    }
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <Divider
                                    variant="middle"
                                    className={styles.divider}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik >
    ) : (
        <Skeleton variant="rect" height={500} />
    );
};

export default ServiceDetail;
