import { useTheme } from "@material-ui/styles"
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AppBarTop from "../../components/AppBarTop/AppBarTop"
import { GetData, InitializeConversation } from "./handler";
import { useStyles } from "./ServiceDetailTutoring.style";
import UserIcon from "../../images/userIcon.png";
import { BsFillPersonFill, BsFillTelephoneFill } from 'react-icons/bs';
import { CircularProgress } from "@material-ui/core";
import { MdPayment } from 'react-icons/md';
import { AiFillStar } from 'react-icons/ai';

type IData = {
    address: string;
    companyName: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    numberOfClientsLike: number;
    numberYearService: number;
    orders: Array<any>;
    phoneNumber: string;
    qualification: string;
    sampleClass: string;
    selectedSubjects: Array<any>;
    serviceAttitudeAvgRate: number;
    serviceDescription: string;
    serviceFeedbackAvgRate: number;
    serviceFeedbackCounter: number;
    serviceInquiries: Array<any>;
    languages: Array<any>;
    serviceMode: string;
    servicePrice: number;
    serviceOnTimeAvgRate: number;
    serviceQualityAvgRate: number;
    serviceRatingCounter: number;
    serviceTitle: string;
    serviceType: string;
    slots: Array<any>;
    timeline: Array<any>;
    updatedAt: string;
    reviews: Array<any>;
    __v: number;
    _id: string;
}

export const ServiceDetailTutoring = () => {
    const theme = useTheme();
    const classes = useStyles();
    const [services, setServices] = useState([]);
    const [service, setService] = useState<IData>();
    const [loading, setLoading] = useState(true);
    const { serviceId } = useParams<{ serviceId: string }>();
    const history = useHistory();

    useEffect(() => {
        GetData().then(res => {
            setServices(res);
            res.map((service: any) => {
                if (service._id === serviceId) {
                    setService(service);
                    console.log(service);
                    setLoading(false);
                }
            })
        })
    }, [serviceId])

    const GenerateSimilarServices = () => {
        const list: any = [];
        service.selectedSubjects.map((subject: string) => {
            services.map((serv: any) => {
                if (serv.selectedSubjects.includes(subject) && serv._id !== serviceId) {
                    list.push(
                        <div className={classes.similarService} key={serv._id} onClick={() => history.push(`/ServiceDetailTutoring/${serv._id}`)}>
                            <p className={classes.subHeading}>{serv.serviceTitle}</p>
                            <p className={`${classes.sub} ${classes.alignLeft}`}>{serv.firstName} {serv.lastName}</p>
                            <p className={`${classes.sub} ${classes.alignLeft}`}>Service Rate: {serv.servicePrice} CAD / hour</p>
                        </div>
                    );


                }

            })
        })
        if (list.length === 0) {
            return <p className={classes.sub}>No similar services found</p>
        }
        return list;
    }

    const GenerateStars = (rate: number) => {
        const list: any = [];
        for (let i = 1; i <= rate; i++) {
            list.push(
                <AiFillStar className={classes.star} key={i} />
            )
        }
        return list;
    }
    const handleMessageInit = (proName: string) => {
        InitializeConversation(proName)
        .then(res => {
            if (res.data.success) {
                history.push(`/contact/client/${sessionStorage.getItem("name")}`)
            }
        })
    }
    const GenerateOverallRatings = (reviews: any) => {
        let overallTimeRating: any = 0;
        let overallQualityRating: any = 0;
        let overallAttitudeRating: any = 0;
        let overallServiceRating: any = 0;
        reviews.map((review: any) => {
            overallTimeRating += review.timeRating;
            overallQualityRating += review.qualityRating;
            overallAttitudeRating += review.attitudeRating;
            overallServiceRating += review.overallRating;
        })
        overallTimeRating = overallTimeRating / reviews.length;
        overallQualityRating = overallQualityRating / reviews.length;
        overallAttitudeRating = overallAttitudeRating / reviews.length;
        overallServiceRating = overallServiceRating / reviews.length;
        console.log(overallServiceRating);
        return { overallTimeRating, overallQualityRating, overallAttitudeRating, overallServiceRating };
    }

    return (
        <>
            <AppBarTop />
            <div className={classes.container}>
                {loading ? <div style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div> :
                    <>
                        <div className={classes.left}>
                            <img src={UserIcon} className={classes.profileImage} />
                            <p className={classes.subHeading} style={{ fontWeight: "bold" }}><Link to={`/profile/provider/${service.firstName}`}>{service.firstName} {service.lastName}</Link></p>
                            <p className={classes.sub}>{service.companyName}</p>
                            <p className={classes.sub}>{service.address}</p>
                            <p className={classes.sub}>{service.phoneNumber}</p>
                            <div className={classes.button}>
                                <BsFillTelephoneFill className={classes.icon} />
                                <p style={{ margin: "0px 0px" }} onClick={() => handleMessageInit(service.firstName)}>Message Provider</p>
                                <p style={{ margin: "0px 0px" }}></p>
                            </div>
                            <hr className={classes.divider} />
                            <p className={classes.subHeading}>Additional Details</p>
                            <p className={classes.sub}>Experience: {service.numberYearService} years</p>
                            <p className={classes.sub}>Qualification: {service.qualification}</p>
                            <p className={classes.sub}>Service Type: {service.serviceType}</p>
                            <hr className={classes.divider} />
                            <p className={classes.subHeading}>Service Reviews</p>
                            <Link to={`/services/feedback/${serviceId}`}><p className={classes.sub}>Leave a Review</p></Link>
                        </div>
                        <div className={classes.center}>
                            <h1 className={classes.heading}>
                                {service.serviceTitle}
                            </h1>
                            <h3 className={classes.subHeading}>Service Description</h3>
                            <p className={`${classes.sub} ${classes.alignLeft}`}>{service.serviceDescription}</p><br />

                            <h3 className={classes.subHeading}>Service Mode of Delivery</h3>
                            <p className={`${classes.sub} ${classes.alignLeft}`}>{service.serviceMode}</p><br />

                            <h3 className={classes.subHeading}>Service Languages</h3>
                            <p className={`${classes.sub} ${classes.alignLeft}`}>
                                {service.languages.map((language: string, index: number) => {
                                    return <span>{index+1}. {language} </span>
                                })}
                            </p>

                            <h3 className={classes.subHeading}>Service Rate</h3>
                            <p className={`${classes.sub} ${classes.alignLeft}`}>{service.servicePrice} CAD / hour</p><br />

                            <h3 className={classes.subHeading}>Service Timings</h3>
                            <p className={`${classes.sub} ${classes.alignLeft}`}>The following time slots are the available times for the service</p><br />
                            <div className={classes.slotContainer}>
                                {service.slots.map((slot: any) => {
                                    return <div className={classes.slot} key={slot}>{slot}</div>
                                })}

                            </div>
                            <hr className={classes.divider} />
                            <h3 className={classes.subHeading}>Place Order</h3>
                            <p className={`${classes.sub} ${classes.alignLeft}`}>By proceeding to place an order,
                                you agree to the <span style={{ color: 'blue' }}>terms and conditions</span>. All service providers must be treated respectfully.
                                Failure to comply with these rules may result in a permanent ban.</p><br />
                            <div className={classes.purchaseButton} onClick={() => history.push(`/services/tutoring/order/${serviceId}`)}>
                                <MdPayment />
                                <p style={{ margin: "0px 0px" }}>Place Order</p>
                                <p style={{ margin: "0px 0px" }}></p>
                            </div>
                            <hr className={classes.divider} />
                            <h3 className={classes.subHeading}>Service Reviews</h3>
                            {service.reviews.length === 0 ? <p className={classes.sub}>No reviews yet</p> : <>
                                <div className={classes.overallRow}>
                                    <div className={classes.starWrapper}>
                                        <p className={classes.sub}>Average Time Rating: </p>
                                        <div className={classes.starContainer}>{GenerateStars(GenerateOverallRatings(service.reviews).overallTimeRating)}</div>
                                    </div>
                                    <div className={classes.starWrapper}>
                                        <p className={classes.sub}>Average Attitude Rating: </p>
                                        <div className={classes.starContainer}>{GenerateStars(GenerateOverallRatings(service.reviews).overallAttitudeRating)}</div>
                                    </div>
                                </div>
                                <div className={classes.overallRow}>
                                    <div className={classes.starWrapper}>
                                        <p className={classes.sub}>Average Quality Rating: </p>
                                        <div className={classes.starContainer}>{GenerateStars(GenerateOverallRatings(service.reviews).overallQualityRating)}</div>
                                    </div>
                                    <div className={classes.starWrapper}>
                                        <p className={classes.sub}>Average Service Rating: </p>
                                        <div className={classes.starContainer}>{GenerateStars(GenerateOverallRatings(service.reviews).overallServiceRating)}</div>
                                    </div>
                                </div>
                            </>
                            }
                            {service.reviews.map((review: any) => {
                                return (
                                    <div className={classes.review} key={review.from}>
                                        <p className={classes.subHeading}><b>{review.from}</b></p>
                                        <p className={classes.sub}>{review.dateString}</p>
                                        <hr className={classes.divider} />
                                        <div className={classes.starWrapper}>
                                            <p className={classes.reviewMessage}>Time: </p>
                                            <div className={classes.starContainer}>
                                                {GenerateStars(review.timeRating)}
                                            </div>
                                        </div>
                                        <div className={classes.starWrapper}>
                                            <p className={classes.reviewMessage}>Quality: </p>
                                            <div className={classes.starContainer}>
                                                {GenerateStars(review.qualityRating)}
                                            </div>
                                        </div>
                                        <div className={classes.starWrapper}>
                                            <p className={classes.reviewMessage}>Attitude: </p>
                                            <div className={classes.starContainer}>
                                                {GenerateStars(review.attitudeRating)}
                                            </div>
                                        </div>
                                        <div className={classes.starWrapper}>
                                            <p className={classes.reviewMessage}>Overall Rating: </p>
                                            <div className={classes.starContainer}>
                                                {GenerateStars(review.overallRating)}
                                            </div>
                                        </div>
                                        <hr className={classes.divider}></hr>
                                        <p className={classes.reviewMessage}>"{review.reviewMessage}"</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={classes.right}>
                            <h3 className={classes.heading} style={{ textAlign: 'center', marginTop: "20px", fontSize: '2rem' }}>Similar Services</h3>
                            {GenerateSimilarServices()}
                        </div>
                    </>
                }
            </div>
        </>
    )
}