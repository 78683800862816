import { ErrorMessage, Field, Form, Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import { useHistory, useParams } from "react-router-dom";
import { fieldLabels, ReserveField, editValidationSchema } from "./fields";
import { TextField, Select } from "formik-material-ui";
import { timeLines } from "../../util/survey";
import Button from "@material-ui/core/Button";
import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    MenuItem,
    Typography,
    InputLabel,
} from "@material-ui/core";
import { handleCancel, handleReserve, handleSubmit } from "./handler";
import { useStyles } from "./Reserve.style";
import { IUser } from "../../util/users";
import { useEffect, useState } from "react";
import { Endpoint } from "../../util/endpoints";
import { getAuth } from "../../util/auth";
import { getToken } from "../../util/cookie";
import { Alert, Skeleton } from "@material-ui/lab";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { IService } from "../../util/service";
import StarShow from "../../components/StarShow/StarShow";
import Cleaning from "../Landboard/dashboard-images/cleaning.png";
import { ArrowBack } from "@material-ui/icons";
import DatePicker from "../../components/DatePicker/DatePicker";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
export interface IReserve {
    first_name: string;
    last_name: string;
    address: string;
    phoneNumber: string;
    zipcode: string;
    orderId: string;
    serviceId: string;
    cleaningDate: string;
    timeLine: number[];
}

interface IServiceDetail {
    service: IService;
    serviceOwner: IUser;
}

const ReserveInfo = () => {
    const styles = useStyles();
    const history = useHistory();
    const [loadingError, setLoadingError] = useState(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const { orderId } = useParams<{ orderId: string }>();
    const [service, setService] = useState<IService>();
    const [serviceOwner, setServiceOwner] = useState<IUser>();
    const [serviceCounter, setServiceCounter] = useState(0);
    const [reserveInfo, setReserveInfo] = useState<IReserve>();
    const [timeCounter, setTimeCounter] = useState(0);
    const [timeList, setTimeList] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [date, setDate] = useState(new Date());
    const initialOptions = {
        "client-id": "test",
        currency: "CAD",
        intent: "capture",
        // "data-client-token": "abc123xyz==", // TODO: Change this later
    };

    const handleDash = () => {
        return history.push("/");
    };

    const getRerserveDetail = async () => {
        try {
            const token: string = getToken();
            const theReserve = await getAuth(Endpoint.ORDER + orderId, token);
            const serviceObj: IServiceDetail = await getAuth(
                Endpoint.DETAIL_SERVICE + theReserve.serviceId,
                token
            );
            let timeList = await getAuth(
                Endpoint.ORDER_TIME +
                    theReserve.serviceId +
                    "/" +
                    theReserve.cleaningDate,
                token
            );
            await Promise.all(
                theReserve.timeLine.map(async (timeline: any) => {
                    timeList[parseInt(timeline) - 9] = 0;
                })
            );
            const theReserveInfo = {
                orderId: theReserve._id,
                serviceId: theReserve.serviceId,
                equipment: theReserve.equipment,
                address: theReserve.address,
                zipcode: theReserve.zipcode,
                first_name: theReserve.firstName,
                last_name: theReserve.lastName,
                phoneNumber: theReserve.phoneNumber,
                cleaningDate: theReserve.cleaningDate,
                timeLine: theReserve.timeLine,
                orderAmount: theReserve.orderAmount,
            } as IReserve;
            setServiceOwner(serviceObj.serviceOwner);
            setService(serviceObj.service);
            setReserveInfo(theReserveInfo);
            setTimeList(timeList);
            var parts = theReserveInfo.cleaningDate.split("-");

            setDate(
                new Date(
                    parseInt(parts[0]),
                    parseInt(parts[1]) - 1,
                    parseInt(parts[2])
                )
            );
            setTimeCounter(theReserveInfo.timeLine.length);
        } catch (e) {
            setLoadingError(true);
        }
    };

    useEffect(() => {
        getRerserveDetail();
    }, []);

    return loadingError ? (
        <Alert severity="error">
            Something went wrong trying to load that user. Please go back and
            try again.
        </Alert>
    ) : reserveInfo ? (
        <Formik
            initialValues={reserveInfo}
            validationSchema={editValidationSchema}
            onSubmit={(values, setSubmitting) => {
                // console.log(values);
                // handleSubmit(
                //     values,
                //     setSubmitting,
                //     setIsEditing,
                //     date,
                //     setTimeCounter
                // );
                handleReserve(values, setSubmitting, setIsEditing);
            }}
        >
            {({ isSubmitting, resetForm, setFieldValue }) => (
                <div className={styles.container}>
                    <Button onClick={handleDash} className={styles.topLeftBtn}>
                        <Logo />
                    </Button>

                    <br />
                    <br />

                    <Card className={styles.card}>
                        <CardHeader
                            title="Reserve Detail"
                            titleTypographyProps={{ variant: "h2" }}
                            className={styles.header}
                        />
                        <Divider variant="middle" className={styles.divider} />
                        <br />

                        <Button variant="text" onClick={history.goBack}>
                            <ArrowBack />
                            Back to Service Survey
                        </Button>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <div className={styles.cardBoarder}>
                                    <Card className={styles.suggestCard}>
                                        <CardContent
                                            className={styles.cardContent}
                                        >
                                            <Typography
                                                variant="h4"
                                                align="center"
                                                className={styles.boldFont}
                                            >
                                                Service Detail
                                            </Typography>
                                            <CardMedia
                                                className={styles.cardMedia}
                                                image={Cleaning}
                                            />
                                            <br />
                                            <Typography
                                                variant="h3"
                                                align="center"
                                                className={styles.boldFont}
                                            >
                                                {service.serviceTitle}
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                align="center"
                                                className={styles.boldFont}
                                            >
                                                Review on the service:
                                            </Typography>
                                            <div className={styles.titleBottom}>
                                                <div
                                                    className={
                                                        styles.starAndRateDiv
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.starShowDiv
                                                        }
                                                    >
                                                        <StarShow
                                                            rate={
                                                                service.serviceFeedbackAvgRate
                                                            }
                                                        />
                                                    </div>
                                                    ({serviceCounter} Reviews)
                                                </div>
                                            </div>
                                            <Divider
                                                className={styles.divider}
                                            />
                                            <Typography
                                                align="center"
                                                variant="h5"
                                                className={styles.boldFont}
                                            >
                                                Price Detail:
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                            >
                                                Clean Service fee: ${" "}
                                                {service.servicePrice}
                                                /hour
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                            >
                                                Clean time:
                                                {" " + timeCounter} hours
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                            >
                                                Tax fee:{" "}
                                                {(
                                                    0.12 *
                                                    timeCounter *
                                                    service.servicePrice
                                                ).toFixed(2)}
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                            >
                                                Total fee:{" "}
                                                {(
                                                    1.12 *
                                                    timeCounter *
                                                    service.servicePrice
                                                ).toFixed(2)}
                                            </Typography>
                                            <Divider
                                                className={styles.divider}
                                            />

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <PayPalScriptProvider
                                                options={initialOptions}
                                            >
                                                <PayPalButtons
                                                    style={{
                                                        layout: "vertical",
                                                    }}
                                                    createOrder={(
                                                        data: any,
                                                        actions: any
                                                    ) => {
                                                        return actions.order.create(
                                                            {
                                                                purchase_units:
                                                                    [
                                                                        {
                                                                            amount: {
                                                                                value: (
                                                                                    1.12 *
                                                                                    timeCounter *
                                                                                    service.servicePrice
                                                                                )
                                                                                    .toFixed(
                                                                                        2
                                                                                    )
                                                                                    .toString(),
                                                                            },
                                                                        },
                                                                    ],
                                                            }
                                                        );
                                                    }}
                                                    forceReRender={[
                                                        timeCounter,
                                                    ]}
                                                />
                                            </PayPalScriptProvider>
                                        </CardContent>
                                    </Card>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <div className={styles.reserveCardBoarder}>
                                    <Card className={styles.suggestCard}>
                                        <CardContent
                                            className={styles.cardContent}
                                        >
                                            <Typography
                                                variant="h4"
                                                align="center"
                                                className={styles.boldFont}
                                            >
                                                Reserve Detail
                                            </Typography>
                                            <Divider
                                                className={styles.divider}
                                            />
                                            <br />
                                            <Typography
                                                variant="h5"
                                                align="center"
                                                className={styles.boldFont}
                                            >
                                                Time Schedule:
                                            </Typography>
                                            <br />

                                            <Form>
                                                <Typography
                                                    variant="h5"
                                                    align="center"
                                                >
                                                    Date:
                                                </Typography>
                                                <DatePicker
                                                    updateDate={setDate}
                                                    value={date}
                                                    disabled={!isEditing}
                                                    setTimeList={setTimeList}
                                                    orders={service.orders}
                                                    serviceId={
                                                        reserveInfo.serviceId
                                                    }
                                                    orderTimeLine={
                                                        reserveInfo.timeLine
                                                    }
                                                    orderDate={
                                                        reserveInfo.cleaningDate
                                                    }
                                                />

                                                <br />
                                                <div className={styles.center}>
                                                    <Typography
                                                        variant="h5"
                                                        align="center"
                                                    >
                                                        Time Picked:
                                                    </Typography>
                                                    <br />
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={8}
                                                        md={6}
                                                    >
                                                        <InputLabel
                                                            shrink={true}
                                                            htmlFor={
                                                                ReserveField.timeLine
                                                            }
                                                        >
                                                            Please confirm the
                                                            time line selected
                                                        </InputLabel>
                                                        <Field
                                                            component={Select}
                                                            disabled={
                                                                !isEditing
                                                            }
                                                            name={
                                                                ReserveField.timeLine
                                                            }
                                                            variant="filled"
                                                            type="TextField"
                                                            fullwidth
                                                            label={
                                                                fieldLabels[
                                                                    ReserveField
                                                                        .timeLine
                                                                ]
                                                            }
                                                            multiple={true}
                                                        >
                                                            {Object.entries(
                                                                timeLines
                                                            ).map(
                                                                ([
                                                                    value,
                                                                    {
                                                                        id,
                                                                        name,
                                                                    },
                                                                ]) => (
                                                                    <MenuItem
                                                                        disabled={
                                                                            !!timeList[
                                                                                id -
                                                                                    1
                                                                            ]
                                                                        }
                                                                        key={
                                                                            value
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Field>
                                                        <ErrorMessage
                                                            component="div"
                                                            className={
                                                                styles.errMsg
                                                            }
                                                            name={
                                                                ReserveField.timeLine
                                                            }
                                                        />
                                                    </Grid>
                                                </div>
                                                <br />

                                                <Divider
                                                    className={styles.divider}
                                                />
                                                <br />
                                                <Typography
                                                    variant="h5"
                                                    align="center"
                                                    className={styles.boldFont}
                                                >
                                                    Contact Information
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    className={styles.boldFont}
                                                >
                                                    Username:
                                                </Typography>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={8}
                                                    md={6}
                                                >
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            ReserveField.first_name
                                                        }
                                                        variant="filled"
                                                        label={
                                                            fieldLabels[
                                                                ReserveField
                                                                    .first_name
                                                            ]
                                                        }
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <br />
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={8}
                                                    md={6}
                                                >
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            ReserveField.last_name
                                                        }
                                                        variant="filled"
                                                        label={
                                                            fieldLabels[
                                                                ReserveField
                                                                    .last_name
                                                            ]
                                                        }
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <Typography
                                                    variant="h6"
                                                    className={styles.boldFont}
                                                >
                                                    Basic User Information:
                                                </Typography>
                                                <Grid item md={6} xs={12}>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            ReserveField.phoneNumber
                                                        }
                                                        variant="filled"
                                                        label={
                                                            fieldLabels[
                                                                ReserveField
                                                                    .phoneNumber
                                                            ]
                                                        }
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <br />
                                                <Grid item md={7} xs={12}>
                                                    <Field
                                                        component={TextField}
                                                        disabled={!isEditing}
                                                        name={
                                                            ReserveField.address
                                                        }
                                                        variant="filled"
                                                        label={
                                                            fieldLabels[
                                                                ReserveField
                                                                    .address
                                                            ]
                                                        }
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <br />
                                                <Grid item md={5} xs={12}>
                                                    <Field
                                                        component={TextField}
                                                        name={
                                                            ReserveField.zipcode
                                                        }
                                                        disabled={!isEditing}
                                                        variant="filled"
                                                        label={
                                                            fieldLabels[
                                                                ReserveField
                                                                    .zipcode
                                                            ]
                                                        }
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <Grid
                                                    justify="flex-end"
                                                    container
                                                    spacing={1}
                                                >
                                                    {isEditing ? (
                                                        <>
                                                            <Grid item>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        handleCancel(
                                                                            setIsEditing,
                                                                            resetForm
                                                                        );
                                                                    }}
                                                                    disabled={
                                                                        isSubmitting
                                                                    }
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    type="submit"
                                                                    disabled={
                                                                        isSubmitting
                                                                    }
                                                                >
                                                                    Save
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Grid item>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setIsEditing(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                {/*<Button*/}
                                                                {/*    color="primary"*/}
                                                                {/*    variant="contained"*/}
                                                                {/*    type="button"*/}
                                                                {/*    disabled={*/}
                                                                {/*        isSubmitting*/}
                                                                {/*    }*/}
                                                                {/*    onClick={() => history.push(`/Pay/${orderId}`)}*/}
                                                                {/*>*/}
                                                                {/*    Pay*/}
                                                                {/*</Button>*/}
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    type="submit"
                                                                    disabled={
                                                                        isSubmitting
                                                                    }
                                                                    onClick={() =>
                                                                        history.push(
                                                                            `/OrderPage/${orderId}`
                                                                        )
                                                                    }
                                                                >
                                                                    Pay
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Form>
                                        </CardContent>
                                    </Card>
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                    </Card>
                </div>
            )}
        </Formik>
    ) : (
        <Skeleton variant="rect" height={500} />
    );
};

export default ReserveInfo;
