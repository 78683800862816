import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FF9800",
        minHeight: 900,
    },
    topLeftBtn: {
        position: "absolute",
        top: "8px",
        left: "0px",
    },
    starAndRateDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    starShowDiv: {
        width: "129px",
        height: "30px",
    },
    divider: {
        background: "black",
    },
    card: {
        borderRadius: 30,
        minWidth: "90%",
        // textAlign: "center",
        marginTop: 150,
        marginBottom: 50,
        minHeight: 800,
    },
    header: {
        textAlign: "center",
        spacing: 10,
    },
    cardBoarder: {
        // boardWidth: "20px",\
        borderRadius: 30,
        backgroundColor: "#FF9800",
        // alignItems: "center",
        minWidth: "80%",
        display: "flex",
        flexDirection: "row",
        margin: 60,
    },
    reserveCardBoarder: {
        borderRadius: 30,
        backgroundColor: "#FF9800",
        // alignItems: "center",
        minWidth: "180%",
        display: "flex",
        flexDirection: "row",
        margin: 60,
    },
    titleBottom: {
        width: "100%",
        display: "flex",
        marginBottom: "15px",
        alignItems: "center",
        justifyContent: "space-between",
    },
    thickerDivider: {
        // Theme Color, or use css color in quote
        background: theme.palette.divider,
        backgroundColor: "#000000",
        color: "#000000",
    },
    suggestCard: {
        borderRadius: 20,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: 1050,
        margin: 10,
        right: 10,
        // maxHeight: 800,
    },

    cardMedia: {
        paddingTop: "56.25%", // 16:9
    },
    cardContent: {
        flexGrow: 1,
        // alignItems: "center",
    },
    cardActionSection: {
        display: "flex",
        flexDirection: "column",
        align: "right",
        float: "right",
        marginBottom: 30,
    },
    editButton: {
        backgroundColor: "#ff9800",
        // position: "relative",
        display: "flex",
        flexDirection: "column",
        align: "right",
        float: "right",
        justifyContent: "right",
        marginRight: 30,
        color: "#FFFFFF",
    },
    chooseButton: {
        backgroundColor: "lightBlue",
        // position: "relative",
        display: "flex",
        flexDirection: "column",
        align: "right",
        float: "right",
        justifyContent: "right",
        color: "#FFFFFF",
    },
    pagination: {
        color: "#ff9800",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 30,
    },
    disabledTextField: {
        "& div": {
            color: "black",
        },
        "& input:disabled": {
            color: "black",
        },
    },
    suggestWord: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 30,
    },
    boldFont: {
        fontWeight: "bold",
    },
    btn: {
        margin: 30,
    },
    center: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    errMsg: {
        color: "red",
    },
}));
