import { Field, Form, Formik } from "formik";
import { CardContent, Grid, IconButton, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
    fieldLabels,
    LoginField,
    initialValues,
    newValidationSchema,
} from "./fields";
import { TextField } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import {
    AppBar,
    Card,
    CardHeader,
    Divider,
    Tab,
    Tabs,
} from "@material-ui/core";
import { tabProps } from "../../util/tabPanel";
import { useStyles } from "./Login.style";
import { saveUserToken } from "./handler";
import { useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { FormikHelpers } from "formik";
import { TNewUserValues, errorMessage } from "./fields";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { Helmet } from "react-helmet";
import { ReactComponent as Logo } from "../../images/logo.svg";

const ForgetPassword = () => {
    const styles = useStyles();
    const history = useHistory();
    const [value, setValue] = useState(0);
    const [status, setStatus] = useState(0);

    const handleDash = () => {
        return history.push("/");
    };

    const handleLoginPage = () => {
        return history.push("/Login");
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleLogin = async (
        values: TNewUserValues,
        helpers: FormikHelpers<TNewUserValues>
    ) => {
        const newUser = JSON.stringify({
            email: values.email,
            password: values.password,
        });
        try {
            setStatus(await saveUserToken(newUser));
        } catch (e) {
            setStatus(-1);
            helpers.setSubmitting(false);
        }
    };

    const LoginAlert = () => {
        return (
            <Collapse in={status !== 0}>
                <Alert
                    severity="error"
                    className={styles.alertBox}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setStatus(0);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    <AlertTitle>Login Error</AlertTitle>
                    {errorMessage[status]} —{" "}
                    <strong>Please check it out!</strong>
                </Alert>
            </Collapse>
        );
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={newValidationSchema}
                onSubmit={handleLogin}
            >
                {({ isSubmitting }) => (
                    <div className={styles.container}>
                        <Button
                            onClick={handleDash}
                            className={styles.topLeftBtn}
                        >
                            <Logo />
                        </Button>

                        {/* <Helmet>
                            <meta
                                http-equiv="Content-Security-Policy"
                                content="upgrade-insecure-requests"
                            />
                        </Helmet> */}

                        <LoginAlert />

                        <br />
                        <br />
                        <Card className={styles.card}>
                            <CardHeader
                                title="Reset Password"
                                className={styles.header}
                                titleTypographyProps={{ variant: "h2" }}
                            />
                            <Divider variant="middle" />
                            <AppBar position="static">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Loading"
                                    centered
                                >
                                    <Tab label="Email" {...tabProps(0)} />
                                </Tabs>
                            </AppBar>
                            <br />
                            <Form>
                                <Grid container spacing={2} justify="center">
                                    <Grid item md={10} xs={12}>
                                        <Field
                                            component={TextField}
                                            name={LoginField.email}
                                            variant="filled"
                                            label={
                                                fieldLabels[LoginField.email]
                                            }
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <br />

                                    {/* <Grid item md={10} xs={12}>
                                        <Field
                                            component={TextField}
                                            name={LoginField.password}
                                            variant="filled"
                                            type="password"
                                            label={
                                                fieldLabels[LoginField.password]
                                            }
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <br /> */}
                                </Grid>
                                <br />
                                <div>
                                    <Grid
                                        container
                                        justify="center"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                disabled={isSubmitting}
                                                className={styles.submitButton}
                                            >
                                                Verify with your email address
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                </div>
                            </Form>
                            <Divider variant="middle" />

                            {/* <Button onClick={handleRegister}>
                                Forget Your Password?
                            </Button> */}

                            <Button onClick={handleLoginPage}>
                                I have already had an account
                            </Button>
                        </Card>
                    </div>
                )}
            </Formik>
        </>
    );
};

export default ForgetPassword;
