import AppBarTop from "../../components/AppBarTop/AppBarTop";
import { useStyles } from "./ClientPublicProfile.style";
import { useHistory, useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import UserIcon from "../../images/userIcon.png"
import { GetClientProfile, GetClientReviews } from "./handler";
import { CircularProgress } from "@material-ui/core";
import { AiFillStar } from "react-icons/ai";

export const ClientPublicProfile = () => {  //This is the client's public profile page
    const { userId } = useParams<{ userId: string }>();
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>();
    const [reviews, setReviews] = useState<any>();
    useEffect(() => {
        GetClientProfile(userId)    //Fetch client's profile
            .then(res => {
                console.log(res.data);
                setData(res.data);
                GetClientReviews(userId)
                    .then(res => {
                        console.log(res.data);
                        setReviews(res.data);
                        setLoading(false);
                    })
            });
    }, [])
    const GenerateStars = (rate: number) => {   //Generate stars for the ratings
        const list: any = [];
        for (let i = 1; i <= rate; i++) {
            list.push(
                <AiFillStar className={classes.star} key={i} />
            )
        }
        return list;
    }
    return (
        <>
            <AppBarTop />
            <div className={classes.container}>
                {loading ? <div style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div> :
                    <>
                        <div className={classes.left}>
                            <img src={UserIcon} className={classes.profileImage} />
                            <p className={classes.subHeading} style={{ fontWeight: "bold" }}>{data.first_name} {data.last_name}</p>
                            <p className={classes.sub}>Verified Van-Service Client</p>
                            <p className={classes.sub}>{data.email}</p>
                            <p className={classes.sub}>{data.address}</p>
                            <p className={classes.sub}>{data.phoneNumber}</p>
                            <hr className={classes.divider} />
                        </div>
                        <div className={classes.center}>
                            <h1 className={classes.heading}>
                                {data.first_name}'s Reviews
                            </h1>
                            <p className={`${classes.alignLeft} ${classes.sub}`}>{data.first_name} has reviewed {reviews.length} services</p>
                            {reviews.map((review: any) => {
                                return (
                                    <div className={classes.review} key={review.serviceId}>
                                        <p className={classes.subHeading}><b>{review.from}</b></p>
                                        <Link to={`/ServiceDetailTutoring/${review.serviceId}`}><p className={classes.subHeading} style={{fontSize: "1.7rem"}}>{review.serviceTitle}</p></Link>
                                        <Link to={`/profile/provider/${review.providerFirstName}`}><p className={classes.subHeading}>{review.providerFirstName} {review.providerLastName}</p></Link>
                                        <p className={classes.subHeading}>{review.review.dateString}</p>
                                        <hr className={classes.divider} />
                                        <p className={classes.reviewMessage}>"{review.review.reviewMessage}"</p>
                                        <hr className={classes.divider} />
                                        <div className={classes.starWrapper}>
                                            <p className={classes.reviewMessage}>Time: </p>
                                            <div className={classes.starContainer}>
                                                {GenerateStars(review.review.timeRating)}
                                            </div>
                                        </div>
                                        <div className={classes.starWrapper}>
                                            <p className={classes.reviewMessage}>Quality: </p>
                                            <div className={classes.starContainer}>
                                                {GenerateStars(review.review.qualityRating)}
                                            </div>
                                        </div>
                                        <div className={classes.starWrapper}>
                                            <p className={classes.reviewMessage}>Attitude: </p>
                                            <div className={classes.starContainer}>
                                                {GenerateStars(review.review.attitudeRating)}
                                            </div>
                                        </div>
                                        <div className={classes.starWrapper}>
                                            <p className={classes.reviewMessage}>Overall Rating: </p>
                                            <div className={classes.starContainer}>
                                                {GenerateStars(review.review.overallRating)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={classes.right}>
                            <h3 className={classes.heading} style={{ textAlign: 'center', marginTop: "20px", fontSize: '2rem' }}>Utilities</h3>
                            <div className={classes.similarService} onClick={() => history.push(`/contact/prosChat/${sessionStorage.getItem("name")}`)}>
                                <p className={classes.subHeading}>Messages</p>
                                <p className={`${classes.sub} ${classes.alignLeft}`}>Read all the latest messages in your inbox</p>
                            </div>
                            <div className={classes.similarService} onClick={() => history.push(`/User`)}>
                                <p className={classes.subHeading}>Edit your Public Profile</p>
                                <p className={`${classes.sub} ${classes.alignLeft}`}>Change or update information of your public profile</p>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}