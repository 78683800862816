import { apiFetch } from "../../util/endpoints";
import { IFeedback } from "../../util/feedback";

export const addServiceRate = async (
    serviceRate: any,
    token: string,
    apiString: any
) => {
    const init: RequestInit = {
        method: "POST",
        headers: {
            authorization: token,
            Accept: "application/json",
        },
        body: serviceRate,
    };
    return await apiFetch(apiString, "", init).then((res) => {
        return res.json();
    });
};

export const addFeedback = async (
    feedback: any,
    token: string,
    apiString: any
) => {
    const init: RequestInit = {
        method: "POST",
        headers: {
            authorization: token,
            Accept: "application/json",
        },
        body: feedback,
    };
    return await apiFetch(apiString, "", init)
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            return res as IFeedback;
        });
};
