import { FormikHelpers } from "formik";
import { getToken } from "../../util/cookie";
import { apiFetch, Endpoint } from "../../util/endpoints";
import { IService } from "../../util/service";

const updateService = async (userInfo: string, token: string) => {
    const init: RequestInit = {
        method: "PATCH",
        headers: {
            authorization: token,
            Accept: "application/json",
        },
        body: userInfo,
    };
    return await apiFetch(Endpoint.DETAIL_SERVICE, "", init)
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            return res as IService;
        });
};

export const handleSubmit = async (
    values: any,
    helpers: FormikHelpers<IService>
) => {
    const serviceInfo = JSON.stringify({
        serviceAddress: values.serviceAddress,
    });
    try {
        await updateService(serviceInfo, getToken());
    } catch (e) {
        alert("Encountered an error while trying to edit the user!");
    }
    helpers.setSubmitting(false);
};
