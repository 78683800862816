import React from "react";
import {
    makeStyles,
    ThemeProvider,
    createMuiTheme,
} from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { blue, grey } from "@material-ui/core/colors";

const theme = createMuiTheme({
    palette: {
        primary: grey,

        secondary: {
            main: "#ff8a65",
        },
    },
});

export const useStyles = makeStyles((theme) => ({
    addIcCallIcon: {
        marginLeft: 15,
    },
    banner: {
        marginBottom: "20px",
        marginTop: "40px",
        margin: "auto",
        justifyContent: "space-around",
        fontFamily: "Microsoft yahei",
        flexGrow: 1,
        maxWidth: 1000,
    },
    block: {
        marginBottom: "20px",
        justifyContent: "center",
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    role1: {
        backgroundColor: "white",
        paddingTop: "20px",
        color: "Green",
        fontSize: "18px",
    },
    role2: {
        backgroundColor: "white",
        paddingTop: "20px",
        color: "#e4007f",
        fontSize: "18px",
    },
    stepNotes: {
        fontSize: "17px",
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(4),
    },
    stepLabels: {
        color: "black",
        fontSize: "50px",
    },
}));

function getStepsForS() {
    return [
        "Fill in your Pro’s information when you register at the first time.",
        "Post your service details.",
        "Wait for the customer’s request and confirm your availability.",
        "Service has been delivered and receive the payment",
        "Write a review",
    ];
}

const ProServiceFlow = () => {
    const classes = useStyles();
    const stepsForS = getStepsForS();
    const [activeStepForS, setActiveStepForS] = React.useState(-1);

    return (
        <Container>
            <ThemeProvider theme={theme}>
                <Grid container className={classes.banner} spacing={2}>
                    <Grid item className={classes.block} xs={6}>
                        <Stepper activeStep={-1} orientation="vertical">
                            {stepsForS.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel className={classes.stepLabels}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </Container>
    );
};

export default ProServiceFlow;
