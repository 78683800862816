import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: "#f5f5f5",
        width: "80%",
        margin: "0 auto",
        marginTop: "50px",
        minHeight: "50vh",
        display: 'flex',
        flexDirection: 'column',
        fontFamily: "Lato",
        paddingLeft: "50px",
        paddingBottom: '40px'
    },
    heading: {
        fontSize: "40px",
        textAlign: "center",
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderLeft: "10px solid #003c96",
        padding: "0px 20px",
        marginBottom: "20px",
        marginLeft: "30px",
    },
    formLabel: {
        fontSize: '1.8rem'
    },
    formInput: {
        width: "400px",
        borderRadius: '5px',
        marginBottom: '10px',
        fontSize: '1.8rem'
    },
    option: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '1.2rem',
        padding: '3px 10px',
        "&:hover": {
            backgroundColor: '#f5f5f5'
        }
    },
    subHeading: {
        fontSize: "2.2rem",
        textDecoration: "underline",
    },
    checkbox: {
        transform: "scale(1.5)",
        marginRight: "30px",
        marginLeft: '20px',
        marginBottom: '30px',
        marginTop: '10px'
    },
    checkboxContainer: {
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'center',
        flexWrap: 'wrap',
        maxWidth: "50%"
    },
    label: {
        fontSize: '1.5rem'
    },
    radio: {
        transform: "scale(1.5)",
        marginLeft: "10px"
    },
    purchaseButton: {
        width: "30%",
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: "#e81752",
        padding: '10px 0px',
        borderRadius: "30px",
        fontSize: '1.2rem',
        color: "#fff",
        margin: "0 auto",
        cursor: 'pointer',
        border: '3px solid transparent',
        transition: "0.2s all ease",
        '&:hover': {
            backgroundColor: "#a8133d"
        }
    },
    loaderWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    success: {
        fontSize: '1.5rem',
        textAlign: 'center',
        color: "#047bdb"
    },
    failure: {
        fontSize: '1.5rem',
        textAlign: 'center',
        color: "red"
    }
}))